import { CommonFactoryInterface } from "../common-factory-type";
import { UpdateOrgDto } from "./factory-dtos";
import { UpdateOrgModel } from "./factory-models";

class OrgUpdateFactory
  implements CommonFactoryInterface<UpdateOrgDto, UpdateOrgModel>
{
  createModelFromDto = (dto: UpdateOrgDto): UpdateOrgModel => {
    return {
      id: dto.id,
      legalName: dto.legalName,
      displayName: dto.displayName,
      phoneNumber: dto.phoneNumber,
      email: dto.email,
      locations: [
        {
          id: dto.locationsId,
          orgId: dto?.id,
          locationId: dto?.locationId,
          locationAddress: {
            id: dto?.locationAddressId,
            city: dto?.city,
            region: dto?.region,
            country: dto?.country,
            postalCode: dto?.postalCode,
            line1: dto?.line1,
            line2: dto?.line2,
          },
        },
      ],
    };
  };

  createModelsFromArrayOfDtos = (dtos: UpdateOrgDto[]): UpdateOrgModel[] =>
    dtos?.map(this.createModelFromDto) || [];
}

export const orgUpdateFactory = new OrgUpdateFactory();
