import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import Button from "../../../components/button/Button";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import AddContact from "../../../components/modal/addContact";
import { useAppSelector } from "../../../redux/redux";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import {
  useActivateDeactivateMutation,
  useGetActiveCustomersQuery,
  useGetSearchFilterCustomersQuery,
  useImportContactsMutation,
  useImportOrganizationsMutation,
} from "../../../slices/ContactsSlice";
import { FormSection } from "../../../support/FormSection";
import ImportContacts from "../modals/ImportContacts";
import { navigateToContact } from "../utils/navigateToContacts";
import { ComposeEmailModal } from "../modals/ComposeEmailModal";
import { CustomerModel } from "../../../factories/customers/models/customerModel";
import { IoMailOutline, IoSearch } from "react-icons/io5";
import { CiImport } from "react-icons/ci";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ExcelExport } from "../../../utils/ExportData";
import { Dropdown } from "../../../components/dropdown/new-dropdown/Dropdown";
import { HiEllipsisVertical } from "react-icons/hi2";
import { BsArchive } from "react-icons/bs";
import {
  useAddMultipleTagMutation,
  useGetTagsQuery,
} from "../../../slices/ContactTagsSlice";
import CustomSelect from "../../../components/select/Select";
import { SelectOptionType } from "../../../types/types";
import { getInitialsFromFullName } from "../../../constants/constants";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import classNames from "classnames";
import Search from "../../../components/select/Search";
import { useDebounce } from "usehooks-ts";
import { SlTag } from "react-icons/sl";
import { Pagination } from "baseui/pagination";

export type Filters = {
  tagsValues: SelectOptionType[];
  yearEnd: SelectOptionType[];
};
type Params = {
  sortBy: string;
  sortOrder: string;
  page: string;
};
type ContactData = {
  Contact: string;
  "Contact Type": string;
  "Open Work": number;
  [key: string]: string | number;
};
export const ActiveContacts: FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const sortBy = searchParams.get("sortBy") || "Name";
  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc";

  const page = +(searchParams.get("page") || 1);

  const { user } = useAppSelector((state) => state.appReducer);

  const { data: tags, isFetching } = useGetTagsQuery({
    orgId: getLinkedFirm()?.orgId || "",
  });
  const [addMultipleTags] = useAddMultipleTagMutation();

  const [importContacts, { isLoading: isLoadingImportContacts }] =
    useImportContactsMutation();
  const [importOrganizations, { isLoading: isLoadingImportOrganizations }] =
    useImportOrganizationsMutation();
  const [deactivateContacts] = useActivateDeactivateMutation();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCompose, setIsOpenCompose] = useState(false);
  const [isOpenImport, setIsOpenImport] = useState(false);
  const [customersSearch, setCustomersSearch] = useState<CustomerModel[]>([]);
  const [search, setSearch] = useState("");
  const [tagValue, setTagValue] = useState("");
  const [yearEndValue, setYearEndValue] = useState("");
  const [addTag, setAddTag] = useState(false);
  const [tagsValue, setTagsValue] = useState<SelectOptionType[]>([]);

  const [filters, setFilters] = useState<Filters>({
    tagsValues: [],
    yearEnd: [],
  });
  const debounceSearch = useDebounce(search, 200);

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetActiveCustomersQuery({
      orgId: getLinkedFirm()?.orgId || "",
      isActive: true,
      userId: user.id,
      tagIds: filters.tagsValues.map((tag) => tag.value) || [],
      yearEndMonth: filters.yearEnd.map((month) => month.value) || [],
      customerName: null,
      sortOrder: sortOrder,
      sortColumn: sortBy,
      searchQuery: debounceSearch || null,
      pageSize: 20,
      pageNumber: page,
    });

  const { data: customers, isLoading: isLoadingCustomers } =
    useGetSearchFilterCustomersQuery({
      searchQuery: debounceSearch || "ab",
      orgId: getLinkedFirm()?.orgId || "",
      userId: user.id,
    });

  const totalPages = Math.ceil((contacts?.[0]?.totalRecords || 0) / 20) || 1;

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  useEffect(() => {}, [contacts?.map((contact) => contact.customerTag)]);

  useEffect(() => {
    setSearchParams({
      sortBy: "Name",
      sortOrder: "asc",
      page: "1",
    });
  }, []);

  const handleImport = (file: File, variant: "individual" | "organization") => {
    if (file) {
      const formData = new FormData();
      formData.append("formFile", file);

      if (variant === "individual") {
        importContacts({
          body: formData,
          locationId: user?.org?.locations[0]?.id || "",
        });
      }

      if (variant === "organization") {
        importOrganizations({
          body: formData,
          locationId: user?.org?.locations[0]?.id || "",
        });
      }
    }
  };
  const getParams = (nextPage: number): Params => {
    const params: Params = {
      sortBy,
      sortOrder,
      page: (nextPage || 1).toString(),
    };
    return params;
  };

  const getContacts = () => {
    const options: SelectOptionType[] = [];
    if (customers) {
      customers.forEach((customer) => {
        options.push({
          value: customer.businessContactId
            ? customer.businessContactId
            : customer?.id,
          label: customer.businessContactId
            ? customer.businessContact?.companyName || ""
            : `${customer?.name}`,
          avatar: getInitialsFromFullName(customer?.name),
          avatarColor: customer.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };
  const yearEndMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const yearEndOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (yearEndMonth) {
      yearEndMonth.forEach((month) => {
        options.push({
          value: month,
          label: month,
        });
      });
    }

    return options;
  };
  const tagOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (tags) {
      tags.forEach((tag) => {
        options.push({
          value: tag.id,
          label: tag?.tagName,
        });
      });
    }

    return options;
  };

  if (isLoadingContacts) {
    return <LottieLoading />;
  }

  const onClickSort = (item: string) => {
    const params: Params = {
      sortBy: item,
      sortOrder: "",
      page: page.toString(),
    };
    if (sortBy === item) {
      params.sortOrder = sortOrder === "asc" ? "desc" : "asc";

      setSearchParams(params);
    } else {
      params.sortOrder = "asc";
      setSearchParams(params);
    }
  };
  return (
    <>
      <FormSection name={""} showHeading={false}>
        <div className={"flex justify-between"}>
          <div className={"flex flex-col"}>
            <div className={"text-lg font-bold"}>
              {contacts?.[0]?.totalRecords} Contacts
            </div>
            <div className={"flex gap-2"}>
              <div className={"input input-bordered flex items-center gap-2"}>
                <IoSearch size={"20px"} />
                <input
                  className={"w-[250px] outline-none"}
                  placeholder={"Search contacts"}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </div>

              {search && (
                <div
                  className={
                    "mt-2 cursor-pointer items-center text-xl text-gray-300"
                  }
                  onClick={() => setSearch("")}>
                  x
                </div>
              )}
            </div>
          </div>
          <div className={"flex items-center gap-4"}>
            <div className={"flex flex-col"}>
              <div className={"text-sm font-semibold text-gray-400"}>Tags</div>
              <div className={"w-[200px]"}>
                <CustomSelect
                  options={tagOptions()}
                  value={filters.tagsValues}
                  onChange={(newValue) => {
                    const value = newValue as SelectOptionType[];
                    setFilters((prev) => ({ ...prev, tagsValues: value }));
                  }}
                  placeholder={"All"}
                  inputValue={tagValue}
                  onChangeInput={setTagValue}
                  isMulti
                  isLoading={isFetching}
                  workTable
                />
              </div>
            </div>
            <div className={"flex items-center gap-4"}>
              <div className={"flex flex-col"}>
                <div className={"text-sm font-semibold text-gray-400"}>
                  Year end
                </div>
                <div className={"w-[200px]"}>
                  <CustomSelect
                    options={yearEndOptions()}
                    value={filters.yearEnd}
                    onChange={(newValue) => {
                      const value = newValue as SelectOptionType[];
                      setFilters((prev) => ({ ...prev, yearEnd: value }));
                    }}
                    placeholder={"All"}
                    inputValue={yearEndValue}
                    onChangeInput={setYearEndValue}
                    isMulti
                    isLoading={isFetching}
                    workTable
                  />
                </div>
              </div>
            </div>
            <Button
              buttonType={"button"}
              onClick={openModal}
              label={"Add Contact"}
              size={"small"}
            />
            <div className={"flex items-center"}>
              <IoMailOutline
                data-tooltip-id="tooltip-compose"
                onClick={() => {
                  setIsOpenCompose(true);
                }}
                color={"#7c66f0"}
                size={"25px"}
                className={"flex cursor-pointer items-center focus:outline-0"}
              />
            </div>
            <ReactTooltip
              id="tooltip-compose"
              place="bottom"
              content="Compose email"
              className={"!rounded-[8px] !p-2 !text-sm"}
            />
            <div className={"flex items-center"}>
              <ExcelExport
                data={
                  customersSearch.length
                    ? customersSearch.map((customer): ContactData => {
                        const phoneNumbers = customer.phoneNumber
                          ? customer.phoneNumber.split(",")
                          : [];
                        const emails = customer.email
                          ? customer.email.split(",")
                          : [];

                        const phoneColumns: { [key: string]: string } =
                          phoneNumbers.reduce(
                            (acc: { [key: string]: string }, phone, index) => {
                              acc[`Phone Number ${index + 1}`] = phone || "";
                              return acc;
                            },
                            {},
                          );
                        const emailColumns: { [key: string]: string } =
                          emails.reduce(
                            (acc: { [key: string]: string }, email, index) => {
                              acc[`Email Address ${index + 1}`] = email || "";
                              return acc;
                            },
                            {},
                          );

                        return {
                          Contact: customer.customerName,
                          ...phoneColumns,
                          ...emailColumns,
                          "Contact Type": customer.customerType,
                          "Open Work": customer.openWorkCounts,
                        };
                      })
                    : contacts?.map((contact): ContactData => {
                        const phoneNumbers = contact.phoneNumber
                          ? contact.phoneNumber.split(",")
                          : [];
                        const emails = contact.email
                          ? contact.email.split(",")
                          : [];

                        const phoneColumns: { [key: string]: string } =
                          phoneNumbers.reduce(
                            (acc: { [key: string]: string }, phone, index) => {
                              acc[`Phone Number ${index + 1}`] = phone || "";
                              return acc;
                            },
                            {},
                          );
                        const emailColumns: { [key: string]: string } =
                          emails.reduce(
                            (acc: { [key: string]: string }, email, index) => {
                              acc[`Email Address ${index + 1}`] = email || "";
                              return acc;
                            },
                            {},
                          );

                        return {
                          Contact: contact.customerName,
                          ...phoneColumns,
                          ...emailColumns,
                          "Contact Type": contact.customerType,
                          "Open Work": contact.openWorkCounts,
                        };
                      }) || []
                }
                fileName={"Contacts"}
              />
            </div>
            <div className={"ml-[-8px] flex items-center"}>
              <Dropdown
                triggerElement={
                  <div>
                    <Button
                      label={""}
                      colorType={"ghost"}
                      size={"custom"}
                      icon={<HiEllipsisVertical size={24} />}
                      extraClasses={
                        "min-h-fit h-fit w-fit p-1 flex items-center"
                      }
                    />
                  </div>
                }>
                <div
                  className={
                    "rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
                  }>
                  <div
                    className={
                      "flex cursor-pointer items-center gap-1 rounded-[5px] bg-white p-2 text-sm hover:bg-gray-300"
                    }
                    onClick={() => {
                      if (
                        !isLoadingImportContacts &&
                        !isLoadingImportOrganizations
                      ) {
                        setIsOpenImport(true);
                      }
                    }}>
                    <CiImport
                      color={"#7c66f0"}
                      size={"20px"}
                      strokeWidth={0.5}
                      className={
                        "flex cursor-pointer items-center focus:outline-0"
                      }
                    />{" "}
                    Import
                  </div>
                  <div
                    className={
                      "flex cursor-pointer items-center gap-1 rounded-[5px] bg-white p-2 text-sm hover:bg-gray-300"
                    }
                    onClick={() =>
                      deactivateContacts({
                        activateCustomer: false,
                        body: customersSearch.map((item) => item.customerId),
                      })
                    }>
                    <BsArchive
                      data-tooltip-id="tooltip-archive"
                      color={"#7c66f0"}
                      size={"20px"}
                      className={
                        "flex cursor-pointer items-center focus:outline-0 active:outline-0"
                      }
                    />{" "}
                    Archive
                  </div>
                  <div
                    className={
                      "flex cursor-pointer items-center gap-1 rounded-[5px] bg-white p-2 text-sm hover:bg-gray-300"
                    }
                    onClick={() => setAddTag(true)}>
                    <SlTag size={"20px"} className={"text-purple"} /> Tags
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        <AddContact isOpen={isOpen} closeModal={closeModal} isSearch={false} />
        <table className={"w-full"}>
          <thead className={"border-b-[1px] border-gray-300"}>
            <tr>
              <th
                className={
                  "items-center px-2 py-4 text-start text-sm normal-case text-gray-400"
                }>
                <input
                  className={
                    "checkbox items-center border-gray-300 [--chkbg:--b1] [--chkfg:--a] checked:border-gray-300 checked:bg-base-100"
                  }
                  onChange={(e) =>
                    e.target.checked
                      ? setCustomersSearch(contacts || [])
                      : setCustomersSearch([])
                  }
                  checked={(contacts?.length || 0) === customersSearch.length}
                  type="checkbox"
                />
              </th>
              <th
                className={
                  "px-2 py-4 text-start text-sm normal-case text-gray-400"
                }>
                <div
                  className={"flex cursor-pointer items-center gap-2"}
                  onClick={() => onClickSort("Name")}>
                  <div
                    className={classNames(
                      "whitespace-nowrap",
                      sortBy === "Name" && "text-[#7C66F0]",
                    )}>
                    Contact
                  </div>
                  <>
                    {sortBy === "Name" &&
                      (sortOrder === "asc" ? (
                        <FaArrowUp size={8} color={"#7C66F0"} />
                      ) : (
                        <FaArrowDown size={8} color={"#7C66F0"} />
                      ))}
                  </>
                </div>
              </th>
              <th
                className={
                  "max-w-[100px] px-2 py-4 text-start text-sm normal-case text-gray-400"
                }>
                <div
                  className={"flex cursor-pointer items-center gap-2"}
                  onClick={() => onClickSort("FirstName")}>
                  <div
                    className={classNames(
                      "whitespace-nowrap",
                      sortBy === "FirstName" && "text-[#7C66F0]",
                    )}>
                    First name
                  </div>
                  <>
                    {sortBy === "FirstName" &&
                      (sortOrder === "asc" ? (
                        <FaArrowUp size={8} color={"#7C66F0"} />
                      ) : (
                        <FaArrowDown size={8} color={"#7C66F0"} />
                      ))}
                  </>
                </div>
              </th>
              <th
                className={
                  "max-w-[100px] px-2 py-4 text-start text-sm normal-case text-gray-400"
                }>
                <div
                  className={"flex cursor-pointer items-center gap-2"}
                  onClick={() => onClickSort("LastName")}>
                  <div
                    className={classNames(
                      "whitespace-nowrap",
                      sortBy === "LastName" && "text-[#7C66F0]",
                    )}>
                    Last name
                  </div>
                  <>
                    {sortBy === "LastName" &&
                      (sortOrder === "asc" ? (
                        <FaArrowUp size={8} color={"#7C66F0"} />
                      ) : (
                        <FaArrowDown size={8} color={"#7C66F0"} />
                      ))}
                  </>
                </div>
              </th>
              <th
                className={
                  "px-2 py-4 text-start text-sm normal-case text-gray-400"
                }>
                Phone Number
              </th>
              <th
                className={
                  "max-w-[170px] px-2 py-4 text-start text-sm normal-case text-gray-400"
                }>
                Email Address
              </th>
              <th
                className={
                  "px-2 py-4 text-start text-sm normal-case text-gray-400"
                }>
                <div
                  className={"flex cursor-pointer items-center gap-2"}
                  onClick={() => onClickSort("ContactType")}>
                  <div
                    className={classNames(
                      "whitespace-nowrap",
                      sortBy === "ContactType" && "text-[#7C66F0]",
                    )}>
                    Contact type
                  </div>
                  <>
                    {sortBy === "ContactType" &&
                      (sortOrder === "asc" ? (
                        <FaArrowUp size={8} color={"#7C66F0"} />
                      ) : (
                        <FaArrowDown size={8} color={"#7C66F0"} />
                      ))}
                  </>
                </div>
              </th>
              <th
                className={
                  "px-2 py-4 text-start text-sm normal-case text-gray-400"
                }>
                <div
                  className={"flex cursor-pointer items-center gap-2"}
                  onClick={() => onClickSort("AllWorks")}>
                  <div
                    className={classNames(
                      "whitespace-nowrap",
                      sortBy === "AllWorks" && "text-[#7C66F0]",
                    )}>
                    All work
                  </div>
                  <>
                    {sortBy === "AllWorks" &&
                      (sortOrder === "asc" ? (
                        <FaArrowUp size={8} color={"#7C66F0"} />
                      ) : (
                        <FaArrowDown size={8} color={"#7C66F0"} />
                      ))}
                  </>
                </div>
              </th>
              <th
                className={
                  "px-2 py-4 text-start text-sm normal-case text-gray-400"
                }>
                <div
                  className={"flex cursor-pointer items-center gap-2"}
                  onClick={() => onClickSort("OpenWork")}>
                  <div
                    className={classNames(
                      "whitespace-nowrap",
                      sortBy === "OpenWork" && "text-[#7C66F0]",
                    )}>
                    Open work
                  </div>
                  <>
                    {sortBy === "OpenWork" &&
                      (sortOrder === "asc" ? (
                        <FaArrowUp size={8} color={"#7C66F0"} />
                      ) : (
                        <FaArrowDown size={8} color={"#7C66F0"} />
                      ))}
                  </>
                </div>
              </th>
              <th
                className={
                  "px-2 py-4 text-start text-sm normal-case text-gray-400"
                }>
                <div
                  className={"flex cursor-pointer items-center gap-2"}
                  onClick={() => onClickSort("YearEnd")}>
                  <div
                    className={classNames(
                      "whitespace-nowrap",
                      sortBy === "YearEnd" && "text-[#7C66F0]",
                    )}>
                    Year end
                  </div>
                  <>
                    {sortBy === "YearEnd" &&
                      (sortOrder === "asc" ? (
                        <FaArrowUp size={8} color={"#7C66F0"} />
                      ) : (
                        <FaArrowDown size={8} color={"#7C66F0"} />
                      ))}
                  </>
                </div>
              </th>
              <th
                className={
                  "px-2 py-4 text-start text-sm normal-case text-gray-400"
                }>
                Tags
              </th>
            </tr>
          </thead>
          <tbody>
            {contacts?.map((item) => (
              <tr
                key={item.id}
                className={"border-b-[1px] border-gray-300 hover:bg-gray-100"}>
                <td className={"w-[30px] pl-2"}>
                  <input
                    className={
                      "checkbox mt-1 border-gray-300 [--chkbg:--b1] [--chkfg:--a] checked:border-gray-300 checked:bg-base-100"
                    }
                    onChange={(e) =>
                      !e.target.checked
                        ? setCustomersSearch((prev) =>
                            prev.filter(
                              (i) => i.customerId !== item.customerId,
                            ),
                          )
                        : setCustomersSearch((prev) => [...prev, item])
                    }
                    type="checkbox"
                    checked={customersSearch.some(
                      (i) => i.customerId === item.customerId,
                    )}
                  />
                </td>
                <td className={"max-w-[200px] px-2 py-2"}>
                  <div className={"flex items-center gap-3 text-sm"}>
                    <AvatarPlaceholder
                      type={item.businessContactId ? "purple" : "blue"}
                      size={"2xs"}
                      label={getInitialsFromFullName(item.customerName)}
                    />
                    <div
                      onClick={() =>
                        navigate(
                          navigateToContact({
                            type: item.businessContactId
                              ? "organization"
                              : "contact",
                            id: item.businessContactId || item.contactId || "",
                            customerId: item.customerId || "",
                          }),
                        )
                      }
                      className={
                        "link-primary max-w-[200px] cursor-pointer truncate text-sm font-semibold hover:underline"
                      }>
                      {item?.customerName}
                    </div>
                  </div>
                </td>
                <td className={"max-w-[100px] px-2 py-2"}>
                  <div
                    className={
                      "text-sm font-semibold normal-case text-gray-800"
                    }>
                    {item?.businessContactId
                      ? "-"
                      : item?.customerName?.split(" ")[0]}
                  </div>
                </td>
                <td className={"max-w-[100px] px-2 py-2"}>
                  <div
                    className={
                      "text-sm font-semibold normal-case text-gray-800"
                    }>
                    {item?.businessContactId
                      ? "-"
                      : item?.customerName?.split("  ")[1]}
                  </div>
                </td>
                <td className={"px-2 py-2"}>
                  <div
                    className={
                      "max-w-[130px] text-sm font-semibold normal-case text-gray-800"
                    }>
                    {item?.phoneNumber?.split(",")[0]}
                  </div>
                </td>
                <td className={"max-w-[170px] px-2 py-2"}>
                  <div
                    className={
                      "link-primary max-w-[170px] cursor-pointer truncate text-sm font-semibold hover:underline"
                    }>
                    <a href={`mailto:${item?.email?.split(",")[0]}`}>
                      {item?.email?.split(",")[0]}
                    </a>
                  </div>
                </td>
                <td className={"px-2 py-2"}>
                  <div
                    className={
                      "text-sm font-semibold normal-case text-gray-800"
                    }>
                    {item.customerType}
                  </div>
                </td>
                <td className={"px-2 py-2"}>
                  <div
                    className={
                      "text-sm font-semibold normal-case text-gray-800"
                    }>
                    {item?.allWorkCounts}
                  </div>
                </td>
                <td className={"px-2 py-2"}>
                  <div
                    className={
                      "text-sm font-semibold normal-case text-gray-800"
                    }>
                    {item?.openWorkCounts}
                  </div>
                </td>
                <td className={"px-2 py-2"}>
                  <div
                    className={
                      "text-sm font-semibold normal-case text-gray-800"
                    }>
                    {item?.yearEndMonth}{" "}
                    {item?.yearEndDay !== 0 && item?.yearEndDay}
                  </div>
                </td>
                <td className={"px-2 py-2"}>
                  <div
                    className={
                      "w-[110px] text-sm font-semibold normal-case text-gray-800"
                    }>
                    {item?.customerTag}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!!contacts?.length && (
          <div>
            <Pagination
              numPages={totalPages}
              currentPage={page}
              onPageChange={({ nextPage }) => {
                if (!isLoadingContacts) {
                  setSearchParams(getParams(nextPage));
                }
              }}
            />
          </div>
        )}
      </FormSection>

      {isOpenCompose && (
        <ComposeEmailModal
          isActive={isOpenCompose}
          onClose={() => setIsOpenCompose(false)}
          bulk={true}
          customersEmail={customersSearch.map((customer) => ({
            email: customer.email,
          }))}
        />
      )}
      {isOpenImport && (
        <ImportContacts
          handleImport={(file, variant) => handleImport(file, variant)}
          isOpen={isOpenImport}
          closeModal={() => setIsOpenImport(false)}
        />
      )}
      {addTag && (
        <div className={`modal ${addTag ? "modal-open" : "modal-close"}`}>
          <div
            className={
              "modal-box flex h-[500px] w-[550px] flex-wrap rounded-md bg-white px-3 py-2"
            }>
            <div className={"h-[60px] w-full pb-2"}>
              <CustomSelect
                options={tagOptions()}
                value={tagsValue}
                fullWidth
                onChange={(newValue) => {
                  const value = newValue as SelectOptionType[];
                  setTagsValue(value);
                }}
                inputValue={tagValue}
                onChangeInput={setTagValue}
                isMulti
                isLoading={isFetching}
                height={"h-[170px]"}
                autoFocus
              />
            </div>
            <div
              className={
                "mt-4 flex h-[300px] w-full flex-wrap gap-0 overflow-y-auto px-2"
              }>
              <div className={"h-fit w-full"}>
                {tagsValue.map((i) => (
                  <div
                    className={
                      "flex h-[30px] w-[100%] items-center justify-between border-b border-gray-200 px-2 py-2"
                    }>
                    {i.label}
                  </div>
                ))}
              </div>
            </div>
            <div className={"text-base font-semibold"}>
              This tags will be added to the selected clients
            </div>
            <div className={"flex h-[10%] w-full items-end justify-end gap-2"}>
              <Button
                label={"Add"}
                extraClasses={"min-h-[40px] h-[40px] flex items-center !py-2"}
                onClick={() => {
                  addMultipleTags({
                    tagIds: tagsValue?.map((i) => i.value),
                    customerIds: customersSearch?.map((i) => i.customerId),
                  });
                  setAddTag(false);
                  setCustomersSearch([]);
                }}
              />
              <Button
                label={"Cancel"}
                colorType={"outline"}
                customSize={"px-1 py-1"}
                extraClasses={"min-h-[40px] h-[40px] flex items-center !py-2"}
                onClick={() => {
                  setAddTag(false);
                  setTagsValue([]);
                  setCustomersSearch([]);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
