import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDebounce } from "usehooks-ts";

import Button from "../../../components/button/Button";
import CustomSelect from "../../../components/select/Select";
import { getInitialsFromFullName } from "../../../constants/constants";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { useGetSearchCustomersQuery } from "../../../slices/ContactsSlice";
import {
  useAddContactLinkedProfileMutation,
  useAddOrganizationLinkedProfileMutation,
  useUpdateContactLinkedProfileMutation,
  useUpdateOrganizationLinkedProfileMutation,
} from "../../../slices/LinkedProfilesSlice";
import { SelectOptionType } from "../../../types/types";
import { ConfirmLinkedProfileModal } from "./ConfirmLinkdeProfileModal";
import { useAppSelector } from "../../../redux/redux";

type Props = {
  isActive: boolean;
  onClose: () => void;
  defaultValues?: FormData & { id: string };
  contactId?: string;
  businessContactId?: string;
};

type FormData = {
  customer: SelectOptionType;
  relationship: string;
};

export const LinkedProfileModal: FC<Props> = ({
  isActive,
  onClose,
  defaultValues,
  contactId,
  businessContactId,
}) => {
  const [searchContacts, setSearchContacts] = useState("");

  const searchContactsDebounce = useDebounce(searchContacts, 50);
  const { user } = useAppSelector((state) => state.appReducer);

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetSearchCustomersQuery({
      searchQuery: searchContactsDebounce || "ab",
      orgId: getLinkedFirm()?.orgId || "",
      userId: user.id,
    });

  const [
    addContactLinkedProfile,
    { isLoading: isLoadingContactAdd, isSuccess: isSuccessContactAdd },
  ] = useAddContactLinkedProfileMutation();
  const [
    updateContactLinkedProfile,
    { isLoading: isLoadingContactUpdate, isSuccess: isSuccessContactUpdate },
  ] = useUpdateContactLinkedProfileMutation();

  const [
    addOrganizationLinkedProfile,
    {
      isLoading: isLoadingOrganizationAdd,
      isSuccess: isSuccessOrganizationAdd,
    },
  ] = useAddOrganizationLinkedProfileMutation();
  const [
    updateOrganizationLinkedProfile,
    {
      isLoading: isLoadingOrganizationUpdate,
      isSuccess: isSuccessOrganizationUpdate,
    },
  ] = useUpdateOrganizationLinkedProfileMutation();

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormData>({
    defaultValues: defaultValues,
  });

  const onSubmit = (data: FormData) => {
    if (defaultValues) {
      if (defaultValues.customer.value !== data.customer.value) {
        setIsOpenConfirm(true);
      } else {
        if (contactId) {
          updateContactLinkedProfile({
            id: defaultValues.id,
            linkedCustomerId: data.customer.value,
            relation: data.relationship,
            contactId: contactId,
          });
        }
        if (businessContactId) {
          updateOrganizationLinkedProfile({
            id: defaultValues.id,
            linkedCustomerId: data.customer.value,
            relation: data.relationship,
          });
        }
      }
    } else {
      setIsOpenConfirm(true);
    }
  };

  const confirm = () => {
    const data = getValues();
    if (defaultValues) {
      if (contactId) {
        updateContactLinkedProfile({
          id: defaultValues.id,
          linkedCustomerId: data.customer.value,
          relation: data.relationship,
          contactId: contactId,
        });
      }
      if (businessContactId) {
        updateOrganizationLinkedProfile({
          id: defaultValues.id,
          linkedCustomerId: data.customer.value,
          relation: data.relationship,
        });
      }
    } else {
      if (contactId) {
        addContactLinkedProfile({
          relation: data.relationship,
          linkedCustomerId: data.customer.value,
          contactId: contactId || undefined,
          businessContactId: businessContactId || undefined,
        });
      }
      if (businessContactId) {
        addOrganizationLinkedProfile({
          relation: data.relationship,
          linkedCustomerId: data.customer.value,
          contactId: contactId || undefined,
          businessContactId: businessContactId || undefined,
        });
      }
    }
  };

  const contactsOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact?.customerId,
          label: `${contact.customerName}`,
          avatar: getInitialsFromFullName(contact?.customerName),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };

  useEffect(() => {
    if (
      isSuccessContactAdd ||
      isSuccessContactUpdate ||
      isSuccessOrganizationAdd ||
      isSuccessOrganizationUpdate
    ) {
      setIsOpenConfirm(false);
      onClose();
    }
  }, [
    isSuccessContactAdd,
    isSuccessContactUpdate,
    isSuccessOrganizationAdd,
    isSuccessOrganizationUpdate,
  ]);

  return (
    <>
      <div
        className={classNames(
          "modal",
          isActive ? "modal-open" : "modal-close",
        )}>
        <div className={"modal-box p-0"}>
          <div
            className={
              "border-b border-gray-300 px-5 py-4 text-center text-lg font-bold"
            }>
            {defaultValues ? "Update" : "Add"} Linked Profile
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={"px-5 py-4"}>
            <div className={"mb-4"}>
              <div className={"label-text mb-2"}>Name</div>
              <Controller
                control={control}
                name={"customer"}
                rules={{
                  required: "This field is required",
                }}
                render={({ field: { value, onChange } }) => (
                  <CustomSelect
                    options={contactsOptions()}
                    value={value}
                    onChange={(newValue) => {
                      const newContact = newValue as SelectOptionType;
                      onChange(newContact);
                    }}
                    inputValue={searchContacts}
                    onChangeInput={setSearchContacts}
                    fullWidth
                    withAvatar
                    isLoading={isLoadingContacts}
                    placeholder={"Search contacts"}
                  />
                )}
              />
              {errors?.customer?.message && (
                <div className={"mt-2 text-sm font-bold text-error"}>
                  {errors?.customer?.message}
                </div>
              )}
            </div>
            <div>
              <div className={"label-text mb-2"}>Relationship</div>
              <input
                type={"text"}
                {...register("relationship", {
                  required: "This field is required",
                })}
                className={"input input-bordered w-full"}
                placeholder={"Relationship"}
              />
              {errors?.relationship?.message && (
                <div className={"mt-2 text-sm font-bold text-error"}>
                  {errors?.relationship?.message}
                </div>
              )}
            </div>
            <div className={"mt-4 flex justify-end gap-4"}>
              <Button
                label={defaultValues ? "Update" : "Add"}
                buttonType={"submit"}
              />
              <Button
                label={"Cancel"}
                onClick={onClose}
                colorType={"outline"}
              />
            </div>
          </form>
        </div>
      </div>
      {isOpenConfirm && (
        <ConfirmLinkedProfileModal
          isActive={isOpenConfirm}
          onClose={() => setIsOpenConfirm(false)}
          confirm={confirm}
          isLoading={
            isLoadingContactAdd ||
            isLoadingContactUpdate ||
            isLoadingOrganizationAdd ||
            isLoadingOrganizationUpdate
          }
        />
      )}
    </>
  );
};
