import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Button from "../../../components/button/Button";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  HomeFilter,
  HomeTab,
} from "../../../factories/works/models/home-enums";
import { useAppSelector } from "../../../redux/redux";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import {
  useArchiveOrUnarchiveMutation,
  useGetFirmsWorksCountsQuery,
  useGetHomeWorksQuery,
  useUpdateMultipleStatusesMutation,
} from "../../../slices/WorkSlice";
import { WorkTableSort } from "../../works/components/types/work-table";
import { WorkList } from "../../works/components/WorkList";
import { AddWorkModal } from "../../works/modals/AddWork.modal";
import { HomeStats } from "./HomeStats";
import { BsArchive } from "react-icons/bs";
import { WorkModel } from "../../../factories/works/models/work.model";
import { SelectOptionType } from "../../../types/types";
import CustomSelect from "../../../components/select/Select";
import { useGetWorkTypesQuery } from "../../../slices/WorkTypeSlice";
import { Filters } from "../../works/pages/AllWorks";
import { useGetStatusesQuery } from "../../../slices/StatusesSlice";
import { HiXMark } from "react-icons/hi2";
import classNames from "classnames";
import { StatusesDropdown } from "../../works/dropdown/StatusesDropdown";

export const HomeMyWorks: FC = () => {
  const { user } = useAppSelector((state) => state.appReducer);

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get("home-tab") as HomeTab;
  const activeFilter = searchParams.get("home-filter") as HomeFilter;
  const page = +(searchParams.get("page") || 1);
  const sortBy = searchParams.get("sortBy") as WorkTableSort;
  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc";

  const [archiveWorks, setArchiveWorks] = useState<WorkModel[]>([]);
  const [filters, setFilters] = useState<Filters>({
    customerIds: [],
    workName: [],
    userIds: [],
    workStatuses: [],
    worktypeIds: [],

    startDates: [],
    dueDates: [],
    fromDate: null,
    toDate: null,
  });
  const [isAddWork, setIsAddWork] = useState(false);
  const [searchWorks, setSearchWorks] = useState("");
  const [isOpenStatuses, setIsOpenStatuses] = useState(false);

  const [activateDeactivate] = useArchiveOrUnarchiveMutation();

  const {
    data: counts,
    isLoading: isLoadingCounts,
    isError,
  } = useGetFirmsWorksCountsQuery({
    orgId: getLinkedFirm()?.orgId || "",
    userId: user?.id || "",
    isMyWork: true,
  });

  const { data: works, isLoading: isLoadingWorks } = useGetHomeWorksQuery({
    pageNumber: page,
    pageSize: 20,
    sortBy: sortBy,
    sortOrder: sortOrder,
    dueDates:
      activeFilter === HomeFilter.OverDue ? HomeFilter.OverDue : activeTab,
    status: filters.workStatuses.map((item) => item.label) || [],
    assignedUserId: user?.id,
    orgId: getLinkedFirm()?.orgId || "",
    worktypeIds: filters.worktypeIds.map((item) => item.value) || [],
  });
  const { data: workTypes, isLoading: isLoadingWorkTypes } =
    useGetWorkTypesQuery({ orgId: getLinkedFirm()?.orgId || "" });
  const { data: statuses, isLoading: isLoadingStatuses } = useGetStatusesQuery({
    orgId: getLinkedFirm()?.orgId || "",
  });
  const [updateStatuses] = useUpdateMultipleStatusesMutation();

  useEffect(() => {
    if (!activeTab) {
      setSearchParams({
        "home-tab": HomeTab.ThisWeek,
        page: page?.toString() || "",
        sortBy: sortBy?.toString() || "",
        sortOrder: sortOrder?.toString() || "",
      });
    }
  }, [activeTab]);

  useEffect(() => {
    setSearchParams({
      "home-tab": activeTab || "",
      page: "1",
      sortBy: WorkTableSort.StartDate,
      sortOrder: "desc",
    });
  }, []);

  const workTypeOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (workTypes) {
      workTypes?.forEach((item) => {
        options.push({
          label: item.name,
          value: item.id,
        });
      });
    }
    return options;
  };
  const statusesOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (statuses) {
      statuses?.forEach((item) => {
        options.push({
          label: item?.name,
          value: item?.id,
          color: item?.color,
          isBold: true,
        });
        if (
          item?.workTypeSubStatuses &&
          item?.workTypeSubStatuses?.length > 0
        ) {
          item?.workTypeSubStatuses?.forEach((subItem) => {
            options.push({
              label: subItem?.subStatus,
              value: subItem?.id,
              color: item?.color,
            });
          });
        }
      });
    }
    return options;
  };
  const clearValues = () => {
    setFilters({
      customerIds: [],
      workName: [],
      userIds: [],
      workStatuses: [],
      worktypeIds: [],

      startDates: [],
      dueDates: [],
      fromDate: null,
      toDate: null,
    });
  };

  const isShowClearButton =
    !!filters?.customerIds.length ||
    !!filters?.worktypeIds.length ||
    !!filters?.workName.length ||
    !!filters?.userIds.length ||
    !!filters?.workStatuses.length ||
    !!filters?.startDates?.length ||
    !!filters?.dueDates?.length;

  const multipleStatuses = [
    {
      color: "#93AAFD",
      name: "Ready",
      statusId: "f63feb60-c2f8-49ed-b099-b7846ecd10b2",
    },
    {
      color: "#FFD80B",
      name: "In Progress",
      statusId: "efaa8fdd-1b4c-4dda-8395-395d15294750",
    },
    {
      color: "#F15252",
      name: "Waiting",
      statusId: "9dae62a0-3f78-4a69-a5f6-240aded90540",
    },
    {
      color: "#47DE96",
      name: "Completed",
      statusId: "7116afd6-80c6-4d00-99da-7b17d782440b",
    },
  ];
  if (isLoadingCounts) {
    return <LottieLoading />;
  }

  if (isError) {
    return null;
  }

  return (
    <>
      <div className={"grid h-full w-full grid-rows-[max-content_1fr] gap-6"}>
        <HomeStats
          thisWeek={counts?.thisWeek?.total || 0}
          nextWeek={counts?.nextWeek?.total || 0}
          later={counts?.later?.total || 0}
          allOpen={counts?.allOpen?.total || 0}
          completed={counts?.completed || 0}
        />
        <WorkList
          works={works || []}
          statusFilter={filters.workStatuses.map((i) => i.label)}
          archiveWorks={archiveWorks}
          setArchiveWorks={setArchiveWorks}
          isLoading={isLoadingWorks}
          myWorkHome={true}
          filter={
            activeFilter === HomeFilter.OverDue ? HomeFilter.OverDue : activeTab
          }
          //totalWorks={getTotalWorks() || 0}
          hasPages>
          <div
            className={
              "grid grid-cols-[1fr_max-content] items-start gap-2 px-8 pt-6"
            }>
            <div className={"flex items-center gap-4"}>
              <div className={"font-medium"}>My work</div>
              <div className={"flex items-center gap-4 pb-4"}>
                <div className={"w-[150px] min-w-[100px]"}>
                  <div className={"text-sm font-semibold text-gray-400"}>
                    Work Type
                  </div>
                  <CustomSelect
                    options={workTypeOptions()}
                    value={filters?.worktypeIds}
                    onChange={(newValue) => {
                      const value = newValue as SelectOptionType[];
                      setFilters((prev) => ({
                        ...prev,
                        worktypeIds: value,
                      }));
                    }}
                    small
                    inputValue={searchWorks}
                    onChangeInput={setSearchWorks}
                    isMulti
                    isLoading={isLoadingWorkTypes}
                    workTable
                    placeholder={"All"}
                    width={"250px"}
                  />
                </div>
                <div className={"w-[150px] min-w-[100px]"}>
                  <div className={"text-sm font-semibold text-gray-400"}>
                    Status
                  </div>

                  <CustomSelect
                    options={statusesOptions()}
                    value={filters.workStatuses}
                    onChange={(newValue) => {
                      const newStatuses = Array.isArray(newValue)
                        ? newValue
                        : [newValue];
                      setFilters((prev) => ({
                        ...prev,
                        workStatuses: newStatuses,
                      }));
                    }}
                    workTable
                    small
                    isLoading={isLoadingStatuses}
                    placeholder={"All"}
                    width={"250px"}
                  />
                </div>
                {isShowClearButton && (
                  <div className={"mt-4 flex items-center"}>
                    <Button
                      label={"Clear"}
                      onClick={clearValues}
                      colorType={"ghost"}
                      icon={<HiXMark size={20} />}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={"flex items-center gap-4"}>
              <div className={"relative"}>
                <div
                  className={classNames(
                    !!archiveWorks.length ? "text-purple" : "text-gray-300",
                    "w-[120px] cursor-pointer text-base font-semibold",
                  )}
                  onClick={() => setIsOpenStatuses(!isOpenStatuses)}>
                  Change status
                </div>
                {isOpenStatuses && (
                  <div>
                    <div
                      className={
                        "absolute top-[30px] z-10 flex w-[150px] flex-col gap-1 rounded-[8px] border border-black border-opacity-[0.2] bg-white"
                      }>
                      {multipleStatuses.map((status) => (
                        <div
                          className={
                            "flex cursor-pointer items-center gap-2 px-4 py-2"
                          }
                          onClick={() => {
                            updateStatuses({
                              body: archiveWorks?.map((i) => i.workId),
                              status: status.name,
                            });
                            setIsOpenStatuses(false);
                            setArchiveWorks([]);
                          }}>
                          <div
                            style={{ backgroundColor: status.color }}
                            className={"h-2.5 w-2.5 rounded-[50%]"}>
                            {" "}
                          </div>
                          <div className={"text-sm font-semibold"}>
                            {status.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div
                aria-disabled={!!archiveWorks.length}
                onClick={() => {
                  activateDeactivate({
                    body: archiveWorks.map((i) => i.workId),
                    archiveWork: true,
                  });
                  setArchiveWorks([]);
                }}>
                <BsArchive
                  data-tooltip-id="tooltip-archive"
                  color={"#7c66f0"}
                  size={"20px"}
                  className={
                    "flex cursor-pointer items-center focus:outline-0 active:outline-0"
                  }
                />
              </div>
              <Button
                buttonType={"button"}
                label={"Add work"}
                onClick={() => setIsAddWork(true)}
              />
            </div>
          </div>
        </WorkList>
      </div>
      {isAddWork && (
        <AddWorkModal isOpen={isAddWork} onClose={() => setIsAddWork(false)} />
      )}
    </>
  );
};
