import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import {
  ContactNoteModel,
  CreateContactNoteModel,
} from "../factories/contacts/models/contact-note.models";
import {
  ContactTagModel,
  ContactTagsModel,
} from "../factories/contacts/models/contact-tag.model";
import { IContactType, SelectOptionType } from "../types/types";
import { billingAPI } from "../services/BillingService";
import { workSlice } from "./WorkSlice";
import { filesSlice } from "./FilesSlice";
import { linkedProfilesSlice } from "./LinkedProfilesSlice";
import { contactNotesSlice } from "./ContactNotesSlice";
import { organizationNotesSlice } from "./OrganizationNotesSlice";
import { contactsSlice } from "./ContactsSlice";

export const contactTagsSlice = createApi({
  reducerPath: "contactTagsSlice",
  tagTypes: ["ContactTag"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getTags: build.query<ContactTagModel[], { orgId: string }>({
      query: ({ orgId }) => ({
        url: `api/tags/get-by-orgId/`,
        method: "GET",
        params: { orgId },
      }),
      providesTags: ["ContactTag"],
    }),
    addTag: build.mutation<
      ContactTagModel[],
      { tagName: string; orgId: string }
    >({
      query: ({ tagName, orgId }) => ({
        url: `api/tags/add-tag/`,
        method: "POST",
        body: { tagName, orgId },
      }),
      invalidatesTags: ["ContactTag"],
    }),
    addMultipleTag: build.mutation<
      ContactTagModel[],
      { tagIds: string[]; customerIds: string[] }
    >({
      query: ({ tagIds, customerIds }) => ({
        url: `api/tags/add-multiple/`,
        method: "POST",
        body: { tagIds, customerIds },
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(contactsSlice.util?.invalidateTags(["Contacts"]));
        });
      },
      invalidatesTags: ["ContactTag"],
    }),
    updateTag: build.mutation<
      ContactTagModel[],
      { tagName: string; id: string }
    >({
      query: ({ tagName, id }) => ({
        url: `api/tags/update-tag/`,
        method: "PUT",
        body: { tagName, id },
      }),
      invalidatesTags: ["ContactTag"],
    }),
    deleteTag: build.mutation<
      ContactTagModel[],
      { tagName: string; id: string }
    >({
      query: ({ tagName, id }) => ({
        url: `api/tags/delete-tag/`,
        method: "DELETE",
        body: { tagName, id },
      }),
      invalidatesTags: ["ContactTag"],
    }),
    addCustomerTags: build.mutation<
      ContactTagModel[],
      { customerId: string; tagIds: string[] }
    >({
      query: ({ customerId, tagIds }) => ({
        url: `api/tags/add-customer-tag/`,
        method: "POST",
        params: { customerId },
        body: tagIds,
      }),
      invalidatesTags: ["ContactTag"],
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(contactsSlice.util?.invalidateTags(["Contacts"]));
        });
      },
    }),
    getCustomerTags: build.query<ContactTagsModel[], { customerId: string }>({
      query: ({ customerId }) => ({
        url: `api/tags/tags-by-customerId/`,
        method: "GET",
        params: { customerId },
      }),
      providesTags: ["ContactTag"],
    }),
    deleteCustomerTag: build.mutation<
      ContactTagModel[],
      { tagIds: string[]; customerId: string }
    >({
      query: ({ tagIds, customerId }) => ({
        url: `api/tags/update-customer-tag/`,
        method: "PUT",
        params: { customerId },
        body: tagIds,
      }),
      invalidatesTags: ["ContactTag"],
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(contactsSlice.util?.invalidateTags(["Contacts"]));
        });
      },
    }),
  }),
});
export const {
  useGetTagsQuery,
  useAddTagMutation,
  useAddMultipleTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
  //Customers
  useAddCustomerTagsMutation,
  useGetCustomerTagsQuery,
  useDeleteCustomerTagMutation,
} = contactTagsSlice;
