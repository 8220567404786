import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";

import { ValidationConstants } from "../../../../../../../../app/constants/ValidationConstants";
import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import CustomSelect from "../../../../../../../../components/select/Select";
import {
  useEditContactMutation,
  useGetContactTypesQuery,
} from "../../../../../../../../slices/ContactsSlice";
import {
  IContactProfile,
  IPhoneType,
  SelectOptionType,
} from "../../../../../../../../types/types";
import * as devConfig from "../../../../../../../../app/configs/devConfig";
import { getAuthHeader } from "../../../../../../../../app/globalState/GlobalAuthState";
import { useEffectOnce } from "usehooks-ts";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { Address } from "../../../../../../../../factories/contacts/models/update-organization.model";

type Props = {
  contact: IContactProfile;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
};

type FormData = {
  firstName: string;
  middleName: string;
  lastName: string;
  socialIdentificationNumber: string;
  email: string;
  phoneNumber: string;
  phoneNumberType: SelectOptionType;
  contactType: SelectOptionType;
  address: {
    line1: string;
    city: string;
    region: string;
    postalCode: string;
  };
  additionalInfo: string;
};

export const ContactEditInfo: FC<Props> = ({ contact, setIsEdit }) => {
  const [phoneTypes, setPhoneTypes] = useState<SelectOptionType[]>([]);

  const { data: contactTypes, isLoading } = useGetContactTypesQuery();
  const [editContact, { isSuccess, isLoading: isLoadingEdit }] =
    useEditContactMutation();
  const [emailsData, setEmailsData] = useState<
    { emailAddress: string; id: string }[]
  >(() => {
    return [
      {
        id: contact?.emails?.[0]?.id || uuidv4(),
        emailAddress: contact?.emails?.[0]?.emailAddress || "",
      },
    ];
  });
  const [phoneData, setPhoneData] = useState<
    { type: SelectOptionType; number: string; id: string }[]
  >(() => {
    return [
      {
        id: contact?.phoneNumbers?.[0]?.id || uuidv4(),
        number: contact?.phoneNumbers?.[0]?.number || "",
        type: {
          value:
            contact?.phoneNumbers?.[0]?.phoneNumberTypeEnumId ||
            "bcd697a8-ef4c-4f91-bb8f-891df1ddfba0",
          label:
            contact?.phoneNumbers?.[0]?.phoneNumberTypeEnum?.phoneNumberType ||
            "Mobile",
        },
      },
    ];
  });
  const [addressesData, setAddressesData] = useState<Address[]>(() => {
    return [
      {
        id: contact?.addresses?.[0]?.id || uuidv4(),
        line1: contact?.addresses?.[0]?.line1,
        city: contact?.addresses?.[0]?.city,
        region: contact?.addresses?.[0]?.region,
        postalCode: contact?.addresses?.[0]?.postalCode,
      },
    ];
  });

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      firstName: contact?.firstName,
      middleName: contact?.middleName,
      lastName: contact?.lastName,
      socialIdentificationNumber: contact?.socialIdentificationNumber,
      email: contact?.emails?.[0]?.emailAddress,
      phoneNumber: contact?.phoneNumbers?.[0]?.number,
      contactType: {
        value: contact?.contactTypeEnumId,
        label: contact?.contactType,
      },
      phoneNumberType: {
        value: contact?.phoneNumberTypeEnumId,
        label: contact?.phoneNumberType,
      },
      address: {
        line1: contact?.addresses?.[0]?.line1,
        city: contact?.addresses?.[0]?.city,
        region: contact?.addresses?.[0]?.region,
        postalCode: contact?.addresses?.[0]?.postalCode,
      },
      additionalInfo: contact?.additionalInfo,
    },
  });
  const onClickAddEmail = () => {
    setEmailsData((prevData) => [
      ...prevData,
      {
        id: uuidv4(),
        emailAddress: "",
      },
    ]);
  };
  const onClickAddPhone = () => {
    setPhoneData((prevData) => [
      ...prevData,
      {
        id: uuidv4(),
        number: "",
        type: {
          label: "Mobile",
          value: "bcd697a8-ef4c-4f91-bb8f-891df1ddfba0",
        },
      },
    ]);
  };
  const onClickAddAddress = () => {
    setAddressesData((prevData) => [
      ...prevData,
      {
        id: uuidv4(),
        line1: "",
        postalCode: "",
        city: "",
        region: "",
      },
    ]);
  };
  const onClickDeleteEmailItem = (id: string, index: number) => {
    if (emailsData?.length > 1) {
      setEmailsData(() => emailsData?.filter((_, i) => i !== index));
    }
  };
  const onClickDeleteItem = (id: string, index: number) => {
    if (phoneData?.length > 1) {
      setPhoneData(() => phoneData?.filter((_, i) => i !== index));
    }
  };
  const onClickDeleteAddressItem = (id: string, index: number) => {
    if (addressesData?.length > 1) {
      setAddressesData(() => addressesData?.filter((_, i) => i !== index));
    }
  };

  const emailsItems = (emailsData || [])?.map((item, index) => {
    return (
      <div className={"flex w-full gap-4"}>
        <input
          placeholder={"Email"}
          value={item.emailAddress}
          onChange={(e) => {
            const newEmail = e.target.value;
            setEmailsData((prevState) =>
              prevState.map((item, idx) =>
                idx === index ? { ...item, emailAddress: newEmail } : item,
              ),
            );
          }}
          type={"text"}
          className={"input input-bordered input-sm mb-2 w-full"}
        />
        {errors.email && (
          <span className="pt-2 text-sm font-bold text-error">
            {errors.email.message}
          </span>
        )}
        {emailsData?.length > 1 ? (
          <div
            className={"bg-transparent text-lg text-red-600"}
            onClick={() => onClickDeleteEmailItem(item?.id || "", index)}>
            <FontAwesomeIcon icon={faTrashCan} className={"cursor-pointer"} />
          </div>
        ) : (
          <div className={"bg-transparent text-lg text-gray-300"}>
            <FontAwesomeIcon icon={faTrashCan} />
          </div>
        )}
      </div>
    );
  });
  const phoneItems = (phoneData || [])?.map((item, index) => {
    return (
      <div className={"flex w-full gap-4"}>
        <div className={"form-control h-[40px] w-[200px]"}>
          <CustomSelect
            small
            options={phoneTypes}
            value={item.type}
            onChange={(newValue) => {
              const newType = Array.isArray(newValue) ? newValue[0] : newValue;
              setPhoneData((prevState) =>
                prevState.map((item, idx) =>
                  idx === index ? { ...item, type: newType } : item,
                ),
              );
            }}
          />
          {errors?.phoneNumberType?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.phoneNumberType?.message}
            </span>
          )}
        </div>
        <input
          placeholder={"Phone number"}
          value={item.number}
          type={"text"}
          onInput={(e: React.FormEvent<HTMLInputElement>) => {
            const target = e.target as HTMLInputElement;
            target.value = target.value.replace(/[^0-9]/g, "");
          }}
          onChange={(e) => {
            const newNumber = e.target.value;
            setPhoneData((prevState) =>
              prevState.map((item, idx) =>
                idx === index ? { ...item, number: newNumber } : item,
              ),
            );
          }}
          className={"input input-bordered input-sm w-full"}
        />
        {phoneData?.length > 1 ? (
          <div
            className={"bg-transparent text-lg text-red-600"}
            onClick={() => onClickDeleteItem(item?.id || "", index)}>
            <FontAwesomeIcon icon={faTrashCan} className={"cursor-pointer"} />
          </div>
        ) : (
          <div className={"bg-transparent text-lg text-gray-300"}>
            <FontAwesomeIcon icon={faTrashCan} />
          </div>
        )}
      </div>
    );
  });
  const addressesItems = (addressesData || [])?.map((item, index) => {
    return (
      <div className={"mb-4 flex w-full gap-4"} key={item.id}>
        <div className={"grid grid-rows-[repeat(3,max-content)] gap-4"}>
          <input
            placeholder={"Street address"}
            value={item.line1}
            onChange={(e) => {
              const newLine1 = e.target.value;
              setAddressesData((prevState) =>
                prevState.map((item, idx) =>
                  idx === index ? { ...item, line1: newLine1 } : item,
                ),
              );
            }}
            type={"text"}
            className={"input input-bordered input-sm w-full"}
          />
          <div className={"grid grid-cols-2 gap-4"}>
            <input
              placeholder={"City"}
              value={item.city}
              onChange={(e) => {
                const newCity = e.target.value;
                setAddressesData((prevState) =>
                  prevState.map((item, idx) =>
                    idx === index ? { ...item, city: newCity } : item,
                  ),
                );
              }}
              type={"text"}
              className={"input input-bordered input-sm w-full"}
            />
            <input
              placeholder={"State / Province"}
              value={item.region}
              onChange={(e) => {
                const newRegion = e.target.value;
                setAddressesData((prevState) =>
                  prevState.map((item, idx) =>
                    idx === index ? { ...item, region: newRegion } : item,
                  ),
                );
              }}
              type={"text"}
              className={"input input-bordered input-sm w-full"}
            />
          </div>
          <div className={"grid grid-cols-2 gap-4"}>
            <input
              placeholder={"Zip / Postal Code"}
              onChange={(e) => {
                const newPostalCode = e.target.value;
                setAddressesData((prevState) =>
                  prevState.map((item, idx) =>
                    idx === index
                      ? { ...item, postalCode: newPostalCode }
                      : item,
                  ),
                );
              }}
              value={item.postalCode}
              type={"text"}
              className={"input input-bordered input-sm w-full"}
            />
            <div />
          </div>
        </div>
        {addressesData?.length > 1 ? (
          <div
            className={"bg-transparent text-lg text-red-600"}
            onClick={() => onClickDeleteAddressItem(item?.id || "", index)}>
            <FontAwesomeIcon icon={faTrashCan} className={"cursor-pointer"} />
          </div>
        ) : (
          <div className={"bg-transparent text-lg text-gray-300"}>
            <FontAwesomeIcon icon={faTrashCan} />
          </div>
        )}
      </div>
    );
  });
  const getPhoneTypes = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/phone-num-types/all`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IPhoneType[] = await response.json();
      const sortData = data.sort((a: any, b: any) => {
        if (a?.phoneNumberType === "Mobile") {
          return -1;
        } else if (b?.phoneNumberType === "Mobile") {
          return 1;
        } else {
          return 0;
        }
      });

      const options: SelectOptionType[] = sortData.map((item) => ({
        value: item?.id,
        label: item?.phoneNumberType,
      }));

      setValue("phoneNumberType", {
        value: contact?.phoneNumberTypeEnumId,
        label: contact?.phoneNumberType,
      });
      setPhoneTypes(options);
    }
  };

  const getDataForAddContact = async () => {
    await getPhoneTypes();
  };
  useEffectOnce(() => {
    getDataForAddContact().then();
  });

  const { contactNameRule } = ValidationConstants.customerRules;

  const onSubmit = (data: FormData) => {
    if (!isLoadingEdit) {
      editContact({
        ...contact,
        contactId: contact?.contactId || "",
        customerId: contact?.customerId || "",
        locationId: contact?.locationId || "",

        firstName: data.firstName || "",
        middleName: data.middleName || "",
        lastName: data.lastName || "",
        socialIdentificationNumber: data.socialIdentificationNumber || "",
        emails:
          emailsData?.map((i) => ({ emailAddress: i.emailAddress })) || [],
        phoneNumbers:
          phoneData
            ?.filter((i) => i.number !== "")
            .map((i) => ({
              number: i.number,
              phoneNumberTypeEnumId: i.type.value,
            })) || [],
        addresses: addressesData?.map((i) => ({
          line1: i.line1 || "",
          city: i.city || "",
          region: i.region || "",
          postalCode: i.postalCode || "",
        })),
        additionalInfo: data?.additionalInfo || "",
      });
    }
  };
  useEffect(() => {
    setEmailsData(
      !!contact?.emails?.length
        ? contact?.emails?.map((i) => ({
            id: i.id || uuidv4(),
            emailAddress: i.emailAddress || "",
          }))
        : [
            {
              emailAddress: "",
              id: uuidv4(),
            },
          ],
    );
  }, [contact]);

  useEffect(() => {
    setPhoneData(
      !!contact?.phoneNumbers?.length
        ? contact?.phoneNumbers?.map((i) => ({
            type: {
              value:
                i.phoneNumberTypeEnumId ||
                "bcd697a8-ef4c-4f91-bb8f-891df1ddfba0",
              label: i.phoneNumberTypeEnum.phoneNumberType || "Mobile",
            },
            number: i.number || "",
            id: i.id || uuidv4(),
          }))
        : [
            {
              type: {
                value: "bcd697a8-ef4c-4f91-bb8f-891df1ddfba0",
                label: "Mobile",
              },
              number: "",
              id: uuidv4(),
            },
          ],
    );
  }, [contact]);
  useEffect(() => {
    setAddressesData(
      !!contact?.addresses?.length
        ? contact?.addresses?.map((i) => ({
            id: i.id || uuidv4(),
            line1: i.line1 || "",
            region: i.region || "",
            city: i.city || "",
            postalCode: i.postalCode || "",
          }))
        : [
            {
              line1: "",
              region: "",
              city: "",
              postalCode: "",
              id: uuidv4(),
            },
          ],
    );
  }, [contact]);

  useEffect(() => {
    if (isSuccess) {
      setIsEdit(false);
    }
  }, [isSuccess]);

  return (
    <Card extraClasses={"shadow-box"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"px-6 py-4"}>
          <div className={"mb-4 flex justify-between"}>
            <div className={"text-lg font-bold"}>Contact Info</div>
            <div className={"flex gap-2"}>
              <Button
                label={"Cancel"}
                buttonType={"button"}
                size={"custom"}
                colorType={"outline"}
                extraClasses={"btn-sm"}
                onClick={() => setIsEdit(false)}
              />
              <Button
                isLoading={isLoadingEdit}
                label={"Save"}
                disabled={isLoadingEdit}
                buttonType={"submit"}
                size={"custom"}
                extraClasses={"btn-sm"}
              />
            </div>
          </div>
          <div className={"grid-rows-[repeat(7, max-content)] grid gap-6"}>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>FIRST NAME</div>
                <input
                  placeholder={"First name"}
                  {...register("firstName", {
                    ...contactNameRule.valueLength,
                    required: "This field is required",
                    maxLength: {
                      value: 25,
                      message:
                        "First name shouldn't be greater than 25 characters",
                    },
                  })}
                  type={"text"}
                  className={"input input-bordered input-sm w-full"}
                />
                {errors?.firstName && (
                  <div className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.firstName?.message}
                  </div>
                )}
              </div>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  MIDDLE NAME
                </div>
                <input
                  placeholder={"Middle name"}
                  {...register("middleName", {
                    ...contactNameRule.valueLength,
                    maxLength: {
                      value: 25,
                      message:
                        "Middle name shouldn't be greater than 25 characters",
                    },
                  })}
                  type={"text"}
                  className={"input input-bordered input-sm w-full"}
                />
                {errors?.middleName && (
                  <div className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.middleName?.message}
                  </div>
                )}
              </div>
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>LAST NAME</div>
                <input
                  placeholder={"Last name"}
                  {...register("lastName", {
                    ...contactNameRule.valueLength,
                    required: "This field is required",
                    maxLength: {
                      value: 25,
                      message:
                        "Last name shouldn't be greater than 25 characters",
                    },
                  })}
                  type={"text"}
                  className={"input input-bordered input-sm w-full"}
                />
                {errors?.lastName && (
                  <div className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.lastName?.message}
                  </div>
                )}
              </div>
              <div />
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>SIN NUMBER</div>
                <input
                  placeholder={"Tax ID"}
                  {...register("socialIdentificationNumber", {
                    maxLength: {
                      value: 20,
                      message: "TAX ID shouldn't be more than 20 characters.",
                    },
                  })}
                  type={"text"}
                  className={"input input-bordered input-sm w-full"}
                />
                {errors.socialIdentificationNumber && (
                  <span className="pt-2 text-sm font-bold text-error">
                    {errors.socialIdentificationNumber.message}
                  </span>
                )}
              </div>
              <div />
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>EMAIL</div>
                <div className={"grid grid-cols-[30px_300px] items-center"}>
                  <div className={"mt-2 flex h-full items-start"}>
                    <div
                      className={
                        "flex h-[20px] w-[20px] items-center justify-center overflow-hidden rounded-[50%] pb-0.5"
                      }>
                      <Button
                        label={"+"}
                        buttonType={"button"}
                        onClick={onClickAddEmail}
                      />
                    </div>
                  </div>
                  <div className={"items-center"}>{emailsItems}</div>
                </div>
              </div>
              <div />
            </div>
            <div className={"flex flex-col gap-4"}>
              <div>
                <div className={"mb-2 w-full text-base text-gray-400"}>
                  PHONE NUMBER
                </div>
                <div className={"grid grid-cols-[30px_300px] items-center"}>
                  <div className={"mt-2 flex h-full items-start"}>
                    <div
                      className={
                        "flex h-[20px] w-[20px] items-center justify-center overflow-hidden rounded-[50%] pb-0.5"
                      }>
                      <Button
                        label={"+"}
                        buttonType={"button"}
                        onClick={onClickAddPhone}
                      />
                    </div>
                  </div>
                  <div className={"items-center"}>{phoneItems}</div>
                </div>
              </div>
              <div />
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  CONTACT TYPE
                </div>
                <Controller
                  control={control}
                  name={"contactType"}
                  render={({ field: { value, onChange } }) => (
                    <CustomSelect
                      value={value}
                      options={contactTypes || []}
                      isLoading={isLoading}
                      onChange={(newValue) => {
                        const contactType = newValue as SelectOptionType;
                        onChange(contactType);
                      }}
                      small
                    />
                  )}
                />
              </div>
              <div />
            </div>
            <div className={"grid grid-rows-[repeat(4,max-content)] gap-4"}>
              <div className={"mb-2 text-base text-gray-400"}>ADDRESS</div>
              <div className={"grid grid-cols-[30px_300px] items-center"}>
                <div className={"mt-2 flex h-full items-start"}>
                  <div
                    className={
                      "flex h-[20px] w-[20px] items-center justify-center overflow-hidden rounded-[50%] pb-0.5"
                    }>
                    <Button
                      label={"+"}
                      buttonType={"button"}
                      onClick={onClickAddAddress}
                    />
                  </div>
                </div>
                <div className={"items-center"}>{addressesItems}</div>
              </div>
            </div>
            <div>
              <div className={"mb-2 text-base text-gray-400"}>
                ADDITIONAL INFO
              </div>
              <textarea
                {...register("additionalInfo")}
                className={"input input-bordered min-h-[100px] w-full"}
              />
            </div>
          </div>
        </div>
      </form>
    </Card>
  );
};
