import { ChevronDown, ChevronUp } from "baseui/icon";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import {
  getIndividualContacts,
  getOrganizationContacts,
  getUsers,
} from "../../../api/api";
import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import Button from "../../../components/button/Button";
import EditDots from "../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import CustomSelect from "../../../components/select/Select";
import {
  getInitials,
  getInitialsFromFullName,
} from "../../../constants/constants";
import { updateInvoiceStatusFactory } from "../../../factories/billing/factory-update-invoce-status";
import {
  TimePeriod,
  useDetectTimePeriod,
} from "../../../hooks/useDetectTimePeriod";
import {
  BillingStatusType,
  Invoice,
  SortEnum,
  useDeleteInvoiceMutation,
  useGetInvoicesQuery,
  useGetInvoiceStatsQuery,
  useGetInvoiceStatusesQuery,
  useUpdateInvoiceMutation,
} from "../../../services/BillingService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { FormSection } from "../../../support/FormSection";
import { IUser, SelectOptionType } from "../../../types/types";
import DeletePopup from "../DeletePopup";
import DropStatus from "../DropStatus";
import PayInvoiceModal from "../payments/PayInvoiceModal";
import { Period, SelectDate } from "../SelectDate";
import { navigateToContact } from "../../contacts/utils/navigateToContacts";
import { ExcelExport } from "../../../utils/ExportData";
import { useGetSearchCustomersQuery } from "../../../slices/ContactsSlice";
import { useDebounce } from "usehooks-ts";
import { useAppSelector } from "../../../redux/redux";
import search from "../../../components/select/Search";

export const InvoicesListView: React.FC<unknown> = () => {
  const { user } = useAppSelector((state) => state.appReducer);

  const navigate = useNavigate();
  const [searchClients, setSearchClients] = useState("");

  const { data: stats, isLoading: isLoadingStats } = useGetInvoiceStatsQuery(
    getLinkedFirm()?.orgId,
  );

  const debounceSearchClients = useDebounce(searchClients, 300);

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetSearchCustomersQuery({
      searchQuery: debounceSearchClients || "ab",
      orgId: getLinkedFirm()?.orgId || "",
      userId: user.id,
    });

  const { data: statuses, isLoading: isLoadingStatuses } =
    useGetInvoiceStatusesQuery(getLinkedFirm()?.orgId || "");

  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [payInvoice, setPayInvoice] = useState<Invoice | null>(null);
  const [invoiceDelete, setInvoiceDelete] = useState<Invoice | null>(null);
  const [filter, setFilter] = useState<Period>({
    period: TimePeriod.LastYear,
    from: null,
    to: null,
  });
  const { from, to } = useDetectTimePeriod(
    filter.period
      ? {
          period: filter.period,
          from: filter.from,
          to: filter.to,
        }
      : null,
  );

  const [filterByStatus, setFilterByStatus] = useState<SelectOptionType[]>([]);
  const [sort, setSort] = useState<{ name: SortEnum; order: boolean }>({
    name: SortEnum.InvoiceDate,
    order: false,
  });

  const [users, setUsers] = useState<SelectOptionType[]>([]);
  const [filterByUser, setFilterByUser] = useState<SelectOptionType[]>([]);
  const [activeInvoiceId, setActiveInvoiceId] = useState("");
  const [filterByContact, setFilterByContact] = useState<SelectOptionType[]>(
    [],
  );
  const [filterByLabel, setFilterByLabel] = useState<SelectOptionType[]>([]);

  const [updateInvoice, { isLoading: isLoadingUpdate }] =
    useUpdateInvoiceMutation();

  const labels: SelectOptionType[] =
    statuses?.map((item) => ({
      label: item?.name,
      value: item?.id,
      color: item?.color,
    })) || [];

  const updateStatus = (statusId: string, invoice: Invoice) => {
    const body = updateInvoiceStatusFactory.createModelFromDto({
      invoice,
      status: statusId || null,
    });

    updateInvoice(body);
  };

  const clientOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact?.customerId,
          label: `${contact.customerName}`,
          avatar: getInitialsFromFullName(contact?.customerName),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };

  const {
    data: invoices,
    isLoading,
    isFetching,
  } = useGetInvoicesQuery({
    orgId: getLinkedFirm()?.orgId,
    filterByDate: ["Custom"],
    fromDate: from ? from.toISOString() : null,
    toDate: to ? to.toISOString() : null,
    sortBy: sort.name,
    sortOrder: sort.order ? "asc" : "desc",
    filterByStatus: filterByStatus?.map((item) => item?.value),
    filterByClient: filterByContact?.map((item) => item?.value),
    filterByAssignee: filterByUser?.map((item) => item?.value),
    filterByLabel: filterByLabel?.map((item) => item?.value),
  });

  const isShowClear = useMemo(() => {
    return (
      !!filterByStatus?.length ||
      !!filterByUser?.length ||
      !!filterByContact?.length ||
      !!filterByLabel?.length ||
      filter.period !== TimePeriod.LastYear
    );
  }, [filter, filterByStatus, filterByUser, filterByContact]);

  useEffect(() => {
    if (getLinkedFirm()?.orgId) {
      setIsLoadingUsers(true);
      //setIsLoadingContacts(true);

      getUsers(`${getLinkedFirm()?.orgId}`).then((res: IUser[]) => {
        setUsers(
          res?.map((item) => ({
            avatar: `${item?.userProfile?.firstName?.[0]}${item?.userProfile?.lastName?.[0]}`,
            label: `${item?.userProfile?.firstName} ${item?.userProfile?.lastName}`,
            value: item?.id,
          })) || [],
        );
        setIsLoadingUsers(false);
      });
    }
  }, [getLinkedFirm()?.orgId]);

  const [deleteInvoice] = useDeleteInvoiceMutation();

  const handleSort = (name: SortEnum) => {
    if (name === sort.name) {
      setSort({ name, order: !sort.order });
    } else {
      setSort({ name, order: true });
    }
  };

  if (isLoading) {
    return <LottieLoading />;
  }

  return (
    <>
      <div className={"flex justify-start gap-6"}>
        <div
          className={
            "flex w-[300px] flex-col items-center justify-center gap-1 rounded-[8px] bg-white px-2 py-8 shadow-md shadow-gray-400"
          }>
          <div className={"text-lg text-gray-500"}>YTD Revenue</div>
          <div className={"text-2xl font-bold"}>
            $
            {(stats?.totalInvoices || 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
        <div
          className={
            "flex w-[300px] flex-col items-center justify-center gap-1 rounded-[8px] bg-white px-2 py-8 shadow-md shadow-gray-400"
          }>
          <div className={"text-lg text-gray-500"}>Total outstanding:</div>
          <div className={"text-2xl font-bold text-gray-800"}>
            $
            {(stats?.totalOutStandings || 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
        <div
          className={
            "flex w-[300px] flex-col items-center justify-center gap-1 rounded-[8px] bg-white px-2 py-8 shadow-md shadow-gray-400"
          }>
          <div className={"text-lg text-gray-500"}>Total past due:</div>
          <div className={"text-2xl font-bold text-gray-800"}>
            $
            {(stats?.totalPastDue || 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
      </div>
      <FormSection
        name={""}
        showHeading={false}
        extraCx={"pb-0 px-0 overflow-visible"}>
        <div className={"mb-4 flex items-end justify-between px-6"}>
          <div className={"flex gap-4 "}>
            <div>
              <div
                className={
                  "mb-1 text-sm font-semibold normal-case text-gray-800"
                }>
                Status
              </div>
              <div className={"min-w-[200px]"}>
                <CustomSelect
                  placeholder={"Search status"}
                  value={filterByStatus}
                  isMulti
                  options={[
                    { value: "NotDueYet", label: "Not due yet" },
                    { value: "OverDue", label: "Overdue" },
                    { value: "PartiallyPaid", label: "Partially Paid" },
                    { value: "Paid", label: "Paid" },
                  ]}
                  onChange={(newValue) =>
                    setFilterByStatus(newValue as SelectOptionType[])
                  }
                />
              </div>
            </div>
            <div>
              <div
                className={
                  "mb-1 text-sm font-semibold normal-case text-gray-800"
                }>
                Assignee
              </div>
              <div className={"min-w-[200px]"}>
                <CustomSelect
                  placeholder={"Search user"}
                  value={filterByUser}
                  isMulti
                  options={users}
                  onChange={(newValue) =>
                    setFilterByUser(newValue as SelectOptionType[])
                  }
                />
              </div>
            </div>
            <div>
              <div
                className={
                  "mb-1 text-sm font-semibold normal-case text-gray-800"
                }>
                Client
              </div>
              <div className={"min-w-[200px]"}>
                <CustomSelect
                  placeholder={"Search client"}
                  value={filterByContact}
                  isMulti
                  inputValue={searchClients}
                  options={clientOptions()}
                  onChangeInput={(inputValue) => setSearchClients(inputValue)}
                  onChange={(newValue) => {
                    setFilterByContact(newValue as SelectOptionType[]);
                  }}
                />
              </div>
            </div>
            <div>
              <div className={"flex flex-col"}>
                <div
                  className={
                    "mb-1 text-sm font-semibold normal-case text-gray-800"
                  }>
                  Invoice Date
                </div>
                <div className={"min-w-[200px]"}>
                  <SelectDate
                    setValue={(value) => setFilter(value)}
                    value={filter}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className={"flex flex-col"}>
                <div
                  className={
                    "mb-1 text-sm font-semibold normal-case text-gray-800"
                  }>
                  Label
                </div>
                <div className={"min-w-[200px]"}>
                  <CustomSelect
                    placeholder={"Search label"}
                    value={filterByLabel}
                    isMulti
                    options={labels}
                    onChange={(newValue) =>
                      setFilterByLabel(newValue as SelectOptionType[])
                    }
                  />
                </div>
              </div>
            </div>
            {isShowClear && (
              <div>
                <div className={"h-6"} />
                <Button
                  buttonType={"button"}
                  colorType={"ghost"}
                  onClick={() => {
                    setFilter({
                      period: TimePeriod.LastYear,
                      from: null,
                      to: null,
                    });
                    setFilterByUser([]);
                    setFilterByContact([]);
                    setFilterByStatus([]);
                    setFilterByLabel([]);
                  }}
                  label={"Clear all"}
                  extraClasses={"normal-case whitespace-nowrap"}
                />
              </div>
            )}
          </div>
          <div className={"flex gap-2"}>
            <div className={"flex items-center"}>
              <ExcelExport
                data={
                  invoices?.map((invoice) => ({
                    Contact: invoice?.customerName,
                    "Invoice No.": invoice?.invoiceNumber,
                    "Invoice Date": moment(invoice?.invoiceDate).format(
                      "MM/DD/YYYY",
                    ),
                    "Due Date": moment(invoice?.dueDate).format("MM/DD/YYYY"),
                    Total: invoice?.total || 0,
                    Balance: invoice?.balance || 0,
                    Status: invoice?.status,
                    Assignee:
                      invoice?.userId &&
                      `${invoice?.userFirstName} ${invoice?.userLastName}`,
                    Label: invoice?.label || "",
                  })) || []
                }
                fileName={"Invoices"}
              />
            </div>
            <Button
              buttonType={"button"}
              onClick={() => navigate("./add")}
              label={"New invoice"}
              extraClasses={"normal-case"}
            />
          </div>
        </div>
        <table className={"w-full"}>
          <thead className={"border-b-[1px] border-gray-300"}>
            <tr>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                <div
                  className={
                    "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                  }
                  onClick={() => handleSort(SortEnum.InvoiceNo)}>
                  <div>Invoice No.</div>
                  {sort?.name === SortEnum.InvoiceNo && (
                    <div className={"relative left-0 top-[-4px]"}>
                      {sort?.order ? (
                        <div className={"absolute"}>
                          <ChevronUp size={26} />
                        </div>
                      ) : (
                        <div className={"absolute"}>
                          <ChevronDown size={26} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </th>
              <th className={"py-4 pl-6 pr-8 text-gray-500 last:pr-4"}>
                <div
                  className={
                    "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                  }
                  onClick={() => handleSort(SortEnum.Customer)}>
                  <div>Contact</div>
                  {sort?.name === SortEnum.Customer && (
                    <div className={"relative left-0 top-[-4px]"}>
                      {sort?.order ? (
                        <div className={"absolute"}>
                          <ChevronUp size={26} />
                        </div>
                      ) : (
                        <div className={"absolute"}>
                          <ChevronDown size={26} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                <div
                  className={
                    "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                  }
                  onClick={() => handleSort(SortEnum.InvoiceDate)}>
                  <div>Invoice date</div>
                  {sort?.name === SortEnum.InvoiceDate && (
                    <div className={"relative left-0 top-[-4px]"}>
                      {sort?.order ? (
                        <div className={"absolute"}>
                          <ChevronUp size={26} />
                        </div>
                      ) : (
                        <div className={"absolute"}>
                          <ChevronDown size={26} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                <div
                  className={
                    "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                  }
                  onClick={() => handleSort(SortEnum.DueDate)}>
                  <div>Due date</div>
                  {sort?.name === SortEnum.DueDate && (
                    <div className={"relative left-0 top-[-4px]"}>
                      {sort?.order ? (
                        <div className={"absolute"}>
                          <ChevronUp size={26} />
                        </div>
                      ) : (
                        <div className={"absolute"}>
                          <ChevronDown size={26} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                <div
                  className={
                    "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                  }
                  onClick={() => handleSort(SortEnum.Total)}>
                  <div>Total</div>
                  {sort?.name === SortEnum.Total && (
                    <div className={"relative left-0 top-[-4px]"}>
                      {sort?.order ? (
                        <div className={"absolute"}>
                          <ChevronUp size={26} />
                        </div>
                      ) : (
                        <div className={"absolute"}>
                          <ChevronDown size={26} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                <div
                  className={
                    "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                  }
                  onClick={() => handleSort(SortEnum.Balance)}>
                  <div>Balance</div>
                  {sort?.name === SortEnum.Balance && (
                    <div className={"relative left-0 top-[-4px]"}>
                      {sort?.order ? (
                        <div className={"absolute"}>
                          <ChevronUp size={26} />
                        </div>
                      ) : (
                        <div className={"absolute"}>
                          <ChevronDown size={26} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                <div className={"flex whitespace-nowrap text-sm normal-case"}>
                  <div>Status</div>
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                <div
                  className={
                    "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                  }
                  onClick={() => handleSort(SortEnum.AssignedUser)}>
                  <div>Assignee</div>
                  {sort?.name === SortEnum.AssignedUser && (
                    <div className={"relative left-0 top-[-4px]"}>
                      {sort?.order ? (
                        <div className={"absolute"}>
                          <ChevronUp size={26} />
                        </div>
                      ) : (
                        <div className={"absolute"}>
                          <ChevronDown size={26} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                <div className={"flex whitespace-nowrap text-sm normal-case"}>
                  <div>Label</div>
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}></th>
            </tr>
          </thead>
          <tbody className={"text-sm"}>
            {invoices?.map((invoice) => (
              <tr
                key={invoice?.invoiceId}
                onClick={() => navigate("./" + invoice.invoiceId)}
                className={
                  "cursor-pointer border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
                }>
                <td
                  className={
                    "link-primary bg-transparent px-4 py-2 text-sm font-semibold text-gray-800 hover:underline"
                  }>
                  {invoice?.invoiceNumber}
                </td>
                <td className={"bg-transparent px-[24px] py-[8px]"}>
                  <div className={"flex items-center"}>
                    <div className={"flex items-center text-[14px]"}>
                      <AvatarPlaceholder
                        size={"extra-small"}
                        type={
                          invoice?.customerType === "Business"
                            ? "purple"
                            : "blue"
                        }
                        label={getInitialsFromFullName(
                          invoice?.customerName || "",
                        )}
                      />
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            navigateToContact({
                              type:
                                invoice?.customerType === "Business"
                                  ? "organization"
                                  : "contact",
                              customerId: invoice?.customerId || "",
                              id: invoice?.businessContactId
                                ? invoice?.businessContactId
                                : invoice?.contactId || "",
                            }),
                          );
                        }}
                        className={
                          "link-primary w-[100px] cursor-pointer truncate pl-2 text-sm font-semibold hover:underline"
                        }>
                        {invoice?.customerName}
                        {invoice?.noOfCustomers > 0
                          ? `(+${invoice?.noOfCustomers})`
                          : ""}
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  className={
                    "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
                  }>
                  {moment(invoice?.invoiceDate).format("MM/DD/YYYY")}
                </td>
                <td
                  className={`bg-transparent px-4 py-2 text-sm font-semibold ${
                    invoice?.status === "OverDue"
                      ? "text-red-500"
                      : "text-gray-800"
                  }`}>
                  {moment(invoice?.dueDate).format("MM/DD/YYYY")}
                </td>
                <td
                  className={
                    "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
                  }>
                  $
                  {(invoice?.total || 0).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td
                  className={
                    "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
                  }>
                  $
                  {(invoice?.balance || 0).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td
                  className={
                    "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
                  }>
                  <div className={"flex items-center gap-2"}>
                    {invoice?.status === "Paid" && (
                      <FaCheckCircle className={"h-4 w-4 text-green-500"} />
                    )}
                    {invoice?.status}
                  </div>
                </td>
                <td className={"relative bg-transparent px-4 py-2"}>
                  {invoice?.userId && (
                    <div className={"flex items-center text-sm"}>
                      <AvatarPlaceholder
                        size={"extra-small"}
                        type={"custom"}
                        avatarCustomColor={invoice?.userColor}
                        label={getInitials(
                          invoice?.userFirstName || "",
                          invoice?.userLastName || "",
                        )}
                      />
                      <div
                        className={
                          "whitespace-nowrap pl-2 text-sm font-semibold normal-case text-gray-800"
                        }>
                        {invoice?.userId &&
                          `${invoice?.userFirstName} ${invoice?.userLastName}`}
                      </div>
                    </div>
                  )}
                </td>
                <td className={"bg-transparent px-4 py-2"}>
                  <DropStatus
                    invoiceStatus={{
                      id: invoice?.labelId,
                      name: invoice?.label,
                      color: invoice?.labelColor,
                    }}
                    paymentStatus={null}
                    statuses={statuses || []}
                    isLoading={
                      (isLoadingUpdate || isFetching) &&
                      invoice?.invoiceId === activeInvoiceId
                    }
                    selectStatus={(status) => {
                      setActiveInvoiceId(invoice?.invoiceId);
                      updateStatus(status?.id, {
                        ...invoice,
                        id: invoice?.invoiceId,
                      });
                    }}
                  />
                </td>
                <td className={"bg-transparent px-4 py-2"}>
                  <EditDots extraPosition={"top-[calc(100%+4px)] right-0"}>
                    <div className={""}>
                      <div
                        className={
                          "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          setInvoiceDelete({
                            ...invoice,
                            id: invoice.invoiceId,
                          });
                        }}>
                        Delete
                      </div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setPayInvoice({
                            ...invoice,
                            id: invoice.invoiceId,
                          });
                        }}
                        className={
                          "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                        }>
                        Pay
                      </div>
                    </div>
                  </EditDots>
                </td>
              </tr>
            ))}
            <tr className={"sticky bottom-0 left-0 bg-gray-100"}>
              <td className={"px-4 py-2"}></td>
              <td className={"px-4 py-2"}></td>
              <td className={"px-4 py-2"}></td>
              <td
                className={
                  "px-4 py-2 text-end text-sm font-bold text-gray-800"
                }>
                Total:
              </td>
              <td className={"px-4 py-2 text-sm font-bold text-gray-800"}>
                $
                {(invoices?.[0]?.sumOfTotal || 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td className={"px-4 py-2 text-sm font-bold text-gray-800"}>
                $
                {(invoices?.[0]?.sumOfBalance || 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td className={"px-4 py-2"}></td>
              <td className={"px-4 py-2"}></td>
              <td className={"px-4 py-2"}></td>
              <td className={"px-4 py-2"}></td>
            </tr>
          </tbody>
        </table>
      </FormSection>
      {payInvoice && (
        <PayInvoiceModal
          close={() => setPayInvoice(null)}
          invoice={payInvoice}
        />
      )}
      {invoiceDelete && (
        <DeletePopup
          onClose={() => setInvoiceDelete(null)}
          isActive={!!invoiceDelete}
          onDelete={() => {
            deleteInvoice(invoiceDelete);
            setInvoiceDelete(null);
          }}
        />
      )}
    </>
  );
};
