import moment from "moment/moment";
import React, { FC, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { HiPlus } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import { LottieLoading } from "../../../../../../../../components/graphics/LottieLoading";
import NoItems from "../../../../../../../../components/NoItems";
import ContactNoteSvg from "../../../../../../../../components/svg/ContactNoteSvg";
import { updateInvoiceStatusFactory } from "../../../../../../../../factories/billing/factory-update-invoce-status";
import {
  Invoice,
  SortEnum,
  useGetInvoicesQuery,
  useGetInvoiceStatusesQuery,
  useUpdateInvoiceMutation,
} from "../../../../../../../../services/BillingService";
import { getLinkedFirm } from "../../../../../../../../sessionStorage/sessionStorage";
import DropStatus from "../../../../../../../billing/DropStatus";
import ErrorPopup from "../../../../../../modals/ErrorPopup";

type Props = {
  customerId: string;
};

export const OrganizationInvoicesWidget: FC<Props> = ({ customerId }) => {
  const navigate = useNavigate();

  const { data: contactInvoices, isFetching } = useGetInvoicesQuery({
    orgId: getLinkedFirm()?.orgId,
    filterByDate: [],
    sortBy: SortEnum.InvoiceDate,
    sortOrder: "asc",
    filterByStatus: [],
    filterByClient: [customerId || ""],
    filterByAssignee: [],
    filterByLabel: [],
  });

  const { data: statuses, isLoading: isLoadingStatuses } =
    useGetInvoiceStatusesQuery(getLinkedFirm()?.orgId || "");

  const [updateInvoice, { isLoading: isLoadingUpdate }] =
    useUpdateInvoiceMutation();

  const [activeInvoiceId, setActiveInvoiceId] = useState("");

  const updateStatus = (statusId: string, invoice: Invoice) => {
    const body = updateInvoiceStatusFactory.createModelFromDto({
      invoice,
      status: statusId || null,
    });

    updateInvoice(body);
  };

  const [isOpenError, setIsOpenError] = useState(false);

  const isActive = true;

  return (
    <>
      <Card extraClasses={"shadow-box rounded-[6px]"}>
        <div className={"max-w-full pt-4"}>
          <div className={"mb-4 flex w-full justify-between px-6"}>
            <div className={"text-lg font-bold"}>Invoices</div>
            <Button
              label={""}
              icon={<HiPlus />}
              size={"custom"}
              colorType={"outline"}
              extraClasses={"btn-sm px-2"}
              onClick={() =>
                !isActive
                  ? setIsOpenError(true)
                  : navigate(`/billing/invoices/add?customerId=${customerId}`)
              }
            />
          </div>
          <div>
            {isFetching ? (
              <div className={"p-4"}>
                <LottieLoading />
              </div>
            ) : !contactInvoices?.length ? (
              <div className={"p-6 pt-0"}>
                <NoItems
                  icon={<ContactNoteSvg />}
                  message={"There are no invoices"}
                />
              </div>
            ) : (
              <div className={"max-h-[300px] overflow-x-auto overflow-y-auto"}>
                <table className={"w-full"}>
                  <thead>
                    <tr className={"border-b-[1px] border-gray-300"}>
                      <th
                        className={
                          "px-4 py-2 text-start text-xs font-bold text-gray-800 2xl:px-3"
                        }>
                        Invoice date
                      </th>
                      <th
                        className={
                          "px-4 py-2 text-start text-xs font-bold text-gray-800 2xl:px-3"
                        }>
                        Total
                      </th>
                      <th
                        className={
                          "px-4 py-2 text-start text-xs font-bold text-gray-800 2xl:px-3"
                        }>
                        Balance
                      </th>
                      <th
                        className={
                          "px-4 py-2 text-start text-xs font-bold text-gray-800 2xl:px-3"
                        }>
                        Status
                      </th>
                      <th
                        className={
                          "px-4 py-2 text-start text-xs font-bold text-gray-800 2xl:px-3"
                        }>
                        Label
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contactInvoices?.map((invoice) => (
                      <tr
                        key={invoice?.invoiceId}
                        onClick={() =>
                          navigate("/billing/invoices/" + invoice.invoiceId)
                        }
                        className={
                          "cursor-pointer border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
                        }>
                        <td
                          className={
                            "px-4 py-2 text-xs text-gray-800 2xl:px-3"
                          }>
                          {moment(invoice?.invoiceDate).format("MM/DD/YYYY")}
                        </td>
                        <td
                          className={
                            "px-4 py-2 text-xs text-gray-800 2xl:px-3"
                          }>
                          $
                          {(invoice?.total || 0).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td
                          className={
                            "px-4 py-2 text-xs text-gray-800 2xl:px-3"
                          }>
                          $
                          {(invoice?.balance || 0).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td
                          className={
                            "px-4 py-2 text-xs text-gray-800 2xl:px-3"
                          }>
                          <div className={"flex items-center gap-2"}>
                            {invoice?.status === "Paid" && (
                              <FaCheckCircle
                                className={"h-4 w-4 text-green-500"}
                              />
                            )}
                            {invoice?.status}
                          </div>
                        </td>
                        <td
                          className={
                            "px-4 py-2 text-xs text-gray-800 2xl:px-3"
                          }>
                          <DropStatus
                            isSmall
                            invoiceStatus={{
                              id: invoice?.labelId,
                              name: invoice?.label,
                              color: invoice?.labelColor,
                            }}
                            paymentStatus={null}
                            statuses={statuses || []}
                            isLoading={
                              (isLoadingUpdate || isFetching) &&
                              invoice?.id === activeInvoiceId
                            }
                            selectStatus={(status) => {
                              setActiveInvoiceId(invoice?.invoiceId);
                              updateStatus(status?.id, {
                                ...invoice,
                                id: invoice?.invoiceId,
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </Card>
      <ErrorPopup
        closePopup={() => setIsOpenError(false)}
        isOpen={isOpenError}
      />
    </>
  );
};
