import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import { toast } from "react-toastify";

import Button from "../../../components/button/Button";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  BillingStatusType,
  useAddPaymentStatusMutation,
  useDeletePaymentStatusMutation,
  useGetPaymentStatusesQuery,
  useReorderPaymentStatusMutation,
  useUpdatePaymentStatusMutation,
} from "../../../services/BillingService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import BillingStatus from "./BillingStatus";

type Reorder<T> = (list: T[], startIndex: number, endIndex: number) => T[];
const reorder: Reorder<BillingStatusType> = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const PaymentsStatuses = () => {
  const { data: statuses, isLoading } = useGetPaymentStatusesQuery(
    getLinkedFirm()?.orgId || "",
  );

  const [data, setData] = useState<BillingStatusType[]>(statuses || []);

  useEffect(() => {
    setData(statuses || []);
  }, [statuses]);

  const [
    addStatus,
    { isLoading: isLoadingAdd, isSuccess: isSuccessAdd, isError: isErrorAdd },
  ] = useAddPaymentStatusMutation();
  const [
    updateStatus,
    {
      isLoading: isLoadingUpdate,
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
    },
  ] = useUpdatePaymentStatusMutation();

  const [reorderStatuses] = useReorderPaymentStatusMutation();

  const [
    deleteStatus,
    {
      isLoading: isLoadingDelete,
      isSuccess: isSuccessDelete,
      isError: isErrorDelete,
    },
  ] = useDeletePaymentStatusMutation();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      statuses || [],
      result.source.index,
      result.destination.index,
    );

    setData(newItems);
    reorderStatuses(newItems?.map((item) => item?.id));
  };

  const handleUpdate = (status: BillingStatusType) => {
    updateStatus(
      statuses?.map((item) => (item?.id === status?.id ? status : item)),
    );
  };

  const handleAddStatus = () => {
    addStatus({
      name: "",
      order: (statuses?.length || 0) + 1,
      color: "#444444",
      orgId: getLinkedFirm()?.orgId || "",
    });
  };

  const handleDelete = (status: BillingStatusType) => {
    deleteStatus(status?.id || "");
  };

  useEffect(() => {
    if (isSuccessAdd) {
      toast("Changes saved successfully", {
        type: "success",
        pauseOnHover: false,
      });
    }
  }, [isSuccessAdd]);

  useEffect(() => {
    if (isSuccessUpdate) {
      toast("Changes saved successfully", {
        type: "success",
        pauseOnHover: false,
      });
    }
  }, [isSuccessUpdate]);

  useEffect(() => {
    if (isSuccessDelete) {
      toast("Changes saved successfully", {
        type: "success",
        pauseOnHover: false,
      });
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    if (isErrorAdd || isErrorUpdate || isErrorDelete) {
      toast("Something went wrong", { type: "error", pauseOnHover: false });
    }
  }, [isErrorAdd, isErrorUpdate, isErrorDelete]);

  if (isLoading || isLoadingDelete || isLoadingAdd) {
    return <LottieLoading />;
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"droppable"}>
          {(provided: DroppableProvided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={"flex flex-col gap-8"}>
              {data?.map((status, index) => (
                <Draggable
                  key={status.id}
                  draggableId={status.id}
                  index={index}>
                  {(provided: DraggableProvided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={provided.draggableProps.style}>
                      <BillingStatus
                        status={status}
                        isUpdating={isLoadingUpdate}
                        setIsButtonDisabled={setIsButtonDisabled}
                        handleUpdate={handleUpdate}
                        handleDelete={handleDelete}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className={"mt-6"}>
        <Button
          label={"Add label"}
          onClick={handleAddStatus}
          extraClasses={"normal-case"}
        />
      </div>
    </div>
  );
};

export default PaymentsStatuses;
