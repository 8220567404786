import React, { FC, useEffect, useState } from "react";
import { HiMiniAdjustmentsHorizontal } from "react-icons/hi2";
import { useSearchParams } from "react-router-dom";

import Button from "../../../components/button/Button";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import {
  useArchiveOrUnarchiveMutation,
  useExportWorksQuery,
  useGetAllWorkStatsQuery,
  useGetWorksQuery,
  useUpdateMultipleStatusesMutation,
} from "../../../slices/WorkSlice";
import { SelectOptionType } from "../../../types/types";
import { AllWorksFilters } from "../components/AllWorksFilters";
import { WorkTableSort } from "../components/types/work-table";
import { WorkList } from "../components/WorkList";
import { WorksStats } from "../components/WorksStats";
import { AddWorkModal } from "../modals/AddWork.modal";
import { FilterWorksModal } from "../modals/FilterWorks.modal";
import { ExcelExport } from "../../../utils/ExportData";
import moment from "moment";
import { useAppSelector } from "../../../redux/redux";
import { WorkModel } from "../../../factories/works/models/work.model";
import { BsArchive } from "react-icons/bs";
import { getInitialsFromFullName } from "../../../constants/constants";
import classNames from "classnames";

export type Filters = {
  customerIds: SelectOptionType[];
  workName: SelectOptionType[];
  userIds: SelectOptionType[];
  workStatuses: SelectOptionType[];
  worktypeIds: SelectOptionType[];

  startDates: [] | ["BetweenDates"];
  dueDates: [] | ["BetweenDates"];
  fromDate: Date | null;
  toDate: Date | null;
};

export const AllWorks: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAppSelector((state) => state.appReducer);

  const page = +(searchParams.get("page") || 1);
  const sortBy = searchParams.get("sortBy") as WorkTableSort;
  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc";
  const userId = searchParams.get("userId");
  const username = searchParams.get("username");

  const [archiveWorks, setArchiveWorks] = useState<WorkModel[]>([]);
  const [isOpenStatuses, setIsOpenStatuses] = useState(false);

  const [activateDeactivate] = useArchiveOrUnarchiveMutation();

  const [filters, setFilters] = useState<Filters>({
    customerIds: [],
    workName: [],
    userIds: [],
    workStatuses: [],
    worktypeIds: [],

    startDates: [],
    dueDates: [],
    fromDate: null,
    toDate: null,
  });

  const {
    data: workStats,
    isLoading,
    isError,
  } = useGetAllWorkStatsQuery({ orgId: getLinkedFirm()?.orgId || "" });

  const [updateStatuses] = useUpdateMultipleStatusesMutation();

  const { data: works, isLoading: isLoadingWorks } = useGetWorksQuery({
    pageNumber: page,
    pageSize: 20,
    orgId: getLinkedFirm()?.orgId || "",
    sortBy: sortBy,
    sortOrder: sortOrder,

    customerIds: filters.customerIds.map((item) => item.value) || [],
    workName: filters.workName.map((item) => item.label) || [],
    userIds: filters.userIds.map((item) => item.value) || [],
    workStatuses: filters.workStatuses.map((i) => i.label) || [],
    worktypeIds: filters.worktypeIds.map((item) => item.value) || [],
    startDates: filters.startDates,
    dueDates: filters.dueDates,
    fromDate: filters.fromDate,
    toDate: filters.toDate,
    currentUserId: user.id,
  });

  const { data: allWorks } = useExportWorksQuery({
    pageNumber: page,
    pageSize: 20,
    orgId: getLinkedFirm()?.orgId || "",
    sortBy: sortBy,
    sortOrder: sortOrder,

    customerIds: filters.customerIds.map((item) => item.value) || [],
    workName: filters.workName.map((item) => item.label) || [],
    userIds: filters.userIds.map((item) => item.value) || [],
    workStatuses: filters.workStatuses.map((i) => i.label) || [],
    worktypeIds: filters.worktypeIds.map((item) => item.value) || [],
    startDates: filters.startDates,
    dueDates: filters.dueDates,
    fromDate: filters.fromDate,
    toDate: filters.toDate,
    currentUserId: user.id,
  });

  const [isAddWork, setIsAddWork] = useState(false);
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  useEffect(() => {
    setSearchParams({
      page: page?.toString() || "1",
      sortBy: sortBy?.toString() || WorkTableSort.StartDate,
      sortOrder: sortOrder?.toString() || "desc",
    });
  }, []);

  useEffect(() => {
    if (userId && username) {
      setFilters((prev) => ({
        ...prev,
        userIds: [
          {
            value: userId,
            label: username,
            avatar: getInitialsFromFullName(username),
          },
        ],
      }));
    }
  }, [userId, username]);

  const multipleStatuses = [
    {
      color: "#93AAFD",
      name: "Ready",
      statusId: "f63feb60-c2f8-49ed-b099-b7846ecd10b2",
    },
    {
      color: "#FFD80B",
      name: "In Progress",
      statusId: "efaa8fdd-1b4c-4dda-8395-395d15294750",
    },
    {
      color: "#F15252",
      name: "Waiting",
      statusId: "9dae62a0-3f78-4a69-a5f6-240aded90540",
    },
    {
      color: "#47DE96",
      name: "Completed",
      statusId: "7116afd6-80c6-4d00-99da-7b17d782440b",
    },
  ];

  if (isLoading) {
    return <LottieLoading />;
  }

  if (isError) {
    return null;
  }

  return (
    <>
      <div className={"grid h-full w-full grid-rows-[max-content_1fr] gap-6 "}>
        <WorksStats
          allWorks={workStats?.allWorksCount || 0}
          completedWorks={workStats?.completedWorkCount || 0}
        />
        <WorkList
          works={works || []}
          statusFilter={filters.workStatuses.map((i) => i.label)}
          isLoading={isLoadingWorks}
          hasPages
          setArchiveWorks={setArchiveWorks}
          archiveWorks={archiveWorks}>
          <div
            className={
              "grid grid-cols-[1fr_max-content] items-start gap-2 px-8 pt-6 xl:items-end"
            }>
            <AllWorksFilters setFilters={setFilters} filters={filters} />
            <div
              className={
                "grid grid-cols-[repeat(3,max-content)] gap-2 pt-6 xl:pt-0"
              }>
              <div className={"flex items-center gap-6"}>
                <div className={"relative"}>
                  <div
                    className={classNames(
                      !!archiveWorks.length ? "text-purple" : "text-gray-300",
                      "w-[120px] cursor-pointer text-base font-semibold",
                    )}
                    onClick={() => setIsOpenStatuses(!isOpenStatuses)}>
                    Change status
                  </div>
                  {isOpenStatuses && (
                    <div>
                      <div
                        className={
                          "absolute top-[30px] z-10 flex w-[150px] flex-col gap-1 rounded-[8px] border border-black border-opacity-[0.2] bg-white"
                        }>
                        {multipleStatuses.map((status) => (
                          <div
                            className={
                              "flex cursor-pointer items-center gap-2 px-4 py-2"
                            }
                            onClick={() => {
                              updateStatuses({
                                body: archiveWorks?.map((i) => i.workId),
                                status: status.name,
                              });
                              setIsOpenStatuses(false);
                              setArchiveWorks([]);
                            }}>
                            <div
                              style={{ backgroundColor: status.color }}
                              className={"h-2.5 w-2.5 rounded-[50%]"}>
                              {" "}
                            </div>
                            <div className={"text-sm font-semibold"}>
                              {status.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  aria-disabled={!!archiveWorks.length}
                  onClick={() => {
                    activateDeactivate({
                      body: archiveWorks.map((i) => i.workId),
                      archiveWork: true,
                    });
                    setArchiveWorks([]);
                  }}>
                  <BsArchive
                    data-tooltip-id="tooltip-archive"
                    color={"#7c66f0"}
                    size={"20px"}
                    className={
                      "flex cursor-pointer items-center focus:outline-0 active:outline-0"
                    }
                  />
                </div>
                <ExcelExport
                  data={
                    allWorks?.map((work) => ({
                      "Work Name": work.workName,
                      Contact: work.customerName,
                      Status: work.jobStatus,
                      "Start Date": moment(work.startDate).format("YYYY-MM-DD"),
                      "Due Date": moment(work.dueDate).format("YYYY-MM-DD"),
                      Progress:
                        (work.completedTaskCounts / work.taskCounts) * 100 || 0,
                      Repeats: work.repeats,
                      "Work Type": work.workType,
                      Assignee: work.userFirstName + " " + work.userLastName,
                    })) || []
                  }
                  fileName={"All Works"}
                />
              </div>

              <Button
                label={""}
                icon={
                  <HiMiniAdjustmentsHorizontal
                    size={24}
                    className={"text-purple"}
                  />
                }
                onClick={() => setIsOpenFilters(true)}
                colorType={"ghost"}
              />
              <Button
                buttonType={"button"}
                label={"Add work"}
                onClick={() => setIsAddWork(true)}
              />
            </div>
          </div>
        </WorkList>
      </div>
      {isAddWork && (
        <AddWorkModal isOpen={isAddWork} onClose={() => setIsAddWork(false)} />
      )}
      {isOpenFilters && (
        <FilterWorksModal
          isOpen={isOpenFilters}
          onClose={() => setIsOpenFilters(false)}
          setFilters={setFilters}
          filters={filters}
        />
      )}
    </>
  );
};
