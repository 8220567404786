import React, { FC, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import {
  useDeleteContactMutation,
  useGetArchivedCustomersQuery,
} from "../../../slices/ContactsSlice";
import { FormSection } from "../../../support/FormSection";
import { navigateToContact } from "../utils/navigateToContacts";
import TooltipDeactivatedMessage from "../components/TooltipDeactivatedMessage";
import { useAppSelector } from "../../../redux/redux";
import { CustomerModel } from "../../../factories/customers/models/customerModel";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteMessageContactPopup from "../refactor/modals/DeleteMessageContactPopup";
import { getInitialsFromFullName } from "../../../constants/constants";
import Search from "../../../components/select/Search";
import { SelectOptionType } from "../../../types/types";
import { useDebounce } from "usehooks-ts";
import { Pagination } from "baseui/pagination";

type Params = {
  page: string;
};
export const ArchivedContacts: FC = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.appReducer);

  const [searchParams, setSearchParams] = useSearchParams();

  const page = +(searchParams.get("page") || 1);

  const [customers, setCustomers] = useState<CustomerModel[]>([]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [search, setSearch] = useState("");

  const debounceSearch = useDebounce(search, 200);

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetArchivedCustomersQuery({
      orgId: getLinkedFirm()?.orgId || "",
      isActive: false,
      userId: user.id,
      tagIds: [],
      yearEndMonth: [],
      customerName: null,
      sortOrder: "asc",
      sortColumn: null,
      searchQuery: debounceSearch || null,
      pageSize: 20,
      pageNumber: page,
    });
  const [deleteContact] = useDeleteContactMutation();
  const contactsCount: number = contacts?.length || 0;
  const totalPages = Math.ceil((contacts?.[0]?.totalRecords || 0) / 20) || 1;

  if (isLoadingContacts) {
    return <LottieLoading />;
  }
  const navigateToContacts = (contact: SelectOptionType) => {
    navigate(
      navigateToContact({
        type: contact.avatarColor === "purple" ? "organization" : "contact",
        customerId: contact.value || "",
        id: contact.value || "",
      }),
    );
  };
  const getContacts = () => {
    const options: SelectOptionType[] = [];
    if (customers) {
      customers.forEach((customer) => {
        options.push({
          value: customer.businessContactId
            ? customer.businessContactId
            : customer?.id,
          label: customer.businessContactId
            ? customer.businessContact?.companyName || ""
            : `${customer?.name}`,
          avatar: getInitialsFromFullName(customer?.name),
          avatarColor: customer.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };
  const getParams = (nextPage: number): Params => {
    const params: Params = {
      page: (nextPage || 1).toString(),
    };
    return params;
  };

  return (
    <FormSection name={""} showHeading={false}>
      <div className={"flex justify-between"}>
        <div className={"flex flex-col"}>
          <div className={"text-lg font-bold"}>
            {contacts?.[0]?.totalRecords} Contacts
          </div>
          <div className={"rounded-[8px] border border-gray-300"}>
            <Search
              value={null}
              onChange={(item) => {
                const value = item as SelectOptionType;
                navigateToContacts(value);
              }}
              options={getContacts()}
              inputValue={search}
              onChangeInput={(value) => setSearch(value)}
              isLoading={isLoadingContacts}
              placeholder={isFocus ? "" : "Search contacts"}
              noOptionsMessage={"No results"}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
              type={"contacts"}
              isContactPage
              workTable
              removeOptions
            />
          </div>
        </div>
        <div
          className={"cursor-pointer"}
          onClick={() => {
            setIsOpenDelete(true);
          }}>
          <FontAwesomeIcon
            icon={faTrashCan}
            className={"text-purple w-[18px]"}
          />
        </div>
      </div>

      <table className={"w-full"}>
        <thead className={"border-b-[1px] border-gray-300"}>
          <tr>
            <th className={"p-4 text-start text-sm normal-case text-gray-400"}>
              <input
                className={
                  "checkbox border-gray-300 [--chkbg:--b1] [--chkfg:--a] checked:border-gray-300 checked:bg-base-100"
                }
                onChange={(e) =>
                  e.target.checked
                    ? setCustomers(contacts || [])
                    : setCustomers([])
                }
                checked={(contacts?.length || 0) === customers.length}
                type="checkbox"
              />
            </th>
            <th className={"p-4 text-start text-sm normal-case text-gray-400"}>
              Contact
            </th>
            <th className={"p-4 text-start text-sm normal-case text-gray-400"}>
              Phone Number
            </th>
            <th className={"p-4 text-start text-sm normal-case text-gray-400"}>
              Email Address
            </th>
            <th className={"p-4 text-start text-sm normal-case text-gray-400"}>
              Contact type
            </th>
            <th className={"p-4 text-start text-sm normal-case text-gray-400"}>
              Open work
            </th>
          </tr>
        </thead>
        <tbody>
          {contacts?.map((item) => (
            <tr
              key={item.customerId}
              className={"border-b-[1px] border-gray-300 hover:bg-gray-100"}>
              <td className={"w-[30px] pl-4"}>
                <input
                  className={
                    "checkbox border-gray-300 [--chkbg:--b1] [--chkfg:--a] checked:border-gray-300 checked:bg-base-100"
                  }
                  onChange={(e) =>
                    !e.target.checked
                      ? setCustomers((prev) =>
                          prev.filter((i) => i.customerId !== item.customerId),
                        )
                      : setCustomers((prev) => [...prev, item])
                  }
                  type="checkbox"
                  checked={customers.some(
                    (i) => i.customerId === item.customerId,
                  )}
                />
              </td>
              <td className={"max-w-[200px] px-4 py-2"}>
                <div className={"flex items-center gap-3 text-sm"}>
                  <AvatarPlaceholder
                    type={item?.businessContactId ? "purple" : "blue"}
                    size={"2xs"}
                    label={getInitialsFromFullName(item?.customerName) || ""}
                  />
                  <div
                    onClick={() =>
                      navigate(
                        navigateToContact({
                          type: item.businessContactId
                            ? "organization"
                            : "contact",
                          id: item.businessContactId || item.contactId || "",
                          customerId: item.customerId || "",
                        }),
                      )
                    }
                    className={
                      "link-primary max-w-[200px] cursor-pointer truncate text-sm font-semibold hover:underline"
                    }>
                    {item.customerName}
                  </div>
                  <TooltipDeactivatedMessage />
                </div>
              </td>
              <td className={"px-4 py-2"}>
                <div
                  className={"text-sm font-semibold normal-case text-gray-800"}>
                  {item.phoneNumber}
                </div>
              </td>
              <td className={"max-w-[200px] px-4 py-2"}>
                <div
                  className={
                    "link-primary max-w-[200px] cursor-pointer truncate text-sm font-semibold hover:underline"
                  }>
                  <a href={`mailto:${item.email}`}>{item.email}</a>
                </div>
              </td>
              <td className={"px-4 py-2"}>
                <div
                  className={"text-sm font-semibold normal-case text-gray-800"}>
                  {item.customerType}
                </div>
              </td>
              <td className={"px-4 py-2"}>
                <div
                  className={"text-sm font-semibold normal-case text-gray-800"}>
                  {item?.openWorkCounts}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isOpenDelete && (
        <DeleteMessageContactPopup
          isOpen={isOpenDelete}
          closePopup={() => setIsOpenDelete(false)}
          confirmDeleteContact={() => {
            setIsOpenDelete(false);
            deleteContact({
              body: customers.map((i) => i.customerId),
            });
            setCustomers([]);
          }}
        />
      )}
      {!!contacts?.length && (
        <div>
          <Pagination
            numPages={totalPages}
            currentPage={page}
            onPageChange={({ nextPage }) => {
              if (!isLoadingContacts) {
                setSearchParams(getParams(nextPage));
              }
            }}
          />
        </div>
      )}
    </FormSection>
  );
};
