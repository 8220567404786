import React, { FC, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import Button from "../../../../../../components/button/Button";
import {
  getInitials,
  inactiveStatusId,
} from "../../../../../../constants/constants";
import { getLinkedFirm } from "../../../../../../sessionStorage/sessionStorage";
import {
  useArchiveOrUnarchiveMutation,
  useGetWorksQuery,
} from "../../../../../../slices/WorkSlice";
import { IContactProfile } from "../../../../../../types/types";
import ErrorPopup from "../../../../modals/ErrorPopup";
import { WorkTableSort } from "../../../../../works/components/types/work-table";
import { WorkList } from "../../../../../works/components/WorkList";
import { AddWorkModal } from "../../../../../works/modals/AddWork.modal";
import { useAppSelector } from "../../../../../../redux/redux";
import { WorkModel } from "../../../../../../factories/works/models/work.model";
import { BsArchive } from "react-icons/bs";

type Props = {
  contact: IContactProfile;
};
export const ContactWorks: FC<Props> = ({ contact }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { customerId } = useParams();
  const { user } = useAppSelector((state) => state.appReducer);

  const page = +(searchParams.get("page") || 1);
  const sortBy = searchParams.get("sortBy") as WorkTableSort;
  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc";

  const [activateDeactivate] = useArchiveOrUnarchiveMutation();
  const { data: works, isFetching: isLoadingWorks } = useGetWorksQuery({
    pageNumber: page,
    pageSize: 20,
    orgId: getLinkedFirm()?.orgId || "",
    sortBy: sortBy,
    sortOrder: sortOrder,

    customerIds: [customerId || ""],
    currentUserId: user.id,
  });

  const [isAddWork, setIsAddWork] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [archiveWorks, setArchiveWorks] = useState<WorkModel[]>([]);
  const isActive = contact?.contactStatusTypeEnumId !== inactiveStatusId;

  useEffect(() => {
    setSearchParams({
      page: "1",
      sortBy: WorkTableSort.WorkName,
      sortOrder: "asc",
    });
  }, []);

  return (
    <>
      <WorkList
        works={works || []}
        setArchiveWorks={setArchiveWorks}
        archiveWorks={archiveWorks}
        isLoading={isLoadingWorks}
        hasPages>
        <div
          className={
            "flex w-full items-center justify-between px-[32px] py-[24px] font-bold"
          }>
          <div>All Work ( {works?.[0]?.totalRecords || 0} )</div>
          <div className={"flex items-center gap-4"}>
            <div
              aria-disabled={!!archiveWorks.length}
              onClick={() => {
                activateDeactivate({
                  body: archiveWorks.map((i) => i.workId),
                  archiveWork: true,
                });
                setArchiveWorks([]);
              }}>
              <BsArchive
                data-tooltip-id="tooltip-archive"
                color={"#7c66f0"}
                size={"20px"}
                className={
                  "flex cursor-pointer items-center focus:outline-0 active:outline-0"
                }
              />
            </div>
            <Button
              buttonType={"button"}
              label={"Add work"}
              onClick={() =>
                !isActive ? setIsOpenError(true) : setIsAddWork(true)
              }
            />
          </div>
        </div>
      </WorkList>
      {isAddWork && (
        <AddWorkModal
          isOpen={isAddWork}
          onClose={() => setIsAddWork(false)}
          defaultContact={{
            value: customerId || "",
            label: `${contact?.firstName} ${contact?.lastName}`,
            avatar: getInitials(contact?.firstName, contact?.lastName),
            avatarColor: "blue",
          }}
        />
      )}
      <ErrorPopup
        closePopup={() => setIsOpenError(false)}
        isOpen={isOpenError}
      />
    </>
  );
};
