import { ChevronDown, ChevronUp } from "baseui/icon";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import {
  getInitials,
  getInitialsFromFullName,
} from "../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import { getAllUsers, getTeamWorks } from "../../services/AppService";
import { FormSection } from "../../support/FormSection";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";

type SortOrderT =
  | { name: "Assignee"; sort: "asc" | "desc" }
  | { name: "Due this week"; sort: "asc" | "desc" }
  | { name: "Due next week"; sort: "asc" | "desc" }
  | { name: "Due later"; sort: "asc" | "desc" }
  | { name: "All open"; sort: "asc" | "desc" }
  | { name: "Completed"; sort: "asc" | "desc" };

const tableHead: { id: number; body: SortOrderT }[] = [
  {
    id: 0,
    body: { name: "Assignee", sort: "asc" },
  },
  {
    id: 1,
    body: { name: "Due this week", sort: "asc" },
  },
  {
    id: 2,
    body: { name: "Due next week", sort: "asc" },
  },
  {
    id: 3,
    body: { name: "Due later", sort: "asc" },
  },
  {
    id: 4,
    body: {
      name: "All open",
      sort: "asc",
    },
  },
  {
    id: 5,
    body: { name: "Completed", sort: "asc" },
  },
];
const HomeTeamWork = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { teamWorks, users, isLoading } = useAppSelector(
    (state) => state.appReducer,
  );

  const [selectedSortOrder, setSelectedSortOrder] = React.useState<SortOrderT>({
    name: "Assignee",
    sort: "asc",
  });

  useEffectOnce(() => {
    dispatch(getTeamWorks(`${getLinkedFirm()?.orgId}`));
    dispatch(getAllUsers(`${getLinkedFirm()?.orgId}`));
  });
  const onClickSort = (item: { id: number; body: SortOrderT }) => {
    setSelectedSortOrder((prev) =>
      prev.name === item.body.name
        ? { ...prev, sort: prev.sort === "asc" ? "desc" : "asc" }
        : item.body,
    );
  };

  const currentUser = (currentUserName: string) => {
    return users.find((user) => {
      const fullName =
        user.userProfile.firstName + " " + " " + user.userProfile.lastName;
      return fullName === currentUserName;
    });
  };

  if (isLoading) {
    return <LottieLoading />;
  }
  return (
    <FormSection name={"Team"}>
      <div>
        <table className={"w-full"}>
          <thead className={"border-b-[1px] border-gray-300"}>
            <tr>
              {tableHead?.map((item) => (
                <th
                  key={item.id}
                  className={"py-[16px] pl-[16px] pr-[32px] last:pr-[16px]"}>
                  <div
                    className={"flex cursor-pointer"}
                    onClick={() => onClickSort(item)}>
                    <div className={"text-[14px] normal-case"}>
                      {item.body.name}
                    </div>
                    {selectedSortOrder.name === item.body.name && (
                      <div className={"relative left-0 top-[-2px]"}>
                        {selectedSortOrder.sort === "asc" ? (
                          <div className={"absolute"}>
                            <ChevronUp size={26} />
                          </div>
                        ) : (
                          <div className={"absolute"}>
                            <ChevronDown size={26} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={"text-[14px]"}>
            {teamWorks.map((user: any) => (
              <tr
                key={user.id}
                className={"border-b-[1px] border-gray-300 hover:bg-gray-100"}>
                <td className={"px-[16px] py-[8px]"}>
                  <div
                    className={"flex cursor-pointer items-center text-[14px]"}
                    onClick={() => {
                      navigate(
                        `/home/user-work/${user?.userId ? user.userId : ""}`,
                      );
                    }}>
                    <AvatarPlaceholder
                      size={"extra-small"}
                      label={getInitials(user.firstName, user.lastName || "")}
                    />
                    <div
                      className={
                        "link-primary  pl-2 text-sm font-[500] normal-case hover:underline"
                      }>
                      {user.firstName + " " + user.lastName}
                    </div>
                  </div>
                </td>
                <td className={"px-[16px] py-[8px] text-[14px]"}>
                  {user.thisWeek}
                </td>
                <td className={"px-[16px] py-[8px] text-[14px]"}>
                  {user.nextWeek}
                </td>
                <td className={"px-[16px] py-[8px] text-[14px]"}>
                  {user.dueLater}
                </td>
                <td className={"px-[16px] py-[8px] text-[14px]"}>
                  {user.allOpen}
                </td>
                <td className={"px-[16px] py-[8px] text-[14px]"}>
                  {user.completed}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </FormSection>
  );
};

export default HomeTeamWork;
