import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { UpdateOrgModel } from "../factories/org/factory-models";

export type LocationAddressModel = {
  line1: string;
  line2: string;
  city: string;
  region: string;
  country: string;
  postalCode: string;
  id: string;
  relayId: string | null;
  createdAt: string;
};

export type LocationModel = {
  orgId: string;
  locationId: string | null;
  locationAddress: LocationAddressModel;
  id: string;
  relayId: string | null;
  createdAt: string;
};

export type OrgModel = {
  id: string;
  legalName: string;
  displayName: string;
  phoneNumbers: { number: string }[];
  locations: LocationModel[];
  createdAt: string;
  email: string;
};

export const orgAPI = createApi({
  reducerPath: "OrgAPI",
  tagTypes: ["Org"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getOrg: build.query<OrgModel, string>({
      query: (orgId) => ({
        url: "api/org/" + orgId,
      }),
      providesTags: ["Org"],
    }),
    updateOrg: build.mutation<unknown, UpdateOrgModel>({
      query: (body) => ({
        url: "api/org/update-org",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Org"],
    }),
  }),
});
export const { useGetOrgQuery, useUpdateOrgMutation } = orgAPI;
