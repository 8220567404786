import React, { useEffect, useState } from "react";
import Button from "../../../components/button/Button";
import {
  useGetBillingContentQuery,
  useUpdateBillingContentMutation,
} from "../../../services/BillingService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";

export const BillingContent = () => {
  const { data: content } = useGetBillingContentQuery(
    getLinkedFirm()?.orgId || "",
  );
  const [editContent] = useUpdateBillingContentMutation();

  const [notes, setNotes] = useState(content?.notes || "");
  const [footer, setFooter] = useState(content?.footer || "");

  useEffect(() => {
    if (content) {
      setNotes(content.notes);
      setFooter(content.footer);
    }
  }, [content]);

  return (
    <div className={"flex flex-col gap-6"}>
      <div className={"flex flex-col gap-4"}>
        <div className={"text-sm font-bold"}>Add note to customer</div>
        <textarea
          className={"input input-bordered min-h-[150px] w-[30%]"}
          value={notes || ""}
          placeholder={
            "Thank you for your business. We look forward to serving you again"
          }
          onChange={(e) => setNotes(e.target.value)}
        />
        <div className={"text-sm font-semibold"}>
          (This message will appear on all invoices)
        </div>
      </div>
      <div className={"flex flex-col gap-4"}>
        <div className={"text-sm font-bold"}>Add footer</div>
        <textarea
          className={"input input-bordered min-h-[150px] w-[30%] flex-wrap"}
          value={footer || ""}
          placeholder={
            "Here you can add your sales tax number or let your clients know how they can pay their invoice."
          }
          onChange={(e) => setFooter(e.target.value)}
        />
      </div>
      <Button
        label={"Save"}
        disabled={content?.footer === footer && content?.notes === notes}
        buttonType={"button"}
        onClick={() =>
          editContent({
            notes: notes,
            footer: footer,
            orgId: getLinkedFirm()?.orgId || "",
            id: content?.id || "",
          })
        }
      />
    </div>
  );
};
