import React, { FC, useState } from "react";
import { useDebounce } from "usehooks-ts";

import Button from "../../../components/button/Button";
import CustomSelect from "../../../components/select/Select";
import { getInitialsFromFullName } from "../../../constants/constants";
import { useDetectTimePeriod } from "../../../hooks/useDetectTimePeriod";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { useGetSearchCustomersQuery } from "../../../slices/ContactsSlice";
import { useGetStatusesQuery } from "../../../slices/StatusesSlice";
import { useGetUsersQuery } from "../../../slices/UserSlice";
import { useGetSearchWorksQuery } from "../../../slices/WorkSlice";
import { SelectOptionType } from "../../../types/types";
import { Period, SelectDate } from "../../billing/SelectDate";
import { Filters } from "../pages/AllWorks";
import { useAppSelector } from "../../../redux/redux";
import { useGetWorkTypesQuery } from "../../../slices/WorkTypeSlice";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  filters: Filters;
};

export const FilterWorksModal: FC<Props> = ({
  isOpen,
  onClose,
  setFilters,
}) => {
  const { user } = useAppSelector((state) => state.appReducer);

  const [filtersData, setFiltersData] = useState<Filters>({
    customerIds: [],
    workName: [],
    userIds: [],
    workStatuses: [],
    worktypeIds: [],

    startDates: [],
    dueDates: [],
    fromDate: null,
    toDate: null,
  });
  const [filterStartDates, setFilterStartDates] = useState<Period | null>(null);
  const [filterDueDates, setFilterDueDates] = useState<Period | null>(null);

  const [searchWorks, setSearchWorks] = useState("");
  const [searchClients, setSearchClients] = useState("");

  const debounceSearchWork = useDebounce(searchWorks, 300);
  const debounceSearchClient = useDebounce(searchClients, 50);

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetSearchCustomersQuery({
      searchQuery: debounceSearchClient || "ab",
      orgId: getLinkedFirm()?.orgId || "",
      userId: user.id,
    });

  const { data: works, isLoading: isLoadingWorks } = useGetSearchWorksQuery({
    query: debounceSearchWork,
    orgId: getLinkedFirm()?.orgId || "",
  });
  const { data: users, isLoading: isLoadingUsers } = useGetUsersQuery(
    getLinkedFirm()?.orgId || "",
  );
  const { data: statuses, isLoading: isLoadingStatuses } = useGetStatusesQuery({
    orgId: getLinkedFirm()?.orgId || "",
  });
  const { data: workTypes, isLoading: isLoadingWorkTypes } =
    useGetWorkTypesQuery({ orgId: getLinkedFirm()?.orgId || "" });

  const { from: fromStartDate, to: toStartDate } = useDetectTimePeriod(
    filterStartDates && filterStartDates.period
      ? {
          period: filterStartDates.period,
          from: filterStartDates.from,
          to: filterStartDates.to,
        }
      : null,
  );

  const { from: fromDueDate, to: toDueDate } = useDetectTimePeriod(
    filterDueDates && filterDueDates.period
      ? {
          period: filterDueDates.period,
          from: filterDueDates.from,
          to: filterDueDates.to,
        }
      : null,
  );

  const clientOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact?.customerId || "",
          label: `${contact.customerName} `,
          avatar: getInitialsFromFullName(contact?.customerName),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }
    return options;
  };

  const usersOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (users) {
      users?.forEach((item) => {
        options.push({
          avatar: getInitialsFromFullName(
            `${item?.userProfile?.firstName} ${item?.userProfile?.lastName}`,
          ),
          label: `${item?.userProfile?.firstName} ${item?.userProfile?.lastName}`,
          value: `${item?.id}`,
        });
      });
    }
    return options;
  };

  const statusesOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (statuses) {
      statuses?.forEach((item) => {
        options.push({
          label: item?.name,
          value: item?.id,
          color: item?.color,
          isBold: true,
        });
        if (
          item?.workTypeSubStatuses &&
          item?.workTypeSubStatuses?.length > 0
        ) {
          item?.workTypeSubStatuses?.forEach((subItem) => {
            options.push({
              label: subItem?.subStatus,
              value: subItem?.id,
              color: item?.color,
            });
          });
        }
      });
    }
    return options;
  };
  const workTypeOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (workTypes) {
      workTypes?.forEach((item) => {
        options.push({
          label: item.name,
          value: item.id,
        });
      });
    }
    return options;
  };

  const onSubmit = () => {
    setFilters({
      customerIds: filtersData.customerIds,
      workName: filtersData.workName,
      userIds: filtersData.userIds,
      workStatuses: filtersData.workStatuses,
      worktypeIds: filtersData.worktypeIds,

      startDates: filterStartDates?.period ? ["BetweenDates"] : [],
      dueDates: filterDueDates?.period ? ["BetweenDates"] : [],
      fromDate: fromStartDate || fromDueDate || null,
      toDate: toStartDate || toDueDate || null,
    });
    onClose();
  };

  return (
    <div className={`modal ${isOpen ? "modal-open" : "modal-close"}`}>
      <div className={"modal-box h-[420px] w-fit max-w-[100%]"}>
        <div className={"flex flex-col gap-3"}>
          <div className={"flex w-full items-center gap-3"}>
            <div
              className={
                "w-20 whitespace-nowrap text-base font-semibold text-gray-800"
              }>
              Work name
            </div>
            <div className={"w-full min-w-[200px]"}>
              <CustomSelect
                options={works || []}
                value={filtersData.workName}
                onChange={(newValue) => {
                  const worksNames = newValue as SelectOptionType[];
                  setFiltersData((prev) => ({ ...prev, workName: worksNames }));
                }}
                inputValue={searchWorks}
                onChangeInput={setSearchWorks}
                isMulti
                removeSelectIcon
                isLoading={isLoadingWorks}
                placeholder={"Search..."}
                fullWidth
              />
            </div>
          </div>
          <div className={"grid grid-cols-2 gap-6"}>
            <div className={"flex items-center gap-3"}>
              <div className={"w-20 text-base font-semibold text-gray-800"}>
                Status
              </div>
              <div className={"min-w-[200px]"}>
                <CustomSelect
                  options={statusesOptions()}
                  value={filtersData.workStatuses}
                  onChange={(newValue) => {
                    const newStatuses = newValue as SelectOptionType[];
                    setFiltersData((prev) => ({
                      ...prev,
                      workStatuses: newStatuses,
                    }));
                  }}
                  isMulti
                  isLoading={isLoadingStatuses}
                />
              </div>
            </div>
            <div className={"flex items-center gap-3"}>
              <div className={"w-20 text-base font-semibold text-gray-800"}>
                Start date
              </div>
              <div className={"min-w-[200px]"}>
                <SelectDate
                  setValue={(value) => {
                    setFilterStartDates(value);
                    if (value?.period) {
                      setFilterDueDates(null);
                    }
                  }}
                  value={filterStartDates}
                />
              </div>
            </div>
          </div>
          <div className={"grid grid-cols-2 gap-6"}>
            <div className={"flex items-center gap-3"}>
              <div className={"w-20 text-base font-semibold text-gray-800"}>
                Assignee
              </div>
              <div className={"min-w-[200px]"}>
                <CustomSelect
                  options={usersOptions()}
                  value={filtersData.userIds}
                  onChange={(newValue) => {
                    const newUsers = newValue as SelectOptionType[];
                    setFiltersData((prev) => ({ ...prev, userIds: newUsers }));
                  }}
                  isMulti
                  isLoading={isLoadingUsers}
                />
              </div>
            </div>
            <div className={"flex items-center gap-3"}>
              <div className={"w-20 text-base font-semibold text-gray-800"}>
                Due date
              </div>
              <div className={"min-w-[200px]"}>
                <SelectDate
                  setValue={(value) => {
                    setFilterDueDates(value);
                    if (value?.period) {
                      setFilterStartDates(null);
                    }
                  }}
                  value={filterDueDates}
                />
              </div>
            </div>
          </div>
          <div className={"grid grid-cols-2 gap-6"}>
            <div className={"flex items-center gap-3"}>
              <div className={"w-20 text-base font-semibold text-gray-800"}>
                Client
              </div>
              <div className={"min-w-[200px]"}>
                <CustomSelect
                  options={clientOptions()}
                  value={filtersData.customerIds}
                  onChange={(newValue) => {
                    const clients = newValue as SelectOptionType[];
                    setFiltersData((prev) => ({
                      ...prev,
                      customerIds: clients,
                    }));
                  }}
                  isMulti
                  inputValue={searchClients}
                  onChangeInput={setSearchClients}
                  isLoading={isLoadingContacts}
                />
              </div>
            </div>
            <div className={"flex items-center gap-3"}>
              <div className={"w-20 text-base font-semibold text-gray-800"}>
                Work type
              </div>
              <div className={"min-w-[200px]"}>
                <CustomSelect
                  options={workTypeOptions()}
                  value={filtersData.worktypeIds}
                  onChange={(newValue) => {
                    const clients = newValue as SelectOptionType[];
                    setFiltersData((prev) => ({
                      ...prev,
                      worktypeIds: clients,
                    }));
                  }}
                  isMulti
                  inputValue={searchClients}
                  onChangeInput={setSearchClients}
                  isLoading={isLoadingWorkTypes}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={"modal-action"}>
          <Button label={"Cancel"} colorType={"outline"} onClick={onClose} />
          <Button label={"Apply"} onClick={onSubmit} />
        </div>
      </div>
    </div>
  );
};
