import classNames from "classnames";
import React, { Dispatch, FC, SetStateAction } from "react";

import { AvatarPlaceholder } from "../../../../../../../../components/avatar/AvatarPlaceholder";
import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import { getInitials } from "../../../../../../../../constants/constants";
import { IContactProfile } from "../../../../../../../../types/types";

type Props = {
  contact: IContactProfile;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
};

export const ContactInfo: FC<Props> = ({ contact, setIsEdit }) => {
  const address = contact?.addresses?.map((i) => (
    <div className={"mb-2 flex flex-col"}>
      {i.line1 || ""} {i.city || ""} {i.region || ""} {i.postalCode || ""}
    </div>
  ));

  return (
    <Card extraClasses={"shadow-box"}>
      <div className={"px-6 py-4"}>
        <div className={"mb-4 flex justify-between"}>
          <div className={"text-lg font-bold"}>Contact Info</div>
          <Button
            label={"Edit"}
            size={"custom"}
            extraClasses={"btn-sm"}
            onClick={() => setIsEdit(true)}
          />
        </div>
        <div className={"grid-rows-[repeat(7, max-content)] grid gap-6"}>
          <div>
            <div className={"mb-2 text-base text-gray-400"}>FULL NAME</div>
            <div className={"flex items-center gap-2"}>
              <AvatarPlaceholder
                size={"extra-small"}
                label={getInitials(
                  contact?.firstName || "",
                  contact?.lastName || "",
                )}
              />
              <div className={"text-sm font-semibold"}>
                {`${contact?.firstName} ${contact?.middleName} ${contact?.lastName}`}
              </div>
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>SIN NUMBER</div>
            <div className={"text-sm font-semibold"}>
              {contact?.socialIdentificationNumber || "-"}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>EMAIL</div>
            <div className={"flex flex-col gap-2 text-sm font-semibold"}>
              {!!contact?.emails.length
                ? contact?.emails?.map((i) => (
                    <div key={i.id}>
                      <a
                        className={classNames(
                          "link-primary text-sm font-semibold",
                          i.emailAddress
                            ? "hover:underline"
                            : "pointer-events-none",
                        )}
                        href={i.emailAddress ? `mailto:${i.emailAddress}` : ""}>
                        {i.emailAddress || "-"}
                      </a>
                    </div>
                  ))
                : "-"}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>PHONE NUMBER</div>
            <div className={"flex gap-4 text-sm font-semibold"}>
              <div className={"flex gap-4"}>
                <div className={"flex flex-col gap-2 text-sm font-semibold"}>
                  {!!contact?.phoneNumbers.length
                    ? contact?.phoneNumbers?.map((i) => (
                        <div key={i.id}>
                          {i.phoneNumberTypeEnum?.phoneNumberType}
                        </div>
                      ))
                    : "-"}
                </div>
                <div className={"flex flex-col gap-2 text-sm font-semibold"}>
                  {!!contact?.phoneNumbers.length
                    ? contact?.phoneNumbers?.map((i) => (
                        <div key={i.id}>{i.number}</div>
                      ))
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>CONTACT TYPE</div>
            <div className={"text-sm font-semibold"}>
              {contact?.contactType}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>ADDRESS</div>
            <div className={"text-sm font-semibold"}>
              {address ? address : "-"}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>ADDITIONAL INFO</div>
            <div className={"text-sm font-semibold"}>
              {contact?.additionalInfo || "-"}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
