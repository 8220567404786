import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import Select, {
  components,
  GroupBase,
  MenuListProps,
  MultiValue,
  OptionProps,
} from "react-select";
import { debounce } from "lodash";

import { SelectOptionType } from "../../types/types";
import { AvatarPlaceholder } from "../avatar/AvatarPlaceholder";
import classNames from "classnames";
import { ChevronDown, Search as SearchIcon } from "baseui/icon";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ContactsIcon, WorksIcon } from "../svg/navIcons/index-svg";

interface Props {
  options: SelectOptionType[];
  placeholder?: string;
  noOptionsMessage?: string;
  onChange: (newValue: MultiValue<SelectOptionType> | SelectOptionType) => void;
  setIsOpenAddContact?: (value: boolean) => void;
  value: SelectOptionType[] | SelectOptionType | null;
  inputValue?: string;
  onChangeInput?: (inputValue: string) => void;
  setType?: (type: "contacts" | "works") => void;
  isLoading: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  type: "contacts" | "works";
  isContactPage?: boolean;
  workTable?: boolean;
  removeOptions?: boolean;
}

type MenuListPropsType = MenuListProps<
  SelectOptionType,
  true,
  GroupBase<SelectOptionType>
> & { setIsOpenAddContact?: (value: boolean) => void };

const InputOption = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  data,
  ...rest
}: OptionProps<SelectOptionType, true, GroupBase<SelectOptionType>>) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    fontWeight: "500",
    fontSize: "14px",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    overflow: "hidden",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      data={data}
      innerProps={props}>
      <div
        className={classNames(
          "grid !font-notoSans",
          data?.caption && "grid-rows-2",
          data?.avatar && "grid-cols-[24px_1fr] gap-2",
        )}>
        {data?.avatar && (
          <div className={"mr-2 font-normal"}>
            <AvatarPlaceholder
              size={"2xs"}
              label={data?.avatar}
              type={data?.avatarColor ? data?.avatarColor : "blue"}
            />
          </div>
        )}
        <div
          className={classNames(
            "truncate !font-notoSans",
            data?.isBold && "font-bold",
          )}>
          {children}
        </div>
        {data?.caption && (
          <div className={"truncate !font-notoSans text-xs text-gray-500"}>
            {data?.caption}
          </div>
        )}
      </div>
    </components.Option>
  );
};
const MenuList = ({
  children,
  setIsOpenAddContact,
  ...props
}: MenuListPropsType) => {
  return (
    <components.MenuList {...props}>
      {setIsOpenAddContact && (
        <div
          className={
            "flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-blue-600 hover:bg-blue-400 hover:text-white"
          }
          onClick={() => {
            if (setIsOpenAddContact) {
              props.selectProps.onMenuClose();
              setIsOpenAddContact(true);
            }
          }}>
          <div
            className={
              "flex h-4 w-4 flex-nowrap items-center justify-center rounded-full border-[1px] border-blue-600"
            }>
            <FontAwesomeIcon
              icon={faPlus}
              className={"text-[10px] text-blue-600"}
            />
          </div>
          <div className={"whitespace-nowrap font-bold"}>
            Create new contact
          </div>
        </div>
      )}
      {children}
    </components.MenuList>
  );
};

const Search: FC<Props> = ({
  options,
  onChange,
  placeholder,
  value,
  inputValue,
  onChangeInput,
  setType,
  isLoading,
  noOptionsMessage,
  onFocus,
  type,
  onBlur,
  setIsOpenAddContact,
  isContactPage,
  workTable,
  removeOptions,
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null);

  const [isOpenType, setIsOpenType] = useState(false);

  useOnClickOutside(dropDownRef, () => setIsOpenType(false));

  const handleFocus = useCallback((e) => onFocus && onFocus(e), [onFocus]);
  const handleBlur = useCallback((e) => onBlur && onBlur(e), [onBlur]);

  const selectStyles = useMemo(
    () => ({
      indicatorsContainer: () => ({ display: "none" }),
      menuPortal: (base: any) =>
        removeOptions
          ? { display: "none" }
          : {
              ...base,
              zIndex: 9999,
              width: "300px",
              marginLeft: "-42px",
              display: inputValue ? "block" : "none",
            },
      placeholder: (base: any) => ({
        ...base,
        fontSize: workTable ? "14px" : "16px",
        fontFamily:
          '"Noto-sans", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important',
      }),
      singleValue: (base: any) => ({ ...base, fontSize: 14, fontWeight: 500 }),
      valueContainer: (base: any) => ({
        ...base,
        flexWrap: "nowrap",
        padding: 0,
      }),
      input: (base: any) => ({
        ...base,
        margin: 0,
        cursor: "text",
        width: "180px",
      }),
      control: (base: any) => ({
        ...base,
        minHeight: "100%",
        padding: "0",
        width: "100%",
        boxShadow: "none",
        borderRadius: "0",
        border: "none",
      }),
      container: (base: any) => ({ ...base, width: "100%" }),
    }),
    [removeOptions, workTable, inputValue], // Следим за изменением inputValue
  );

  return (
    <div className="flex h-10 w-[300px] items-center gap-2 rounded-[8px] bg-white px-[10px]">
      <div className="">
        <SearchIcon size={25} />
      </div>
      <Select
        value={value}
        placeholder={placeholder}
        filterOption={null}
        hideSelectedOptions={false}
        onChange={onChange}
        inputValue={inputValue}
        noOptionsMessage={() => noOptionsMessage || "No results"}
        onInputChange={(newValue) => onChangeInput && onChangeInput(newValue)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        isLoading={isLoading}
        menuPortalTarget={document.body}
        styles={selectStyles}
        components={{
          Option: InputOption,
          MenuList: ({ children, ...props }) => (
            <MenuList {...props} setIsOpenAddContact={setIsOpenAddContact}>
              {children}
            </MenuList>
          ),
        }}
        options={options}
      />
      {!isContactPage && (
        <div
          ref={dropDownRef}
          className={classNames(
            "dropdown dropdown-end",
            isOpenType && "dropdown-open",
          )}>
          <div
            onClick={() => setIsOpenType(!isOpenType)}
            className="flex w-16 items-center gap-1 border-l border-gray-300 px-2">
            {type === "contacts" ? (
              <ContactsIcon className="h-6 w-6" />
            ) : (
              <WorksIcon className="h-6 w-6" />
            )}

            <div>
              <ChevronDown size={24} fontFamily="notoSans" />
            </div>
          </div>
          <div className="dropdown-content z-[1] mt-2 w-[120px] rounded-[8px] border border-gray-300 bg-white py-1">
            <div
              onClick={() => setType && setType("contacts")}
              className="flex cursor-pointer items-center gap-1 px-2 py-1 hover:bg-gray-300">
              <ContactsIcon className="h-6 w-6" />
              <div className="text-sm font-semibold">Contacts</div>
            </div>
            <div
              onClick={() => setType && setType("works")}
              className="flex cursor-pointer items-center gap-1 px-2 py-1 hover:bg-gray-300">
              <WorksIcon className="h-6 w-6" />
              <div className="text-sm font-semibold">Work items</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;
