import moment from "moment/moment";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";

import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import { StyledDatePicker } from "../../../../../../../../components/datepicker/datepicker";
import CustomSelect from "../../../../../../../../components/select/Select";
import {
  Address,
  UpdateOrganizationModel,
} from "../../../../../../../../factories/contacts/models/update-organization.model";
import {
  useEditOrgContactMutation,
  useGetContactTypesQuery,
  useGetOrganizationTypesQuery,
} from "../../../../../../../../slices/ContactsSlice";
import {
  IOrganizationContact,
  IPhoneType,
  reportingDateGst,
  reportingDatePst,
  salesTax,
  SelectOptionType,
} from "../../../../../../../../types/types";
import { getDaysOptions, MonthsEnum, monthsOptions } from "./options";
import * as devConfig from "../../../../../../../../app/configs/devConfig";
import { getAuthHeader } from "../../../../../../../../app/globalState/GlobalAuthState";
import { useEffectOnce } from "usehooks-ts";
import { v4 as uuidv4 } from "uuid";
import { FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

type Props = {
  organization: IOrganizationContact;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
};

type FormData = {
  companyName: string;
  businessNumber: string;
  yearEndMonth: SelectOptionType;
  yearEndDay: SelectOptionType;
  salesTaxId: SelectOptionType;
  organizationTypeId: SelectOptionType;
  phoneNumbers: string[];
  emails: string[];
  contactType: SelectOptionType;
  address: {
    line1: string;
    city: string;
    region: string;
    postalCode: string;
  };
  additionalInfo: string;
  incorporationDate: string;
  gstHstNumber: string;
  pstNumber: string;
  gstHstReportingPeroid: SelectOptionType;
  pstReportingPeroid: SelectOptionType;
  phoneNumberType: SelectOptionType;
  emailAddress: string;
  firstName: string;
  lastName: string;
  contactPersonPhoneNumberType: SelectOptionType[];
  contactPersonPhoneNumber: string[];
  contactPersonPhoneType: string[];
  contactPersonPhoneTypeId: string[];
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonEmailAddress: string[];
};

export const OrganizationEditInfo: FC<Props> = ({
  organization,
  setIsEdit,
}) => {
  const { data: contactTypes, isLoading } = useGetContactTypesQuery();
  const { data: organizationTypes, isLoading: isLoadingOrganizationTypes } =
    useGetOrganizationTypesQuery();

  const [editOrganization, { isSuccess, isLoading: isLoadingEdit }] =
    useEditOrgContactMutation();

  const [phoneTypes, setPhoneTypes] = useState<SelectOptionType[]>([]);
  const [phoneData, setPhoneData] = useState<
    { type: SelectOptionType; number: string; id: string }[]
  >(() => {
    return [
      {
        id: organization?.phoneNumbers?.[0]?.id || uuidv4(),
        number: organization?.phoneNumbers?.[0]?.number || "",
        type: {
          value:
            organization?.phoneNumbers?.[0]?.phoneNumberTypeEnumId ||
            "bcd697a8-ef4c-4f91-bb8f-891df1ddfba0",
          label:
            organization?.phoneNumbers?.[0]?.phoneNumberTypeEnum
              ?.phoneNumberType || "Mobile",
        },
      },
    ];
  });
  const [contactPersonPhoneData, setContactPersonPhoneData] = useState<
    { type: SelectOptionType; number: string; id: string }[]
  >(() => {
    return [
      {
        id: organization?.contactPersons?.phoneNumbers?.[0]?.id || uuidv4(),
        number: organization?.contactPersons?.phoneNumbers?.[0]?.number || "",
        type: {
          value:
            organization?.contactPersons?.phoneNumbers?.[0]
              ?.phoneNumberTypeEnumId || "bcd697a8-ef4c-4f91-bb8f-891df1ddfba0",
          label:
            organization?.contactPersons?.phoneNumbers?.[0]?.phoneNumberTypeEnum
              ?.phoneNumberType || "Mobile",
        },
      },
    ];
  });
  const [emailsData, setEmailsData] = useState<
    { emailAddresses: string; id: string }[]
  >(() => {
    return [
      {
        id: organization?.emails?.[0]?.id || uuidv4(),
        emailAddresses: organization?.emails?.[0]?.emailAddress || "",
      },
    ];
  });
  const [contactPersonEmailsData, setContactPersonEmailsData] = useState<
    { emailAddresses: string; id: string }[]
  >(() => {
    return [
      {
        id: organization?.emails?.[0]?.id || uuidv4(),
        emailAddresses: organization?.emails?.[0]?.emailAddress || "",
      },
    ];
  });
  const [addressesData, setAddressesData] = useState<Address[]>(() => {
    return [
      {
        id: organization?.addresses?.[0]?.id || uuidv4(),
        line1: organization?.addresses?.[0]?.line1,
        city: organization?.addresses?.[0]?.city,
        region: organization?.addresses?.[0]?.region,
        postalCode: organization?.addresses?.[0]?.postalCode,
      },
    ];
  });
  const [incorporationDate, setIncorporationDate] = useState<Date | null>(
    organization?.incorporationDate
      ? new Date(organization.incorporationDate)
      : null,
  );

  const {
    register,
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      companyName: organization?.companyName || "",
      businessNumber: organization?.businessNumber || "",
      yearEndMonth: {
        value: organization?.yearEndMonth || "",
        label: organization?.yearEndMonth || "",
      },
      yearEndDay: {
        value: organization?.yearEndDay.toString() || "",
        label: organization?.yearEndDay.toString() || "",
      },
      salesTaxId: {
        value: organization?.salesTaxId || "",
        label: organization?.salesTaxType?.salesTaxType || "",
      },
      organizationTypeId: {
        value: organization?.organizationTypeId || "",
        label: organization?.organizationType?.organizationType || "",
      },
      phoneNumbers: organization?.phoneNumbers.map((i) => i.number) || [],
      phoneNumberType: {
        value: organization?.phoneNumbers[0]?.phoneNumberTypeEnumId || "",
        label:
          organization?.phoneNumbers[0]?.phoneNumberTypeEnum?.phoneNumberType ||
          "",
      },
      emails: organization?.emails?.map((i) => i.emailAddress),
      contactType: {
        value: organization?.contactTypeEnumId || "",
        label: organization?.contactType?.contactType || "",
      },
      address: {
        line1: organization?.addresses?.[0]?.line1 || "",
        city: organization?.addresses?.[0]?.city || "",
        region: organization?.addresses?.[0]?.region || "",
        postalCode: organization?.addresses?.[0]?.postalCode || "",
      },
      additionalInfo: organization?.additionalInfo || "",
      incorporationDate: organization?.incorporationDate
        ? organization?.incorporationDate?.toString()
        : "",
      gstHstNumber: organization.gstHstNumber,
      pstNumber: organization.pstNumber,
      gstHstReportingPeroid: {
        label: organization.gstHstReportingPeroid,
        value: organization.gstHstReportingPeroid,
      },
      pstReportingPeroid: {
        label: organization.pstReportingPeroid,
        value: organization.pstReportingPeroid,
      },
      contactPersonFirstName: organization?.contactPersons?.firstName || "",
      contactPersonLastName: organization?.contactPersons?.lastName || "",
      contactPersonPhoneNumber:
        organization?.contactPersons?.phoneNumbers.map((i) => i.number) || [],
      contactPersonEmailAddress:
        organization?.contactPersons?.emails.map((i) => i.emailAddress) || [],
      contactPersonPhoneNumberType:
        organization?.contactPersons?.phoneNumbers.map((i) => ({
          value: i.phoneNumberTypeEnumId,
          label: i.phoneNumberTypeEnum?.phoneNumberType,
        })),
    },
  });

  /*  const salesTaxOptions: SelectOptionType[] = salesTax.map((item) => ({
    value: item.id,
    label: item.name,
  }));*/

  const reportingPeriodGst: SelectOptionType[] = reportingDateGst.map(
    (item) => ({
      label: item.name,
      value: item.id,
    }),
  );
  const reportingPeriodPst: SelectOptionType[] = reportingDatePst.map(
    (item) => ({
      label: item.name,
      value: item.id,
    }),
  );
  const onClickAddPhone = () => {
    setPhoneData((prevData) => [
      ...prevData,
      {
        id: uuidv4(),
        number: "",
        type: {
          label: "Mobile",
          value: "bcd697a8-ef4c-4f91-bb8f-891df1ddfba0",
        },
      },
    ]);
  };
  const onClickAddContactPersonPhone = () => {
    setContactPersonPhoneData((prevData) => [
      ...prevData,
      {
        id: uuidv4(),
        number: "",
        type: {
          label: "Mobile",
          value: "bcd697a8-ef4c-4f91-bb8f-891df1ddfba0",
        },
      },
    ]);
  };
  const onClickAddContactPersonEmail = () => {
    setContactPersonEmailsData((prevData) => [
      ...prevData,
      {
        id: uuidv4(),
        emailAddresses: "",
      },
    ]);
  };
  const onClickAddEmail = () => {
    setEmailsData((prevData) => [
      ...prevData,
      {
        id: uuidv4(),
        emailAddresses: "",
      },
    ]);
  };
  const onClickAddAddress = () => {
    setAddressesData((prevData) => [
      ...prevData,
      {
        id: uuidv4(),
        line1: "",
        postalCode: "",
        city: "",
        region: "",
      },
    ]);
  };
  const onClickDeleteItem = (id: string, index: number) => {
    if (phoneData?.length > 1) {
      setPhoneData(() => phoneData?.filter((_, i) => i !== index));
    }
  };
  const onClickDeleteContactPersonItem = (id: string, index: number) => {
    if (contactPersonPhoneData?.length > 1) {
      setContactPersonPhoneData(() => phoneData?.filter((_, i) => i !== index));
    }
  };
  const onClickDeleteContactPersonEmailItem = (id: string, index: number) => {
    if (contactPersonEmailsData?.length > 1) {
      setContactPersonEmailsData(() =>
        contactPersonEmailsData?.filter((_, i) => i !== index),
      );
    }
  };
  const onClickDeleteEmailItem = (id: string, index: number) => {
    if (emailsData?.length > 1) {
      setEmailsData(() => emailsData?.filter((_, i) => i !== index));
    }
  };
  const onClickDeleteAddressItem = (id: string, index: number) => {
    if (addressesData?.length > 1) {
      setAddressesData(() => addressesData?.filter((_, i) => i !== index));
    }
  };

  const phoneItems = (phoneData || [])?.map((item, index) => {
    return (
      <div key={item.id} className={"flex w-full gap-4"}>
        <div className={"form-control h-[40px] w-[200px]"}>
          <CustomSelect
            small
            options={phoneTypes}
            value={item.type}
            onChange={(newValue) => {
              const newType = Array.isArray(newValue) ? newValue[0] : newValue;
              setPhoneData((prevState) =>
                prevState.map((item, idx) =>
                  idx === index ? { ...item, type: newType } : item,
                ),
              );
            }}
          />
          {errors?.phoneNumberType?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.phoneNumberType?.message}
            </span>
          )}
        </div>
        <input
          placeholder={"Phone number"}
          value={item.number}
          type={"text"}
          onInput={(e: React.FormEvent<HTMLInputElement>) => {
            const target = e.target as HTMLInputElement;
            target.value = target.value.replace(/[^0-9]/g, "");
          }}
          onChange={(e) => {
            const newNumber = e.target.value;
            setPhoneData((prevState) =>
              prevState.map((item, idx) =>
                idx === index ? { ...item, number: newNumber } : item,
              ),
            );
          }}
          className={"input input-bordered input-sm w-full"}
        />
        {phoneData?.length > 1 ? (
          <div
            className={"bg-transparent text-lg text-red-600"}
            onClick={() => onClickDeleteItem(item?.id || "", index)}>
            <FontAwesomeIcon icon={faTrashCan} className={"cursor-pointer"} />
          </div>
        ) : (
          <div className={"bg-transparent text-lg text-gray-300"}>
            <FontAwesomeIcon icon={faTrashCan} />
          </div>
        )}
      </div>
    );
  });

  const contactPersonPhoneItems = (contactPersonPhoneData || [])?.map(
    (item, index) => {
      return (
        <div key={item.id} className={"flex w-full gap-4"}>
          <div className={"form-control h-[40px] w-[200px]"}>
            <CustomSelect
              small
              options={phoneTypes}
              value={item.type}
              onChange={(newValue) => {
                const newType = Array.isArray(newValue)
                  ? newValue[0]
                  : newValue;
                setContactPersonPhoneData((prevState) =>
                  prevState.map((item, idx) =>
                    idx === index ? { ...item, type: newType } : item,
                  ),
                );
              }}
            />
            {errors?.contactPersonPhoneNumberType?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {errors?.contactPersonPhoneNumberType?.message}
              </span>
            )}
          </div>
          <input
            placeholder={"Phone number"}
            value={item.number}
            type={"text"}
            onInput={(e: React.FormEvent<HTMLInputElement>) => {
              const target = e.target as HTMLInputElement;
              target.value = target.value.replace(/[^0-9]/g, "");
            }}
            onChange={(e) => {
              const newNumber = e.target.value;
              setContactPersonPhoneData((prevState) =>
                prevState.map((item, idx) =>
                  idx === index ? { ...item, number: newNumber } : item,
                ),
              );
            }}
            className={"input input-bordered input-sm w-full"}
          />
          {contactPersonPhoneData?.length > 1 ? (
            <div
              className={"bg-transparent text-lg text-red-600"}
              onClick={() =>
                onClickDeleteContactPersonItem(item?.id || "", index)
              }>
              <FontAwesomeIcon icon={faTrashCan} className={"cursor-pointer"} />
            </div>
          ) : (
            <div className={"bg-transparent text-lg text-gray-300"}>
              <FontAwesomeIcon icon={faTrashCan} />
            </div>
          )}
        </div>
      );
    },
  );
  const contactPersonEmailsItems = (contactPersonEmailsData || [])?.map(
    (item, index) => {
      return (
        <div key={item.id} className={"flex w-full gap-4"}>
          <input
            placeholder={"Email"}
            value={item.emailAddresses}
            onChange={(e) => {
              const newEmail = e.target.value;
              setContactPersonEmailsData((prevState) =>
                prevState.map((item, idx) =>
                  idx === index ? { ...item, emailAddresses: newEmail } : item,
                ),
              );
            }}
            type={"text"}
            className={"input input-bordered input-sm mb-2 w-full"}
          />
          {errors.contactPersonEmailAddress && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors.contactPersonEmailAddress.message}
            </span>
          )}
          {contactPersonEmailsData?.length > 1 ? (
            <div
              className={"bg-transparent text-lg text-red-600"}
              onClick={() =>
                onClickDeleteContactPersonEmailItem(item?.id || "", index)
              }>
              <FontAwesomeIcon icon={faTrashCan} className={"cursor-pointer"} />
            </div>
          ) : (
            <div className={"bg-transparent text-lg text-gray-300"}>
              <FontAwesomeIcon icon={faTrashCan} />
            </div>
          )}
        </div>
      );
    },
  );
  const emailsItems = (emailsData || [])?.map((item, index) => {
    return (
      <div className={"flex w-full gap-4"} key={item.id}>
        <input
          placeholder={"Email"}
          value={item.emailAddresses}
          onChange={(e) => {
            const newEmail = e.target.value;
            setEmailsData((prevState) =>
              prevState.map((item, idx) =>
                idx === index ? { ...item, emailAddresses: newEmail } : item,
              ),
            );
          }}
          type={"text"}
          className={"input input-bordered input-sm mb-2 w-full"}
        />
        {errors.emails && (
          <span className="pt-2 text-sm font-bold text-error">
            {errors.emails.message}
          </span>
        )}
        {emailsData?.length > 1 ? (
          <div
            className={"bg-transparent text-lg text-red-600"}
            onClick={() => onClickDeleteEmailItem(item?.id || "", index)}>
            <FontAwesomeIcon icon={faTrashCan} className={"cursor-pointer"} />
          </div>
        ) : (
          <div className={"bg-transparent text-lg text-gray-300"}>
            <FontAwesomeIcon icon={faTrashCan} />
          </div>
        )}
      </div>
    );
  });
  const addressesItems = (addressesData || [])?.map((item, index) => {
    return (
      <div className={"mb-4 flex w-full gap-4"} key={item.id}>
        <div className={"grid grid-rows-[repeat(3,max-content)] gap-4"}>
          <input
            placeholder={"Street address"}
            value={item.line1}
            onChange={(e) => {
              const newLine1 = e.target.value;
              setAddressesData((prevState) =>
                prevState.map((item, idx) =>
                  idx === index ? { ...item, line1: newLine1 } : item,
                ),
              );
            }}
            type={"text"}
            className={"input input-bordered input-sm w-full"}
          />
          <div className={"grid grid-cols-2 gap-4"}>
            <input
              placeholder={"City"}
              value={item.city}
              onChange={(e) => {
                const newCity = e.target.value;
                setAddressesData((prevState) =>
                  prevState.map((item, idx) =>
                    idx === index ? { ...item, city: newCity } : item,
                  ),
                );
              }}
              type={"text"}
              className={"input input-bordered input-sm w-full"}
            />
            <input
              placeholder={"State / Province"}
              value={item.region}
              onChange={(e) => {
                const newRegion = e.target.value;
                setAddressesData((prevState) =>
                  prevState.map((item, idx) =>
                    idx === index ? { ...item, region: newRegion } : item,
                  ),
                );
              }}
              type={"text"}
              className={"input input-bordered input-sm w-full"}
            />
          </div>
          <div className={"grid grid-cols-2 gap-4"}>
            <input
              placeholder={"Zip / Postal Code"}
              onChange={(e) => {
                const newPostalCode = e.target.value;
                setAddressesData((prevState) =>
                  prevState.map((item, idx) =>
                    idx === index
                      ? { ...item, postalCode: newPostalCode }
                      : item,
                  ),
                );
              }}
              value={item.postalCode}
              type={"text"}
              className={"input input-bordered input-sm w-full"}
            />
            <div />
          </div>
        </div>
        {addressesData?.length > 1 ? (
          <div
            className={"bg-transparent text-lg text-red-600"}
            onClick={() => onClickDeleteAddressItem(item?.id || "", index)}>
            <FontAwesomeIcon icon={faTrashCan} className={"cursor-pointer"} />
          </div>
        ) : (
          <div className={"bg-transparent text-lg text-gray-300"}>
            <FontAwesomeIcon icon={faTrashCan} />
          </div>
        )}
      </div>
    );
  });
  const onSubmit = (data: FormData) => {
    if (!isLoadingEdit) {
      const body: UpdateOrganizationModel = {
        id: organization?.id || "",
        companyName: data?.companyName || "",
        businessNumber: data.businessNumber || "",
        yearEndMonth: data.yearEndMonth.value as MonthsEnum,
        yearEndDay: +data.yearEndDay.value || 0,
        salesTaxId: data.salesTaxId.value || null,
        organizationTypeId: data.organizationTypeId.value || null,
        phoneNumbers:
          phoneData
            ?.filter((i) => i.number !== "")
            .map((i) => ({
              number: i.number,
              phoneNumberTypeEnumId: i.type.value,
            })) || [],
        emails:
          emailsData?.map((i) => ({ emailAddress: i.emailAddresses })) || [],
        contactTypeEnumId: data.contactType.value,
        addresses: addressesData?.map((i) => ({
          id: i.id,
          line1: i.line1 || "",
          city: i.city || "",
          region: i.region || "",
          postalCode: i.postalCode || "",
        })),

        incorporationDate: incorporationDate?.toISOString() || null,
        additionalInfo: data?.additionalInfo || "",
        socialIdentificationNumber: organization?.socialIdentificationNumber,
        contactStatusEnumId: organization?.contactStatusEnumId,
        gstHstNumber: data.gstHstNumber,
        pstNumber: data.pstNumber,
        gstHstReportingPeroid: data.gstHstReportingPeroid.label,
        pstReportingPeroid: data.pstReportingPeroid.label,
        contactPersons: {
          firstName: data?.contactPersonFirstName,
          lastName: data?.contactPersonLastName,
          phoneNumbers:
            contactPersonPhoneData
              ?.filter((i) => i.number !== "")
              .map((i) => ({
                number: i.number,
                phoneNumberTypeEnumId: i.type.value,
              })) || [],
          emails:
            contactPersonEmailsData.map((i) => ({
              emailAddress: i.emailAddresses,
            })) || [],
        },
      };

      editOrganization(body);
    }
  };
  const getPhoneTypes = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/phone-num-types/all`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IPhoneType[] = await response.json();
      const sortData = data.sort((a: any, b: any) => {
        if (a?.phoneNumberType === "Mobile") {
          return -1;
        } else if (b?.phoneNumberType === "Mobile") {
          return 1;
        } else {
          return 0;
        }
      });

      const options: SelectOptionType[] = sortData.map((item) => ({
        value: item?.id,
        label: item?.phoneNumberType,
      }));

      setValue("phoneNumberType", {
        value:
          organization?.phoneNumbers[0]?.phoneNumberTypeEnumId ||
          options[0].value,
        label:
          organization?.phoneNumbers[0]?.phoneNumberTypeEnum?.phoneNumberType ||
          options[0].label,
      });
      setValue("contactPersonPhoneNumberType", [
        {
          value:
            organization?.contactPersons?.phoneNumbers?.[0]
              ?.phoneNumberTypeEnumId || options[0].value,
          label:
            organization?.contactPersons?.phoneNumbers[0]?.phoneNumberTypeEnum
              ?.phoneNumberType || options[0].label,
        },
      ]);
      setPhoneTypes(options);
    }
  };

  const getDataForAddContact = async () => {
    await getPhoneTypes();
  };

  useEffectOnce(() => {
    getDataForAddContact().then();
  });

  useEffect(() => {
    setPhoneData(
      !!organization?.phoneNumbers.length
        ? organization?.phoneNumbers?.map((i) => ({
            type: {
              value:
                i.phoneNumberTypeEnumId ||
                "bcd697a8-ef4c-4f91-bb8f-891df1ddfba0",
              label: i.phoneNumberTypeEnum.phoneNumberType || "Mobile",
            },
            number: i.number || "",
            id: i.id || uuidv4(),
          }))
        : [
            {
              type: {
                value: "bcd697a8-ef4c-4f91-bb8f-891df1ddfba0",
                label: "Mobile",
              },
              number: "",
              id: uuidv4(),
            },
          ],
    );
  }, [organization]);

  useEffect(() => {
    setContactPersonPhoneData(
      organization?.contactPersons?.phoneNumbers
        ? organization?.contactPersons?.phoneNumbers?.map((i) => ({
            type: {
              value:
                i.phoneNumberTypeEnumId ||
                "bcd697a8-ef4c-4f91-bb8f-891df1ddfba0",
              label: i.phoneNumberTypeEnum?.phoneNumberType || "Mobile",
            },
            number: i.number || "",
            id: i.id || uuidv4(),
          }))
        : [
            {
              type: {
                value: "bcd697a8-ef4c-4f91-bb8f-891df1ddfba0",
                label: "Mobile",
              },
              number: "",
              id: uuidv4(),
            },
          ],
    );
  }, [organization]);

  useEffect(() => {
    setContactPersonEmailsData(
      organization?.contactPersons?.emails
        ? organization?.contactPersons?.emails?.map((i) => ({
            id: i.id || uuidv4(),
            emailAddresses: i.emailAddress || "",
          }))
        : [
            {
              emailAddresses: "",
              id: uuidv4(),
            },
          ],
    );
  }, [organization]);

  useEffect(() => {
    setEmailsData(
      !!organization?.emails.length
        ? organization?.emails?.map((i) => ({
            id: i.id || uuidv4(),
            emailAddresses: i.emailAddress || "",
          }))
        : [
            {
              emailAddresses: "",
              id: uuidv4(),
            },
          ],
    );
  }, [organization]);

  useEffect(() => {
    setAddressesData(
      !!organization?.addresses?.length
        ? organization?.addresses?.map((i) => ({
            id: i.id || uuidv4(),
            line1: i.line1 || "",
            region: i.region || "",
            city: i.city || "",
            postalCode: i.postalCode || "",
          }))
        : [
            {
              line1: "",
              region: "",
              city: "",
              postalCode: "",
              id: uuidv4(),
            },
          ],
    );
  }, [organization]);

  useEffect(() => {
    if (isSuccess) {
      setIsEdit(false);
    }
  }, [isSuccess]);

  return (
    <Card extraClasses={"shadow-box"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"px-6 py-4"}>
          <div className={"mb-4 flex justify-between"}>
            <div className={"text-lg font-bold"}>Contact Info</div>
            <div className={"flex gap-2"}>
              <Button
                label={"Cancel"}
                buttonType={"button"}
                size={"custom"}
                colorType={"outline"}
                extraClasses={"btn-sm"}
                onClick={() => setIsEdit(false)}
              />
              <Button
                isLoading={isLoadingEdit}
                label={"Save"}
                disabled={isLoadingEdit}
                buttonType={"submit"}
                size={"custom"}
                extraClasses={"btn-sm"}
              />
            </div>
          </div>
          <div className={"grid-rows-[repeat(11, max-content)] grid gap-6"}>
            <div>
              <div className={"mb-2 text-base text-gray-400"}>LEGAL NAME</div>
              <input
                {...register("companyName", {
                  required: "This field is required",
                  maxLength: {
                    value: 50,
                    message:
                      "Company name shouldn't be greater than 50 characters",
                  },
                })}
                type={"text"}
                placeholder={"Legal name"}
                className={"input input-bordered input-sm w-full"}
              />
              {errors?.companyName && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors?.companyName?.message}
                </div>
              )}
            </div>
            <div>
              <div className={"mb-2 text-base text-gray-400"}>
                BUSINESS NUMBER
              </div>
              <input
                {...register("businessNumber")}
                type={"text"}
                placeholder={"Business number"}
                className={"input input-bordered input-sm w-full"}
              />
            </div>
            <div>
              <div className={"mb-2 text-base text-gray-400"}>
                INCORPORATION DATE
              </div>
              <div className={"flex items-center gap-4"}>
                <StyledDatePicker
                  value={
                    incorporationDate
                      ? moment(incorporationDate).toDate()
                      : null
                  }
                  onChange={(date) =>
                    setIncorporationDate(date ? moment(date).toDate() : null)
                  }
                />
                <div
                  className={
                    "cursor-pointer bg-transparent text-lg text-red-600"
                  }>
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    onClick={() => setIncorporationDate(null)}
                  />
                </div>
              </div>
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  YEAR END MONTH
                </div>
                <Controller
                  control={control}
                  name={"yearEndMonth"}
                  render={({ field: { value, onChange } }) => (
                    <CustomSelect
                      value={value}
                      options={monthsOptions || []}
                      onChange={(newValue) => {
                        const newMonth = newValue as SelectOptionType;
                        onChange(newMonth);
                      }}
                      small
                    />
                  )}
                />
              </div>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  YEAR END DAY
                </div>
                <Controller
                  control={control}
                  name={"yearEndDay"}
                  render={({ field: { value, onChange } }) => (
                    <CustomSelect
                      value={value}
                      options={
                        getDaysOptions(
                          watch("yearEndMonth")?.value as MonthsEnum,
                        ) || []
                      }
                      isLoading={isLoadingOrganizationTypes}
                      onChange={(newValue) => {
                        const organizationType = newValue as SelectOptionType;
                        onChange(organizationType);
                      }}
                      small
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <div className={"flex justify-between py-2"}>
                <div>
                  <div className={"mb-2 text-base text-gray-400"}>
                    GST/HST NUMBER
                  </div>
                  <input
                    {...register("gstHstNumber")}
                    type={"text"}
                    placeholder={"GST/HST number"}
                    className={"input input-bordered input-sm w-full"}
                  />
                </div>
                <div>
                  <div className={"mb-2 text-base text-gray-400"}>
                    REPORTING PERIOD
                  </div>
                  <Controller
                    control={control}
                    name={"gstHstReportingPeroid"}
                    render={({ field: { value, onChange } }) => (
                      <CustomSelect
                        value={value}
                        options={reportingPeriodGst}
                        onChange={(newValue) => {
                          const newReportDate = newValue as SelectOptionType;
                          onChange(newReportDate);
                        }}
                        small
                      />
                    )}
                  />
                </div>
              </div>
              <div className={"flex justify-between py-2"}>
                <div>
                  <div className={"mb-2 text-base text-gray-400"}>
                    PST NUMBER
                  </div>
                  <input
                    {...register("pstNumber")}
                    type={"text"}
                    placeholder={"PST number"}
                    className={"input input-bordered input-sm w-full"}
                  />
                </div>
                <div>
                  <div className={"mb-2 text-base text-gray-400"}>
                    REPORTING PERIOD
                  </div>
                  <Controller
                    control={control}
                    name={"pstReportingPeroid"}
                    render={({ field: { value, onChange } }) => (
                      <CustomSelect
                        value={value}
                        options={reportingPeriodPst}
                        onChange={(newValue) => {
                          const newReportDatePst = newValue as SelectOptionType;
                          onChange(newReportDatePst);
                        }}
                        small
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  ORGANIZATION TYPE
                </div>
                <Controller
                  control={control}
                  name={"organizationTypeId"}
                  render={({ field: { value, onChange } }) => (
                    <CustomSelect
                      value={value}
                      options={organizationTypes || []}
                      isLoading={isLoadingOrganizationTypes}
                      onChange={(newValue) => {
                        const organizationType = newValue as SelectOptionType;
                        onChange(organizationType);
                      }}
                      small
                    />
                  )}
                />
              </div>
              <div />
            </div>
            <div className={"flex flex-col gap-4"}>
              <div>
                <div className={"mb-2 w-full text-base text-gray-400"}>
                  PHONE NUMBER
                </div>
                <div className={"grid grid-cols-[30px_300px] items-center"}>
                  <div className={"mt-2 flex h-full items-start"}>
                    <div
                      className={
                        "flex h-[20px] w-[20px] items-center justify-center overflow-hidden rounded-[50%] pb-0.5"
                      }>
                      <Button
                        label={"+"}
                        buttonType={"button"}
                        onClick={onClickAddPhone}
                      />
                    </div>
                  </div>
                  <div className={"items-center"}>{phoneItems}</div>
                </div>
              </div>
              <div />
            </div>
            <div>
              <div className={"mb-2 text-base text-gray-400"}>EMAIL</div>
              <div className={"grid grid-cols-[30px_300px] items-center"}>
                <div className={"mt-2 flex h-full items-start"}>
                  <div
                    className={
                      "flex h-[20px] w-[20px] items-center justify-center overflow-hidden rounded-[50%] pb-0.5"
                    }>
                    <Button
                      label={"+"}
                      buttonType={"button"}
                      onClick={onClickAddEmail}
                    />
                  </div>
                </div>
                <div className={"items-center"}>{emailsItems}</div>
              </div>
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  CONTACT TYPE
                </div>
                <Controller
                  control={control}
                  name={"contactType"}
                  render={({ field: { value, onChange } }) => (
                    <CustomSelect
                      value={value}
                      options={contactTypes || []}
                      isLoading={isLoading}
                      onChange={(newValue) => {
                        const contactType = newValue as SelectOptionType;
                        onChange(contactType);
                      }}
                      small
                    />
                  )}
                />
              </div>
              <div />
            </div>
            <div>
              <div className={"mb-2 text-base text-gray-400"}>ADDRESS</div>
              <div className={"grid grid-cols-[30px_300px] items-center"}>
                <div className={"mt-2 flex h-full items-start"}>
                  <div
                    className={
                      "flex h-[20px] w-[20px] items-center justify-center overflow-hidden rounded-[50%] pb-0.5"
                    }>
                    <Button
                      label={"+"}
                      buttonType={"button"}
                      onClick={onClickAddAddress}
                    />
                  </div>
                </div>
                <div className={"items-center"}>{addressesItems}</div>
              </div>
            </div>
            <div
              className={"flex flex-col gap-6 border-t border-gray-200  py-4"}>
              <div className={"text-base text-gray-400"}>CONTACT PERSON</div>
              <div className={"grid grid-cols-2 gap-4"}>
                <div>
                  <div className={"mb-2 text-base text-gray-400"}>
                    FIRST NAME
                  </div>
                  <div>
                    <input
                      {...register("contactPersonFirstName", {
                        maxLength: {
                          value: 50,
                          message:
                            "First name shouldn't be greater than 50 characters",
                        },
                      })}
                      type={"text"}
                      placeholder={"First name"}
                      className={"input input-bordered input-sm w-full"}
                    />
                  </div>
                </div>
                <div>
                  <div className={"mb-2 text-base text-gray-400"}>
                    LAST NAME
                  </div>
                  <div>
                    <input
                      {...register("contactPersonLastName", {
                        maxLength: {
                          value: 50,
                          message:
                            "Last name shouldn't be greater than 50 characters",
                        },
                      })}
                      type={"text"}
                      placeholder={"Last name"}
                      className={"input input-bordered input-sm w-full"}
                    />
                  </div>
                </div>
              </div>
              <div className={"flex flex-col"}>
                <div className={"mb-2 text-base text-gray-400"}>EMAIL</div>
                <div className={"grid grid-cols-[30px_300px] items-center"}>
                  <div className={"mt-2 flex h-full items-start"}>
                    <div
                      className={
                        "flex h-[20px] w-[20px] items-center justify-center overflow-hidden rounded-[50%] pb-0.5"
                      }>
                      <Button
                        label={"+"}
                        buttonType={"button"}
                        onClick={onClickAddContactPersonEmail}
                      />
                    </div>
                  </div>
                  <div className={"items-center"}>
                    {contactPersonEmailsItems}
                  </div>
                </div>
              </div>
              <div className={"flex flex-col"}>
                <div className={"mb-2 text-base text-gray-400"}>
                  PHONE NUMBER
                </div>
                <div className={"grid grid-cols-[30px_300px] items-center"}>
                  <div className={"mt-2 flex h-full items-start"}>
                    <div
                      className={
                        "flex h-[20px] w-[20px] items-center justify-center overflow-hidden rounded-[50%] pb-0.5"
                      }>
                      <Button
                        label={"+"}
                        buttonType={"button"}
                        onClick={onClickAddContactPersonPhone}
                      />
                    </div>
                  </div>
                  <div className={"items-center"}>
                    {contactPersonPhoneItems}
                  </div>
                </div>
              </div>
            </div>
            <div className={"border-t border-gray-200 pt-6"}>
              <div className={"mb-2 text-base text-gray-400"}>
                ADDITIONAL INFO
              </div>
              <textarea
                {...register("additionalInfo")}
                className={"input input-bordered min-h-[100px] w-full"}
              />
            </div>
          </div>
        </div>
      </form>
    </Card>
  );
};
