import React from "react";
import { Link } from "react-router-dom";

import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { useGetOrgQuery } from "../../../services/OrgService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { FormSection } from "../../../support/FormSection";

export const OrgDetailsView: React.FC<unknown> = () => {
  const { data: org, isLoading: isLoadingOrg } = useGetOrgQuery(
    getLinkedFirm()?.orgId || "",
  );

  if (isLoadingOrg) {
    return <LottieLoading />;
  }

  const editOrgInfo = (
    <Link
      to={"./edit"}
      className={
        "bg-purple flex items-center justify-center rounded-[7px] px-[20px] py-[8px] text-[14px] text-white"
      }>
      <div>Edit</div>
    </Link>
  );

  return (
    <>
      <FormSection
        name={"Firm details"}
        RightSideComponent={editOrgInfo}
        weightTitle={"bold"}
        cardBodyExtraCx={"space-y-8"}>
        <div>
          <div className={"text-[14px] font-bold"}>Company Name</div>
          <div className={"mt-0 text-[14px]"}> {org?.legalName}</div>
        </div>
        <div>
          <div className={"text-[14px] font-bold"}>Invoice Display Name</div>
          <div className={"mt-0 text-[14px]"}> {org?.displayName}</div>
        </div>
        <div>
          <div className={"text-[14px] font-bold"}>Address</div>
          <div>
            <div className={"mt-0 text-[14px]"}>
              {org?.locations?.[0]?.locationAddress?.line1}
            </div>
          </div>
        </div>
        <div className={"flex justify-between"}>
          <div className={"w-1/2"}>
            <div className={"text-[14px] font-bold"}>City</div>
            <div className={"mt-0 text-[14px]"}>
              {org?.locations?.[0]?.locationAddress?.city}
            </div>
          </div>
          <div className={"w-1/2"}>
            <div className={"text-[14px] font-bold"}>Province/State</div>
            <div className={"mt-0 text-[14px]"}>
              {org?.locations?.[0]?.locationAddress?.region}
            </div>
          </div>
        </div>
        <div className={"flex justify-between"}>
          <div className={"w-1/2"}>
            <div className={"text-[14px] font-bold"}>Postal Code/Zip</div>
            <div className={"mt-0 text-[14px]"}>
              {org?.locations?.[0]?.locationAddress?.postalCode}
            </div>
          </div>
          <div className={"w-1/2"}>
            <div className={"text-[14px] font-bold"}>Country</div>
            <div className={"mt-0 text-[14px]"}>
              {org?.locations?.[0]?.locationAddress?.country}
            </div>
          </div>
        </div>
        <div className={""}>
          <div className={"text-[14px] font-bold"}>Email</div>
          <div className={"mt-0 text-[14px]"}>{org?.email}</div>
        </div>
        <div className={""}>
          <div className={"text-[14px] font-bold"}>Phone number</div>
          <div className={"mt-0 text-[14px]"}>
            {org?.phoneNumbers?.[0]?.number}
          </div>
        </div>
      </FormSection>
    </>
  );
};
