import classNames from "classnames";
import React, { FC, useState } from "react";
import { HiXMark } from "react-icons/hi2";
import { useDebounce } from "usehooks-ts";

import Button from "../../../components/button/Button";
import CustomSelect from "../../../components/select/Select";
import { getInitialsFromFullName } from "../../../constants/constants";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { useGetSearchCustomersQuery } from "../../../slices/ContactsSlice";
import { useGetStatusesQuery } from "../../../slices/StatusesSlice";
import { useGetUsersQuery } from "../../../slices/UserSlice";
import { useGetSearchWorksQuery } from "../../../slices/WorkSlice";
import { SelectOptionType } from "../../../types/types";
import { Filters } from "../pages/AllWorks";
import { useAppSelector } from "../../../redux/redux";
import { useGetWorkTypesQuery } from "../../../slices/WorkTypeSlice";

type Props = {
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  filters: Filters;
};

export const AllWorksFilters: FC<Props> = ({ setFilters, filters }) => {
  const { user } = useAppSelector((state) => state.appReducer);

  const [searchWorks, setSearchWorks] = useState("");
  const [searchClients, setSearchClients] = useState("");

  const debounceSearchClient = useDebounce(searchClients, 50);

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetSearchCustomersQuery({
      searchQuery: debounceSearchClient || "ab",
      orgId: getLinkedFirm()?.orgId || "",
      userId: user.id,
    });

  const { data: users, isLoading: isLoadingUsers } = useGetUsersQuery(
    getLinkedFirm()?.orgId || "",
  );
  const { data: statuses, isLoading: isLoadingStatuses } = useGetStatusesQuery({
    orgId: getLinkedFirm()?.orgId || "",
  });
  const { data: workTypes, isLoading: isLoadingWorkTypes } =
    useGetWorkTypesQuery({ orgId: getLinkedFirm()?.orgId || "" });

  const clientOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact?.customerId,
          label: `${contact.customerName}`,
          avatar: getInitialsFromFullName(contact?.customerName),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };

  const usersOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (users) {
      users?.forEach((item) => {
        options.push({
          avatar: getInitialsFromFullName(
            `${item?.userProfile?.firstName} ${item?.userProfile?.lastName}`,
          ),
          label: `${item?.userProfile?.firstName} ${item?.userProfile?.lastName}`,
          value: `${item?.id}`,
        });
      });
    }
    return options;
  };

  const statusesOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (statuses) {
      statuses?.forEach((item) => {
        options.push({
          label: item?.name,
          value: item?.id,
          color: item?.color,
          isBold: true,
        });
        if (item?.workTypeSubStatuses && !!item?.workTypeSubStatuses?.length) {
          item?.workTypeSubStatuses?.forEach((subItem) => {
            options.push({
              label: subItem?.subStatus,
              value: subItem?.id,
              color: item?.color,
            });
          });
        }
      });
    }
    return options;
  };
  const workTypeOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (workTypes) {
      workTypes?.forEach((item) => {
        options.push({
          label: item.name,
          value: item.id,
        });
      });
    }
    return options;
  };

  const clearValues = () => {
    setFilters({
      customerIds: [],
      workName: [],
      userIds: [],
      workStatuses: [],
      worktypeIds: [],

      startDates: [],
      dueDates: [],
      fromDate: null,
      toDate: null,
    });
  };

  const isShowClearButton =
    !!filters?.customerIds.length ||
    !!filters?.workName.length ||
    !!filters?.userIds.length ||
    !!filters?.workStatuses.length ||
    !!filters?.startDates?.length ||
    !!filters?.dueDates?.length;

  return (
    <div
      className={classNames(
        "grid w-full items-end gap-4",
        isShowClearButton
          ? "grid-cols-[repeat(3,max-content)] xl:grid-cols-[repeat(5,max-content)]"
          : "grid-cols-[repeat(3,max-content)] xl:grid-cols-[repeat(4,max-content)]",
      )}>
      <div className={"min-w-[200px]"}>
        <div className={"mb-0.5 text-base"}>Client</div>
        <CustomSelect
          options={clientOptions()}
          value={filters?.customerIds}
          onChange={(newValue) => {
            const value = newValue as SelectOptionType[];
            setFilters((prev) => ({ ...prev, customerIds: value }));
          }}
          inputValue={searchClients}
          onChangeInput={setSearchClients}
          isMulti
          isLoading={isLoadingContacts}
        />
      </div>
      <div className={"min-w-[200px]"}>
        <div className={"mb-0.5 text-base"}>Work type</div>
        <CustomSelect
          options={workTypeOptions()}
          value={filters?.worktypeIds}
          onChange={(newValue) => {
            const value = newValue as SelectOptionType[];
            setFilters((prev) => ({
              ...prev,
              worktypeIds: value,
            }));
          }}
          inputValue={searchWorks}
          onChangeInput={setSearchWorks}
          isMulti
          isLoading={isLoadingWorkTypes}
          placeholder={"All"}
        />
      </div>
      <div className={"min-w-[200px]"}>
        <div className={"mb-0.5 text-base"}>Assignee</div>
        <CustomSelect
          options={usersOptions()}
          value={filters?.userIds}
          onChange={(newValue) => {
            const value = newValue as SelectOptionType[];
            setFilters((prev) => ({ ...prev, userIds: value }));
          }}
          inputValue={searchClients}
          onChangeInput={setSearchClients}
          isMulti
          isLoading={isLoadingUsers}
        />
      </div>
      <div className={"min-w-[200px]"}>
        <div className={"mb-0.5 text-base"}>Status</div>
        <CustomSelect
          options={statusesOptions()}
          value={filters?.workStatuses}
          onChange={(newValue) => {
            const newStatuses = Array.isArray(newValue) ? newValue : [newValue];
            setFilters((prev) => ({ ...prev, workStatuses: newStatuses }));
          }}
          isLoading={isLoadingStatuses}
        />
      </div>
      {isShowClearButton && (
        <div>
          <Button
            label={"Clear"}
            onClick={clearValues}
            colorType={"ghost"}
            icon={<HiXMark size={20} />}
          />
        </div>
      )}
    </div>
  );
};
