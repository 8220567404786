import React, { FC, useEffect, useState } from "react";
import Button from "../../../../../../components/button/Button";
import {
  IContactProfile,
  SelectOptionType,
} from "../../../../../../types/types";
import { EmailsListView } from "../../../../../emails/EmailsListView";
import { LottieLoading } from "../../../../../../components/graphics/LottieLoading";
import {
  useDisconnectEmailMutation,
  useGetEmailFoldersQuery,
  useGetRedirectUrlMutation,
  useLazyGetContactEmailsQuery,
} from "../../../../../../slices/EmailsSlice";
import { useAppSelector } from "../../../../../../redux/redux";
import { AddEmailModal } from "../../../../modals/AddEmailModal";
import { SearchInput } from "../../../../../emails/components/Search";
import { HiEllipsisVertical } from "react-icons/hi2";
import { Dropdown } from "../../../../../../components/dropdown/new-dropdown/Dropdown";
import { ComposeEmailModal } from "../../../../modals/ComposeEmailModal";
import { Email } from "../../../../../../factories/emails/models/emails.models";
import { getLinkedFirm } from "../../../../../../sessionStorage/sessionStorage";

type Props = {
  contact: IContactProfile;
};

export const ContactEmails: FC<Props> = ({ contact }) => {
  const { user } = useAppSelector((state) => state.appReducer);

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState<string[]>(["1"]);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [folder, setFolder] = useState<SelectOptionType | null>(null);
  const [isOpenCompose, setIsOpenCompose] = useState(false);
  const [selectedMail, setSelectedMail] = useState<Email | null>(null);
  const [isOpenProvider, setIsOpenProvider] = useState(false);

  const [connectEmail, { data, isSuccess }] = useGetRedirectUrlMutation();
  const [disconnectEmail] = useDisconnectEmailMutation();

  const [
    getContactEmails,
    { data: dataContactEmails, error, isFetching: isLoading },
  ] = useLazyGetContactEmailsQuery();

  const {
    data: folders,
    isLoading: isLoadingFolders,
    error: errorFolder,
  } = useGetEmailFoldersQuery({
    userId: user?.id || "",
  });

  useEffect(() => {
    getContactEmails({
      userId: user?.id || "",
      folder: folder?.label.toUpperCase() || "",
      pageNumber: page === 1 ? undefined : pages[page - 1] || "",
      contactEmail: contact?.emails?.[0].emailAddress || "",
      orgId: getLinkedFirm()?.orgId || "",
    });
  }, [page, user?.id, folder?.label]);

  useEffect(() => {
    if (dataContactEmails?.nextPageValue && page + 1 > pages.length) {
      setPages((prev) => [...prev, dataContactEmails?.nextPageValue]);
    }
  }, [dataContactEmails?.nextPageValue]);

  useEffect(() => {
    if (folders) {
      const folder = folders.find(
        (item) => item.name.toUpperCase() === "INBOX",
      );
      if (folder) {
        setFolder({
          value: folder?.id,
          label: folder?.name.toUpperCase(),
          totalCount: folder?.total_count || 0,
        });
      }
    }
  }, [folders?.length]);
  useEffect(() => {
    if (isSuccess) {
      const a = document.createElement("a");
      a.setAttribute("href", data);
      a.setAttribute("target", "_blank");
      a.click();
    }
  }, [isSuccess]);

  const folderOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    const desiredFolderNames = ["inbox", "sent", "sent items"];
    if (folders) {
      folders?.forEach((folder) => {
        if (desiredFolderNames.includes(folder?.name?.toLowerCase())) {
          options.push({
            label: folder?.name.toUpperCase(),
            value: folder?.id,
            totalCount: folder?.total_count,
          });
        }
      });
    }
    options.sort((a, b) => {
      if (a.label.toLowerCase() === "inbox") return -1;
      if (b.label.toLowerCase() === "inbox") return 1;
      return 0;
    });
    return options;
  };

  const handleButtonClick = async () => {
    disconnectEmail({
      userId: user.id || "",
    });
  };

  const isConnected =
    error !== "Grant for this User doesn't exists. Try authorizing again." &&
    errorFolder !==
      "Grant for this User doesn't exists. Try authorizing again.";

  if (isLoadingFolders) {
    return <LottieLoading />;
  }
  return (
    <div>
      {!isConnected ? (
        <div
          className={
            "mt-[200px] flex w-full flex-col items-center justify-center gap-2"
          }>
          <div className={"font-bold text-gray-500"}>Not connected</div>
          <Button label={"Connect"} onClick={() => setIsOpenProvider(true)} />
        </div>
      ) : (
        <div className={"grid w-full grid-cols-[30fr_70fr] items-center gap-4"}>
          <div className={"mt-5 w-full pb-4"}>
            <SearchInput />
          </div>
          <div className={"flex w-full justify-between"}>
            <div>
              <Button
                label={"Compose email"}
                onClick={() => setIsOpenCompose(true)}
              />
            </div>
            <div className={"flex items-center gap-2"}>
              <Button
                label={"Add email to existing work"}
                onClick={() => setIsOpenAdd(true)}
                disabled={!selectedMail}
              />
              <Button
                label={"Create work from email"}
                colorType={"outline"}
                disabled={!selectedMail}
              />
              <Dropdown
                triggerElement={
                  <div>
                    <Button
                      label={""}
                      colorType={"ghost"}
                      size={"custom"}
                      icon={<HiEllipsisVertical size={24} />}
                      extraClasses={"min-h-fit h-fit w-fit p-1"}
                    />
                  </div>
                }>
                <div
                  className={
                    "rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
                  }>
                  <div
                    onClick={handleButtonClick}
                    className={
                      "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                    }>
                    Disconnect
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
      {isConnected && (
        <EmailsListView
          emails={dataContactEmails?.data || []}
          isLoading={isLoading}
          folder={folder}
          setPage={setPage}
          setPages={setPages}
          page={page}
          setFolder={setFolder}
          folderOptions={folderOptions}
          selectedMail={selectedMail}
          setSelectedMail={setSelectedMail}
          contactPage={true}
        />
      )}
      {isOpenAdd && (
        <AddEmailModal
          isActive={isOpenAdd}
          onClose={() => setIsOpenAdd(false)}
          contactId={contact?.contactId || ""}
          selectedMail={selectedMail}
        />
      )}
      {isOpenCompose && (
        <ComposeEmailModal
          isActive={isOpenCompose}
          onClose={() => setIsOpenCompose(false)}
        />
      )}
      {isOpenProvider && (
        <div
          className={
            "fixed left-0 top-0 flex h-screen w-screen items-center justify-center bg-black/20"
          }
          onClick={() => setIsOpenProvider(false)}>
          <div className={"modal-box absolute w-[300px] bg-white shadow-box"}>
            <div className={"pb-2 font-bold"}>Choose your provider:</div>
            <div
              className={
                "flex cursor-pointer items-center gap-4 rounded-[8px] px-1 py-2 text-lg font-semibold hover:bg-gray-300"
              }
              onClick={() =>
                connectEmail({
                  provider: "google",
                  returnUrl: document.URL,
                  userId: user?.id || "",
                })
              }>
              <img
                className={"h-[20px] w-[25px]"}
                alt={"Gmail"}
                src={
                  "https://mailmeteor.com/logos/assets/PNG/Gmail_Logo_512px.png"
                }
              />
              Google
            </div>
            <div
              className={
                "flex cursor-pointer items-center gap-4 rounded-[8px] px-1 py-2 text-lg font-semibold hover:bg-gray-300"
              }
              onClick={() =>
                connectEmail({
                  provider: "microsoft",
                  returnUrl: document.URL,
                  userId: user?.id || "",
                })
              }>
              <img
                className={"h-[25px] w-[25px]"}
                alt={"Outlook"}
                src={
                  "https://mailmeteor.com/logos/assets/PNG/Microsoft_Office_Outlook_Logo_512px.png"
                }
              />
              Microsoft
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
