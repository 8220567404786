import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDown } from "baseui/icon";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import Button from "../../../components/button/Button";
import CustomCurrencyInput from "../../../components/CustomCurrencyInput";
import { StyledDatePicker } from "../../../components/datepicker/datepicker";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import CustomSelect from "../../../components/select/Select";
import {
  getInitials,
  getInitialsFromFullName,
  timeFormatter,
} from "../../../constants/constants";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { useAppSelector } from "../../../redux/redux";
import {
  IAddInvoice,
  useAddInvoiceMutation,
  useGetInvoiceNoQuery,
  useGetInvoiceTermsQuery,
  useGetServicesQuery,
  useGetTaxRateQuery,
  useUpdateBilledStatusMutation,
} from "../../../services/BillingService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { useGetSearchCustomersQuery } from "../../../slices/ContactsSlice";
import {
  useAddBilledWorkMutation,
  useGetUsersQuery,
  useGetWorksByCustomerIdMutation,
} from "../../../slices/UserSlice";
import { FormSection } from "../../../support/FormSection";
import { IUser, SelectOptionType } from "../../../types/types";
import { transformDateToUTC } from "../../../utils/transformDate";
import PayInvoiceModal from "../payments/PayInvoiceModal";
import { BillableTime } from "./BillableTime";
import { InvoiceTime } from "../../time/InvoiceTime";
import { Item } from "./types";
import ClockSvg from "../../../components/svg/clock-icon.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { IoTimeOutline } from "react-icons/io5";
import { ViewWork } from "./ViewWork";
import { RxDragHandleDots2 } from "react-icons/rx";
import { useDebounce } from "usehooks-ts";

const discountTypes = {
  "%": "89EDC863-9055-479B-AD08-554C0418A59F",
  $: "771B703A-5E94-4271-988A-206C0B48F7A8",
};

const SaveOptions = {
  close: "Save and close",
  pay: "Save and record payment",
};

const AddInvoice = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const defaultCustomerId = searchParams?.get("customerId") || "";

  const { user } = useAppSelector((state) => state.appReducer);

  const [addInvoice, { data: newInvoice }] = useAddInvoiceMutation();
  const [updateBilledStatus] = useUpdateBilledStatusMutation();
  const { data: terms, isLoading: isLoadingTerms } = useGetInvoiceTermsQuery();
  const { data: invoiceNo, isLoading: isLoadingInvoiceNo } =
    useGetInvoiceNoQuery(getLinkedFirm()?.orgId);
  const { data: services, isLoading: isLoadingServices } = useGetServicesQuery(
    getLinkedFirm()?.orgId,
  );
  const { data: taxRateValue, isLoading: isLoadingTaxRate } =
    useGetTaxRateQuery(getLinkedFirm()?.orgId);

  const { data: users, isLoading: isLoadingUsers } = useGetUsersQuery(
    getLinkedFirm()?.orgId,
  );
  const [currentContact, setCurrentContact] = useState<SelectOptionType | null>(
    null,
  );
  const [customerId, setCustomerId] = useState(defaultCustomerId);
  const [defaultSearch, setDefaulSearch] = useState(() =>
    customerId ? "" : "ab",
  );
  const [isIncludeTax, setIsIncludeTax] = useState(false);
  const [search, setSearch] = useState("");

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetSearchCustomersQuery({
      orgId: getLinkedFirm()?.orgId || "",
      userId: user.id,
      searchQuery: search || defaultSearch,
    });

  const [invoiceCustomers, setInvoiceCustomers] = useState<SelectOptionType[]>(
    [],
  );

  const [isShowAddClient, setIsShowAddClient] = useState(false);

  const [data, setData] = useState<Item[]>(() => {
    if (!services || services.length === 0) return [];
    return [
      {
        service: services[0].id,
        description: services[0].description || "",
        qty: 1,
        rate: services[0].rate?.toString() || "0",
        amount: services[0].rate || 0,
        invoiceType: "invoiceItem",
        assignedUserId: null,
        workId: null,
        workTypeId: null,
        duration: 0,
      },
    ];
  });

  const [getWorks, { data: works, isLoading }] =
    useGetWorksByCustomerIdMutation();
  const [addBilledWork] = useAddBilledWorkMutation();

  useEffect(() => {
    getWorks({
      customerId: currentContact?.value || "",
    });
  }, [currentContact]);

  const selectUserRef = useRef<HTMLDivElement>(null);

  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [term, setTerm] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [discount, setDiscount] = useState("");
  const [discountType, setDiscountType] = useState<"$" | "%">("%");
  const [isOpenSaveMenu, setIsOpenSaveMenu] = useState(false);
  const [isOpenAddPayment, setIsOpenAddPayment] = useState(false);
  const [saveOption, setSaveOption] = useState<"close" | "pay">("close");
  const [isOpenUsers, setIsOpenUsers] = useState(false);
  const [notes, setNotes] = useState("");
  const [isBillableTime, setIsBillableTime] = useState(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);
  const [billedWorks, setBilledWorks] = useState<InvoiceTime[]>([]);
  const [currentTimeIds, setCurrentTimeIds] = useState<string[]>([]);
  const [viewWork, setViewWork] = useState(false);
  const [workItem, setWorkItem] = useState<Item>();

  useOnClickOutside(optionsRef, () => setIsOpenSaveMenu(false));
  useOnClickOutside(selectUserRef, () => setIsOpenUsers(false));

  useEffect(() => {
    setCurrentTimeIds(billedWorks.map((i) => i.id));
  }, [billedWorks]);

  const totalRate = (value: string) => {
    const hours = Number(value.split("h").slice(0, -1));
    const minutes = Number(value?.split("h")?.pop()?.replace("m", "")) / 60;
    return hours + minutes;
  };

  const addWorkItems = (items: InvoiceTime[]) => {
    setData((prev) => {
      const newBilledWorks: InvoiceTime[] = [];

      items?.forEach((item) => {
        if (!prev.find((i) => i?.id === item?.id)) {
          newBilledWorks.push(item);
        }
      });

      return [
        ...prev,
        ...(newBilledWorks?.map((i) => ({
          service: services?.[0]?.id || "",
          description: i.work.description,
          qty: +totalRate(timeFormatter(i.duration)).toFixed(2),
          rate: i.assignedUser?.hourlyRate.toString(),
          isWork: true,
          workType: i.work.service.name,
          workName: i.work.name,
          dueDate: i.work.dueDate,
          assignedUser: `${i.assignedUser?.userProfile.firstName} ${i.assignedUser?.userProfile.lastName}`,
          id: i.id,
          invoiceType: "billedItem",
          assignedUserId: i.assignedUserId,
          workId: i.work.id,
          workTypeId: i.work.service.id,
          duration: i.duration,
          amount:
            totalRate(timeFormatter(i.duration)) *
            (i.assignedUser?.hourlyRate || 0),
        })) as Item[]),
      ];
    });
    setBilledWorks(items);
    setIsBillableTime(false);
  };

  const contactsOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact?.customerId,
          label: `${contact.customerName}`,
          avatar: getInitialsFromFullName(contact?.customerName),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };

  const subtotal = useMemo(() => {
    let value = 0;
    data.forEach((item) => (value += item.amount));
    return value;
  }, [data, isIncludeTax]);

  const discountValue = useMemo(() => {
    if (discountType === "$") {
      return +discount || 0;
    }
    if (discountType === "%") {
      return (subtotal * (+discount || 0)) / 100;
    }
    return 0;
  }, [discount, discountType, subtotal]);

  const taxValue = useMemo(() => {
    if (isIncludeTax) {
      const tax: number = (taxRateValue?.[0]?.taxRate || 0) / 100 + 1;
      const subtotalWithoutTax = data.reduce(
        (acc, item) => acc + item.amount / tax,
        0,
      );
      return (
        (((subtotalWithoutTax || 0) - (discountValue || 0)) *
          (taxRateValue?.[0]?.taxRate || 0)) /
        100
      );
    }
    return (
      (((subtotal || 0) - (discountValue || 0)) *
        (taxRateValue?.[0]?.taxRate || 0)) /
      100
    );
  }, [subtotal, discountValue, taxRateValue, isIncludeTax]);

  const total = useMemo(() => {
    return isIncludeTax
      ? subtotal - discountValue
      : subtotal - discountValue + taxValue;
  }, [subtotal, taxValue, discountValue, isIncludeTax]);

  const onClickAddItem = () => {
    setData((prevData) => [
      ...prevData,
      {
        id: uuidv4(),
        service: services?.[0]?.id || "",
        description: services?.[0]?.description || "",
        qty: 1,
        rate: services?.[0]?.rate?.toString() || "",
        amount: services?.[0]?.rate || 0,
        invoiceType: "invoiceItem",
        assignedUserId: null,
        workId: null,
        workTypeId: null,
        duration: 0,
      },
    ]);
  };

  const onClickDeleteItem = (id: string, index: number) => {
    if (data.length > 1) {
      setData(() => data.filter((_, i) => i !== index));
      setBilledWorks(billedWorks.filter((i) => i.id !== id));
    }
  };

  useEffect(() => {
    if (customerId && contacts?.length && !isLoadingContacts) {
      const options = contactsOptions();

      const defaultCustomer =
        options?.find((item) => {
          return String(item.value).trim() === String(customerId).trim();
        }) || null;

      setCurrentContact(defaultCustomer);
      setCustomerId("");
    }
  }, [customerId, contacts, isLoadingContacts]);

  useEffect(() => {
    setDefaulSearch(customerId ? "" : "ab");
  }, [customerId]);

  useEffect(() => {
    if (terms && terms?.length > 0 && term) {
      const name = terms?.find((item) => item.id === term)?.termName || "";
      if (name === "Due on Receipt") {
        setDueDate(invoiceDate);
      }
      if (name === "Net 60") {
        setDueDate(moment(invoiceDate).add(60, "days").toDate());
        setDueDate(moment(invoiceDate).add(60, "days").toDate());
      }
      if (name === "Net 30") {
        setDueDate(moment(invoiceDate).add(30, "days").toDate());
      }
      if (name === "Net 15") {
        setDueDate(moment(invoiceDate).add(15, "days").toDate());
      }
      if (name === "Other") {
      }
    }
  }, [term]);

  useEffect(() => {
    if (newInvoice?.id) {
      if (saveOption === "close") {
        navigate(`/billing/invoices/${newInvoice?.id}`);
      }
      if (saveOption === "pay") {
        setIsOpenAddPayment(true);
      }
    }
  }, [newInvoice?.id]);

  const create = () => {
    if (currentContact && data.length > 0) {
      const body: IAddInvoice = {
        isIncluded: isIncludeTax,
        notes: notes || "",
        orgId: getLinkedFirm()?.orgId,
        createdByUserId: user.id,
        customerId: currentContact?.value || "",
        invoiceNo: invoiceNo || 0,
        tax: taxRateValue?.[0]?.taxRate || 0,
        termId: term,
        discount: +discount || 0,
        discountTypeId: discountTypes[discountType].toLowerCase(),
        total: +total.toFixed(2),
        invoiceDate: moment(invoiceDate).startOf("day").toISOString(),
        assignedToUserId: selectedUser ? selectedUser?.id : null,
        dueDate: moment(dueDate).startOf("day").toISOString(),
        invoiceCustomers: invoiceCustomers?.map((item) => ({
          customerId: item?.value,
        })),
        invoiceItem: data.map((item) => ({
          description: item.description,
          billingServiceId: item.service,
          amount: item.amount,
          rate: +item.rate || 0,
          quantity: item.qty,
          invoiceType: item.invoiceType,
          assignedUserId: item.assignedUserId,
          workTypeId: item.workTypeId,
          workId: item.workId,
          duration: item.duration,
        })),
      };
      addInvoice(body);

      updateBilledStatus(currentTimeIds);
    }
  };

  useEffect(() => {
    if (newInvoice?.id) {
      addBilledWork(
        billedWorks?.map((work: any) => ({
          description: work.work.name,
          duration: work.duration,
          rate: work.assignedUser.hourlyRate,
          amount:
            work.assignedUser.hourlyRate *
            totalRate(timeFormatter(work.duration)),
          serviceId: work.work.serviceId,
          invoiceId: newInvoice?.id,
          workTypeId: work.work.service.id,
          assignedUserId: work.assignedUserId,
        })),
      );
    }
  }, [newInvoice?.id]);

  useEffect(() => {
    if (terms && terms?.length > 0) {
      const defaultValue = terms?.find(
        (item) => item?.termName === "Due on Receipt",
      );
      setTerm(defaultValue ? defaultValue?.id || "" : "");
    }
  }, [terms]);

  useEffect(() => {
    if (services && services?.length > 0) {
      setData([
        {
          service: services?.[0]?.id || "",
          description: services?.[0]?.description || "",
          qty: 1,
          rate: services?.[0]?.rate?.toString() || "",
          amount: services?.[0]?.rate || 0,
          invoiceType: "invoiceItem",
          assignedUserId: null,
          workId: null,
          workTypeId: null,
          duration: 0,
        },
      ]);
    }
  }, [services]);

  const onClickIncludeTax = (value: boolean) => {
    const tax: number = (taxRateValue?.[0]?.taxRate || 0) / 100 + 1;
    setIsIncludeTax(value);
    if (value) {
      setData(
        data?.map((item) => ({
          ...item,
          rate: (+item?.rate * tax).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          amount: +item?.rate * item.qty * tax,
        })),
      );
    } else {
      setData(
        data?.map((item) => ({
          ...item,
          rate: (+item?.rate / tax).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          amount: (+item?.rate * item.qty) / tax,
        })),
      );
    }
  };

  if (
    isLoadingTerms ||
    isLoadingServices ||
    isLoadingInvoiceNo ||
    isLoadingTaxRate ||
    isLoadingUsers ||
    isLoadingContacts
  ) {
    return <LottieLoading />;
  }

  if (
    services?.length === 0 ||
    taxRateValue?.length === 0 ||
    !services ||
    !taxRateValue
  ) {
    return (
      <div className={"text-center text-lg font-semibold"}>
        <div className={"text-center text-gray-800"}>
          Check your services and tax rate in settings{" "}
        </div>
        <div
          className={"link-primary cursor-pointer text-center hover:underline"}
          onClick={() => navigate("/settings/billing")}>
          Go to settings
        </div>
      </div>
    );
  }

  const activeUser = () => {
    if (selectedUser) {
      return (
        <div>
          <div className={"flex items-center gap-1"}>
            <AvatarPlaceholder
              size={"2xs"}
              label={getInitials(
                selectedUser?.userProfile?.firstName || "",
                selectedUser?.userProfile?.lastName || "",
              )}
            />
            <div
              className={
                "max-w-[116px] overflow-hidden text-ellipsis whitespace-nowrap text-sm"
              }>
              {`${selectedUser?.userProfile?.firstName} ${selectedUser?.userProfile?.lastName}`}
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const handleOnDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) return;

    if (destination.index === source.index) return;

    const reorderedData = Array.from(data);
    const [movedItem] = reorderedData.splice(source.index, 1);
    reorderedData.splice(destination.index, 0, movedItem);

    setData(reorderedData);
  };

  const tableRows = data.map((item, index) => {
    return (
      <Draggable
        key={item.id}
        draggableId={String(item.id || "")}
        index={index}>
        {(provided) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="cursor-move border-b-[1px] border-gray-300 hover:bg-gray-100">
            <td className={"max-w-[10px]"}>
              <RxDragHandleDots2 size={24} className={"mr-2 text-gray-200"} />
            </td>
            <td className="border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800">
              <select
                defaultValue={"1"}
                className="select select-bordered"
                value={item.service}
                onChange={(e) =>
                  setData((prev) =>
                    prev.map((invoice, i) =>
                      i === index
                        ? {
                            ...item,
                            service: e.target.value,
                            description:
                              services?.find((s) => s.id === e.target.value)
                                ?.description || "",
                            rate: item.isWork
                              ? item.rate
                              : services
                                  ?.find((s) => s.id === e.target.value)
                                  ?.rate?.toString() || "",
                            amount: item.isWork
                              ? item.amount
                              : item.qty *
                                (services?.find((s) => s.id === e.target.value)
                                  ?.rate || 0),
                          }
                        : invoice,
                    ),
                  )
                }>
                {services?.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </td>
            <td className="border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800">
              <input
                type={"text"}
                className={"input input-bordered"}
                value={item.description}
                onChange={(e) =>
                  setData((prev) =>
                    prev.map((invoice, i) =>
                      i === index
                        ? { ...item, description: e.target.value }
                        : invoice,
                    ),
                  )
                }
              />
            </td>
            <td className="border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800">
              <input
                type={"text"}
                className={"input input-bordered"}
                value={item.qty}
                onChange={(e) =>
                  setData((prev) =>
                    prev.map((invoice, i) =>
                      i === index
                        ? {
                            ...item,
                            qty: new RegExp("^[0-9]*$").test(e.target.value)
                              ? Number(e.target.value)
                              : item.qty,
                            amount: Number(e.target.value) * (+item.rate || 0),
                          }
                        : invoice,
                    ),
                  )
                }
              />
            </td>
            <td className="border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800">
              <CustomCurrencyInput
                value={item.rate}
                setValue={(value) =>
                  setData((prev) =>
                    prev.map((invoice, i) =>
                      i === index
                        ? {
                            ...item,
                            rate: value,
                            amount: (+value || 0) * item.qty,
                          }
                        : invoice,
                    ),
                  )
                }
              />
            </td>
            <td className="border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800">
              {item.amount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              })}
            </td>
            {data?.length > 1 ? (
              <td
                className={"bg-transparent px-4 py-2 text-lg text-red-600"}
                onClick={() => onClickDeleteItem(item?.id || "", index)}>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className={"cursor-pointer"}
                />
              </td>
            ) : (
              <td className={"bg-transparent px-4 py-2 text-lg text-gray-300"}>
                <FontAwesomeIcon icon={faTrashCan} />
              </td>
            )}
            <td className={"bg-transparent px-4 py-2 text-lg text-gray-300"}>
              {item.invoiceType === "billedItem" && (
                <>
                  <IoTimeOutline
                    className={"text-purple h-6 w-6 cursor-pointer"}
                    onClick={() => {
                      setViewWork(true);
                      setWorkItem(item);
                    }}
                  />
                  {viewWork && (
                    <ViewWork
                      assignedUser={item.assignedUser}
                      qty={item.qty}
                      workType={item.workType}
                      workName={item.workName}
                      billedWork={workItem}
                      isActive={viewWork}
                      setCollapsedItems={setCollapsedItems}
                      collapsedItems={collapsedItems}
                      setViewWork={setViewWork}
                      totalRate={totalRate}
                    />
                  )}
                </>
              )}
            </td>
          </tr>
        )}
      </Draggable>
    );
  });
  return (
    <>
      <FormSection
        name={"Create invoice"}
        classForTitle={"p-0"}
        extraCx={"overflow-visible shadow-box"}>
        <div className={"flex items-start justify-between"}>
          <div className={"flex flex-col gap-6"}>
            <div className={"w-fit"}>
              <div className={"mb-2 text-base font-semibold text-gray-800"}>
                Client
              </div>
              <div className={"flex items-center gap-4"}>
                <div className={"w-[300px]"}>
                  <CustomSelect
                    placeholder={"Search client"}
                    value={currentContact}
                    inputValue={search}
                    options={contactsOptions() || []}
                    width={"300px"}
                    onChangeInput={(inputValue) => setSearch(inputValue)}
                    onChange={(newValue) =>
                      setCurrentContact({ ...(newValue as SelectOptionType) })
                    }
                  />
                </div>
                {currentContact && (
                  <>
                    {!isShowAddClient ? (
                      <div
                        onClick={() => setIsShowAddClient(true)}
                        className={"link link-primary text-base font-semibold"}>
                        Add client
                      </div>
                    ) : (
                      <div className={"min-w-[200px]"}>
                        <CustomSelect
                          placeholder={"Search client"}
                          isMulti
                          inputValue={search}
                          value={invoiceCustomers}
                          options={contactsOptions() || []}
                          onChangeInput={(inputValue) => setSearch(inputValue)}
                          onChange={(newValue) =>
                            setInvoiceCustomers(newValue as SelectOptionType[])
                          }
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className={"flex items-center gap-6"}>
              <div>
                <div className={"mb-2 text-base font-semibold text-gray-800"}>
                  Invoice date
                </div>
                <div className={"text-base text-gray-800"}>
                  <StyledDatePicker
                    value={invoiceDate}
                    onChange={(date) => {
                      setInvoiceDate(transformDateToUTC(date));
                    }}
                  />
                </div>
              </div>
              <div>
                <div className={"mb-2 text-base font-semibold text-gray-800"}>
                  Terms
                </div>
                <div className={"text-base text-gray-800"}>
                  <select
                    className={"select select-bordered"}
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}>
                    {terms?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.termName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <div className={"mb-2 text-base font-semibold text-gray-800"}>
                  Due date
                </div>
                <div className={"text-base text-gray-800"}>
                  <StyledDatePicker
                    value={dueDate}
                    onChange={(date) => {
                      setDueDate(transformDateToUTC(date));
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className={"mb-2 text-base font-semibold text-gray-800"}>
                Assignee
              </div>
              <div className={"text-base text-gray-800"}>
                <div
                  onClick={() => setIsOpenUsers(!isOpenUsers)}
                  className={`select select-bordered relative h-[48px] w-[200px] ${
                    isOpenUsers
                      ? "outline outline-[2px] outline-offset-2 outline-gray-300"
                      : "outline-none"
                  }`}
                  ref={selectUserRef}>
                  <div className={"flex w-full items-center"}>
                    <div className={"text-sm"}>{activeUser()}</div>
                  </div>
                  {isOpenUsers && (
                    <div
                      className={
                        "absolute left-[0px] z-[1] mt-[48px] max-h-[300px] w-full overflow-y-auto rounded-[8px] bg-white py-4 shadow-filter"
                      }>
                      <div className={"flex flex-col gap-1"}>
                        <div
                          onClick={(event) => {
                            event.stopPropagation();
                            setIsOpenUsers(false);
                            setSelectedUser(null);
                          }}
                          className={
                            "cursor-pointer px-4 text-sm hover:bg-gray-100"
                          }>
                          All
                        </div>
                        {users?.map((user) => (
                          <div key={user.id}>
                            <div
                              className={
                                "flex cursor-pointer px-4 hover:bg-gray-100"
                              }
                              onClick={(event) => {
                                event.stopPropagation();
                                setIsOpenUsers(false);
                                setSelectedUser(user);
                              }}>
                              <div className={"flex items-center gap-1"}>
                                <AvatarPlaceholder
                                  size={"super-small"}
                                  label={getInitials(
                                    user?.userProfile?.firstName || "",
                                    user?.userProfile?.lastName || "",
                                  )}
                                />
                                <div
                                  className={
                                    "text-sm"
                                  }>{`${user.userProfile?.firstName} ${user.userProfile?.middleName} ${user.userProfile?.lastName}`}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={"mb-2 text-base font-semibold text-gray-800"}>
              Invoice No
            </div>
            <div
              className={
                "input input-bordered flex items-center justify-center"
              }>
              {invoiceNo || 0}
            </div>
          </div>
        </div>
      </FormSection>
      <FormSection
        name={""}
        classForTitle={"p-0"}
        extraCx={"overflow-visible shadow-box"}>
        <div className={"flex items-center justify-end gap-4"}>
          <div className={"text-base font-semibold text-gray-500"}>
            Include Tax
          </div>
          <input
            type={"checkbox"}
            className={"toggle toggle-primary"}
            checked={isIncludeTax}
            onChange={(e) => onClickIncludeTax(e.target.checked)}
          />
        </div>
        <table className={"w-full"}>
          <thead className={"border-b-[1px] border-gray-300 bg-gray-200"}>
            <tr>
              <th className={"max-w-[10px]"}></th>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    "flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Service
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    "flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Description
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    "flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Qty
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    "flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Rate
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    "flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Amount
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}></th>
              <th
                className={
                  "items-center py-4 pl-4 pr-8 text-gray-500 last:pr-4"
                }>
                <img
                  src={ClockSvg}
                  alt={"Time"}
                  className={"h-[24px] w-[24px]"}
                />
              </th>
            </tr>
          </thead>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {tableRows}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
        {isBillableTime && !works?.length && (
          <div className={"font-semibold text-error"}>
            There are no pending time entries
          </div>
        )}
        <div className={"flex items-start justify-between"}>
          <div>
            <div
              className={
                "link-primary my-2 cursor-pointer pl-4 text-base font-semibold hover:underline"
              }
              onClick={onClickAddItem}>
              + Add line item
            </div>
            <div
              className={
                "link-primary my-2 cursor-pointer pl-4 text-base font-semibold hover:underline"
              }
              onClick={() => setIsBillableTime(true)}>
              + Billable time
            </div>
            <div>
              <div
                className={"mb-2 mt-20 text-base font-semibold text-gray-800"}>
                Notes
              </div>
              <textarea
                className={"textarea textarea-bordered min-w-[300px]"}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div
              className={
                "mb-2 flex w-full justify-between text-base font-semibold text-gray-800"
              }>
              <span className={"w-[200px]"}>Subtotal:</span> $
              {(subtotal || 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
            <div
              className={"mb-2 flex w-full items-center justify-between gap-2"}>
              <div className={"text-base font-semibold text-gray-800"}>
                <span className={"w-[200px]"}>Discount:</span>
              </div>
              <div className={"flex items-center"}>
                <select
                  value={discountType}
                  className={"select select-bordered select-sm"}
                  onChange={(e) =>
                    setDiscountType(e?.target?.value as "%" | "$")
                  }>
                  <option value={"$"}>$</option>
                  <option value={"%"}>%</option>
                </select>
                <div className={"max-w-[70px]"}>
                  <CustomCurrencyInput
                    value={discount}
                    setValue={setDiscount}
                    prefix={""}
                    className={"input-sm"}
                  />
                </div>
                <div className={"ml-2 text-base font-semibold text-gray-800"}>
                  $
                  {(discountValue || 0).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
              </div>
            </div>
            <div
              className={
                "mb-2 flex w-full items-center justify-between gap-2 text-base font-semibold text-gray-800"
              }>
              <div className={"max-w-[200px]"}>
                {taxRateValue?.[0]?.taxName}
              </div>
              <div className={"flex items-center gap-2"}>
                <div
                  className={
                    "input input-bordered flex h-fit min-h-[10px] items-center justify-center text-base font-semibold text-gray-800"
                  }>
                  {taxRateValue?.[0]?.taxRate}%
                </div>
                {isIncludeTax && (
                  <div
                    className={
                      "input input-bordered flex h-fit min-h-[10px] items-center  gap-2 rounded-md px-2 text-gray-500"
                    }>
                    <span className={"font-normal"}>
                      Includes {taxRateValue?.[0]?.taxName || ""} on
                    </span>
                    <span className={"font-semibold"}>
                      ${" "}
                      {(subtotal - taxValue - discountValue).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      )}
                    </span>
                  </div>
                )}
                <div>
                  $
                  {(taxValue || 0).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
              </div>
            </div>
            <div
              className={
                "flex w-full items-end justify-between text-base font-semibold text-gray-800"
              }>
              <span className={"w-[200px]"}>Total:</span>{" "}
              <span className={"text-3xl font-bold"}>
                $
                {(total || 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          </div>
        </div>
        <div className={"flex w-full justify-end gap-4"}>
          <Button
            label={"Cancel"}
            onClick={() => navigate(-1)}
            colorType={"ghost"}
            extraClasses={"normal-case"}
          />
          <div className={"relative"}>
            <div className={"flex"}>
              <Button
                label={SaveOptions[saveOption]}
                onClick={create}
                extraClasses={"normal-case"}
                radius={"left"}
                disabled={!currentContact || !data.length}
                iconPosition={"right"}
              />
              <div className={"w-[1px] bg-white"} />
              <Button
                label={""}
                extraClasses={"normal-case"}
                radius={"right"}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenSaveMenu(true);
                }}
                disabled={!currentContact || !data.length}
                icon={<ChevronDown className={"min-h-[20px] min-w-[20px]"} />}
              />
            </div>
            {isOpenSaveMenu && (
              <div
                ref={optionsRef}
                className={
                  "absolute right-0 top-full w-fit rounded-[8px] border-[1px] border-base-300 bg-white"
                }>
                {saveOption === "pay" && (
                  <div
                    className={
                      "cursor-pointer whitespace-nowrap rounded-[8px] px-4 py-2 text-base hover:bg-gray-300"
                    }
                    onClick={() => {
                      setIsOpenSaveMenu(false);
                      setSaveOption("close");
                    }}>
                    Save and close
                  </div>
                )}
                {saveOption === "close" && (
                  <div
                    className={
                      "cursor-pointer whitespace-nowrap rounded-[8px] px-4 py-2 text-base hover:bg-gray-300"
                    }
                    onClick={() => {
                      setIsOpenSaveMenu(false);
                      setSaveOption("pay");
                    }}>
                    Save and record payment
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </FormSection>
      {isBillableTime && !!works?.length && (
        <BillableTime
          isLoading={isLoading}
          currentContact={currentContact}
          collapsedItems={collapsedItems}
          setCollapsedItems={setCollapsedItems}
          works={works}
          billedWorks={billedWorks}
          onAdd={addWorkItems}
          onClose={() => setIsBillableTime(false)}
          isActive={isBillableTime}
        />
      )}
      {isOpenAddPayment && (
        <PayInvoiceModal
          close={() => setIsOpenAddPayment(false)}
          invoice={newInvoice}
        />
      )}
    </>
  );
};

export default AddInvoice;
