import classNames from "classnames";
import moment from "moment";
import React, { Dispatch, FC, SetStateAction } from "react";

import { AvatarPlaceholder } from "../../../../../../../../components/avatar/AvatarPlaceholder";
import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import { getInitialsFromFullName } from "../../../../../../../../constants/constants";
import { IOrganizationContact } from "../../../../../../../../types/types";

type Props = {
  organization: IOrganizationContact;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
};

export const OrganizationInfo: FC<Props> = ({ organization, setIsEdit }) => {
  const address = organization?.addresses?.map((i) => (
    <div className={"mb-2 flex flex-col"}>
      {i.line1 || ""} {i.city || ""} {i.region || ""} {i.postalCode || ""}
    </div>
  ));

  return (
    <Card extraClasses={"shadow-box"}>
      <div className={"px-6 py-4"}>
        <div className={"mb-4 flex justify-between"}>
          <div className={"text-lg font-bold"}>Contact Info</div>
          <Button
            label={"Edit"}
            size={"custom"}
            extraClasses={"btn-sm"}
            onClick={() => setIsEdit(true)}
          />
        </div>
        <div className={"grid-rows-[repeat(11, max-content)] grid gap-6"}>
          <div>
            <div className={"mb-2 text-base text-gray-400"}>LEGAL NAME</div>
            <div className={"flex items-center gap-2"}>
              <AvatarPlaceholder
                size={"extra-small"}
                type={"purple"}
                label={getInitialsFromFullName(organization?.companyName || "")}
              />
              <div className={"text-sm font-semibold"}>
                {organization?.companyName}
              </div>
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>BUSINESS NUMBER</div>
            <div className={"text-sm font-semibold"}>
              {organization?.businessNumber || "-"}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>INCORPORATION DATE</div>
            <div className={"text-sm font-semibold"}>
              {organization?.incorporationDate
                ? moment(organization?.incorporationDate).format("MMM DD, YYYY")
                : "-"}
            </div>
          </div>
          <div className={"grid-cols-[(repeat(2, max-content))] grid gap-4"}>
            <div>
              <div className={"text-base text-gray-400"}>YEAR END</div>
              <div className={"text-sm font-semibold"}>
                {organization?.yearEndMonth || "-"}{" "}
                {organization?.yearEndDay || "-"}
              </div>
            </div>
          </div>
          <div>
            <div className={"flex justify-between py-2"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  GST/HST NUMBER
                </div>
                <div className={"text-sm font-semibold"}>
                  {organization.gstHstNumber}
                </div>
              </div>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  REPORTING PERIOD
                </div>
                <div className={"text-sm font-semibold"}>
                  {organization.gstHstReportingPeroid}
                </div>
              </div>
            </div>
            <div className={"flex justify-between py-2"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>PST NUMBER</div>
                <div className={"text-sm font-semibold"}>
                  {organization.pstNumber}
                </div>
              </div>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  REPORTING PERIOD
                </div>
                <div className={"text-sm font-semibold"}>
                  {organization.pstReportingPeroid}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>ORGANIZATION TYPE</div>
            <div className={"text-sm font-semibold"}>
              {organization?.organizationType?.organizationType || "-"}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>PHONE NUMBER</div>
            <div className={"flex gap-4"}>
              <div className={"flex flex-col gap-2 text-sm font-semibold"}>
                {!!organization?.phoneNumbers?.length
                  ? organization?.phoneNumbers?.map((i) => (
                      <div>{i.phoneNumberTypeEnum?.phoneNumberType}</div>
                    ))
                  : "-"}
              </div>
              <div className={"flex flex-col gap-2 text-sm font-semibold"}>
                {!!organization?.phoneNumbers?.length
                  ? organization?.phoneNumbers?.map((i) => (
                      <div>{i.number}</div>
                    ))
                  : "-"}
              </div>
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>EMAIL</div>
            <div>
              {!!organization?.emails.length
                ? organization?.emails?.map((i) => (
                    <div>
                      <a
                        className={classNames(
                          "link-primary text-sm font-semibold",
                          i.emailAddress
                            ? "hover:underline"
                            : "pointer-events-none",
                        )}
                        href={i.emailAddress ? `mailto:${i.emailAddress}` : ""}>
                        {i.emailAddress || "-"}
                      </a>
                    </div>
                  ))
                : "-"}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>CONTACT TYPE</div>
            <div className={"text-sm font-semibold"}>
              {organization?.contactType?.contactType}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>ADDRESS</div>
            <div className={"text-sm font-semibold"}>
              {address ? address : "-"}
            </div>
          </div>
          <div className={"flex flex-col gap-6 border-t border-gray-200 py-4"}>
            <div className={"text-base text-gray-400"}>CONTACT PERSON</div>
            <div className={"grid grid-cols-2"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>FIRST NAME</div>
                <div className={"text-sm font-semibold"}>
                  {organization?.contactPersons?.firstName || "-"}
                </div>
              </div>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>LAST NAME</div>
                <div className={"text-sm font-semibold"}>
                  {organization?.contactPersons?.lastName || "-"}
                </div>
              </div>
            </div>
            <div className={"flex flex-col"}>
              <div className={"mb-2 text-base text-gray-400"}>EMAIL</div>
              <div>
                {!!organization?.contactPersons?.emails.length
                  ? organization?.contactPersons?.emails?.map((i) => (
                      <div key={i.id}>
                        <a
                          className={classNames(
                            "link-primary text-sm font-semibold",
                            i.emailAddress
                              ? "hover:underline"
                              : "pointer-events-none",
                          )}
                          href={
                            i.emailAddress ? `mailto:${i.emailAddress}` : ""
                          }>
                          {i.emailAddress || "-"}
                        </a>
                      </div>
                    ))
                  : "-"}
              </div>
            </div>
            <div className={"flex flex-col"}>
              <div className={"mb-2 text-base text-gray-400"}>PHONE NUMBER</div>
              <div className={"flex gap-4"}>
                <div className={"flex flex-col gap-2 text-sm font-semibold"}>
                  {organization?.contactPersons?.phoneNumbers?.map((i) => (
                    <div key={i.id}>
                      {i.phoneNumberTypeEnum?.phoneNumberType}
                    </div>
                  )) || "-"}
                </div>
                <div className={"flex flex-col gap-2 text-sm font-semibold"}>
                  {organization?.contactPersons?.phoneNumbers?.map((i) => (
                    <div key={i.id}>{i.number}</div>
                  )) || "-"}
                </div>
              </div>
            </div>
          </div>
          <div className={"border-t border-gray-200 pt-4"}>
            <div className={"text-base text-gray-400"}>ADDITIONAL INFO</div>
            <div className={"text-sm font-semibold"}>
              {organization?.additionalInfo || "-"}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
