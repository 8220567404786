import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useState } from "react";
import { HiXMark } from "react-icons/hi2";

import Button from "../../../components/button/Button";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  useAddBillingLogoMutation,
  useDeleteBillingLogoMutation,
  useGetBillingLogoQuery,
  useUpdateBillingLogoMutation,
} from "../../../services/BillingService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { ACCEPTED_FILE_TYPES, validateFile } from "./validation";
import * as procLoadingAnimation from "../../../media/lottieFiles/proc-loading-01.json";
import Lottie from "lottie-react";

type Props = {
  onClose: () => void;
};

const BillingLogo: FC<Props> = ({ onClose }) => {
  const {
    data: logo,
    isLoading,
    isFetching,
  } = useGetBillingLogoQuery(getLinkedFirm()?.orgId || "");

  const [
    addLogo,
    { isLoading: isLoadingAdd, isError: isErrorAdd, isSuccess: isSuccessAdd },
  ] = useAddBillingLogoMutation();
  const [
    updateLogo,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateBillingLogoMutation();
  const [
    deleteLogo,
    {
      isLoading: isLoadingDelete,
      isError: isErrorDelete,
      isSuccess: isSuccessDelete,
    },
  ] = useDeleteBillingLogoMutation();

  const [preview, setPreview] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  function resizeImage(
    file: File,
    minWidth: number,
    minHeight: number,
    maxWidth: number,
    maxHeight: number,
  ): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0, width, height);
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Canvas toBlob returned null"));
          }
        }, file.type);
      };
      img.onerror = () => reject(new Error("Image onLoad error"));
    });
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer?.files[0];
    if (file && validateFile(file)) {
      setPreview(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
    close();
  };

  const handleButtonClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ACCEPTED_FILE_TYPES.join(",");

    fileInput.onchange = async (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file && validateFile(file)) {
        const resizedImageBlob = await resizeImage(file, 64, 64, 600, 600);
        const resizedFile = new File([resizedImageBlob], file.name, {
          type: file.type,
        });
        setPreview(resizedFile);
        setPreviewUrl(URL.createObjectURL(resizedFile));
      }
    };

    fileInput.click();
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleSave = () => {
    if (preview) {
      if (previewUrl !== logo?.imageURI) {
        const formData = new FormData();
        if (logo?.imageURI) {
          formData.append("Name", preview.name);
          formData.append("Image", preview);
          formData.append("ImageType", ".png");
          formData.append("OrgId", getLinkedFirm()?.orgId || "");

          updateLogo(formData);
        } else {
          formData.append("Name", preview.name);
          formData.append("Image", preview);
          formData.append("ImageType", preview.type);
          formData.append("OrgId", getLinkedFirm()?.orgId || "");

          addLogo(formData);
        }
      }
    } else {
      if (!previewUrl) {
        deleteLogo(getLinkedFirm()?.orgId || "");
      }
    }
  };

  useEffect(() => {
    if (
      isErrorAdd ||
      isErrorUpdate ||
      isErrorDelete ||
      isSuccessAdd ||
      isSuccessUpdate ||
      isSuccessDelete
    ) {
      setIsEdit(false);
    }
  }, [
    isErrorAdd,
    isErrorUpdate,
    isErrorDelete,
    isSuccessAdd,
    isSuccessUpdate,
    isSuccessDelete,
  ]);

  useEffect(() => {
    if (logo?.imageURI) {
      setPreview(null);
      setPreviewUrl(logo?.imageURI);
    }
  }, [logo]);

  if (isLoading) return <LottieLoading />;

  return (
    <div className={"rounded-2xl bg-white p-6"}>
      {previewUrl && (
        <div className={"relative mb-4 h-fit w-fit"}>
          <img
            src={previewUrl}
            alt={"preview"}
            className={
              "min-h-32 min-w-32 max-w-80 max-h-32 rounded-[8px] object-cover"
            }
          />
          <div className={"mt-4 text-xs font-semibold"}>
            Minimum resolution 64px x 64px
          </div>
          {isLoadingAdd || isLoadingUpdate || isLoadingDelete || isFetching ? (
            <div
              className={
                "absolute left-0 top-0 flex h-full w-full items-center justify-center rounded-[8px] bg-[rgba(0,0,0,0.6)]"
              }>
              <div className={"flex h-16 w-16 items-center justify-center"}>
                <Lottie animationData={procLoadingAnimation} loop={true} />
              </div>
            </div>
          ) : (
            isEdit && (
              <div
                onClick={() => {
                  setPreview(null);
                  setPreviewUrl("");
                }}
                className={
                  "absolute -right-2.5 -top-2.5 cursor-pointer rounded-full bg-[rgba(0,0,0,0.6)] text-2xl text-white hover:bg-black"
                }>
                <HiXMark />
              </div>
            )
          )}
        </div>
      )}
      {isEdit && (
        <div
          className={
            "mb-4 flex min-h-[300px] min-w-[500px] flex-col items-center justify-center gap-4 rounded-2xl border-[1px] border-dotted border-gray-400 p-4"
          }
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}>
          <div className={"text-lg font-medium text-blue-500"}>Drag & Drop</div>
          <div>
            <FontAwesomeIcon
              icon={faCloudArrowUp}
              className={"text-[100px] text-blue-500"}
            />
          </div>
          <div className={"text-gray-500"}>
            <span>your file or </span>
            <span
              onClick={handleButtonClick}
              className={"cursor-pointer font-bold"}>
              Click here
            </span>
          </div>
        </div>
      )}
      <div>
        <div className={"flex gap-6"}>
          <Button
            label={"Cancel"}
            colorType={"outline"}
            extraClasses={"normal-case"}
            onClick={onClose}
          />
          <Button
            label={isEdit ? "Save" : "Edit"}
            colorType={"secondary"}
            extraClasses={"normal-case"}
            disabled={
              isLoadingAdd || isLoadingUpdate || isLoadingDelete || isFetching
            }
            onClick={() => {
              if (isEdit) {
                handleSave();
              } else {
                setIsEdit(true);
              }
            }}
          />
        </div>
        {(isErrorAdd || isErrorUpdate || isErrorDelete) && (
          <div className={"mt-4 text-base text-red-500"}>
            Something went wrong
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingLogo;
